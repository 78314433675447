import React, { FC } from 'react';
import '../App.css';
import { Container, Grid, Paper, styled, Typography } from '@mui/material';
import welcomeImg from '../assets/Group-11.png';
import { ContainedButton } from '../components/shared/button';
import { useAuth0 } from '@auth0/auth0-react';

export const StyledContainer = styled(Container)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const Login: FC = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <StyledContainer maxWidth="lg">
      <Paper>
        <Grid container alignItems={'center'}>
          <Grid item justifyContent={'center'} xs={5} px={10}>
            <Typography component={'h1'} variant={'h2'}>
              Welcome!
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 1.5 }}>
              So glad you made it this far, please sign in!
            </Typography>
            <ContainedButton onClick={loginWithRedirect} text={'Log in'} />
          </Grid>
          <Grid item xs={7}>
            <img width={'100%'} src={welcomeImg} />
          </Grid>
        </Grid>
      </Paper>
    </StyledContainer>
  );
};
