import { Box, Grid, InputLabel, Slider as MuiSlider, SliderProps, styled, Typography } from '@mui/material';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { concatenateStringAndLowercaseFirstLetter } from '../../services/shared/stringHelpers';

export const StyledSlider = styled(MuiSlider)(({ theme }) => ({
  // these next two properties ensure that the far left and right labels stay
  // in line with the slider
  '& .MuiSlider-markLabel[data-index="0"]': {
    transform: 'translateX(0%)',
  },
  '& .MuiSlider-markLabel[data-index="2"]': {
    transform: 'translateX(-100%)',
  },
  '& .MuiSlider-markLabel': {
    fontSize: '0.875rem',
    marginTop: theme.spacing(1),
  },
  '& .MuiSlider-thumb': {
    backgroundColor: theme.palette.primary.contrastText,
    border: `solid 1px ${theme.palette.text.primary}`,
  },
  paddingBottom: theme.spacing(4),
}));

export const StyledTwoPointSlider = styled(MuiSlider)(({ theme }) => ({
  // these next two properties ensure that the far left and right labels stay
  // in line with the slider
  '& .MuiSlider-markLabel[data-index="0"]': {
    transform: 'translateX(0%)',
  },
  '& .MuiSlider-markLabel[data-index="1"]': {
    transform: 'translateX(-100%)',
  },
  '& .MuiSlider-markLabel': {
    fontSize: '0.875rem',
    marginTop: theme.spacing(1),
  },
  '& .MuiSlider-thumb': {
    backgroundColor: theme.palette.primary.contrastText,
    border: `solid 1px ${theme.palette.text.primary}`,
  },
  paddingBottom: theme.spacing(4),
}));

export type InternalSliderProps = {
  onChange: (value: number | undefined) => any;
  formLabel: string;
};

type RangeProps = {
  value: number[];
  onChange: (value: (number | undefined)[]) => any;
  min: number;
  max: number;
  label: string;
  step: number;
};

export const RangeSlider: FC<RangeProps> = ({ label, step, value, min, max, onChange }) => {
  const [internalValue, setInternalValue] = useState(value);
  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  if (value.length !== 2) {
    throw 'value parameter supplied with an incorrect amount of values';
  }

  const valueLabelFormat = (value: number) => {
    const numberWithCommas = value.toLocaleString('en-US');
    return `$${numberWithCommas}`;
  };

  const handleChange = (event: React.SyntheticEvent | Event, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      onChange([newValue[0], newValue[1]]);
    }
  };

  const handleInternalChange = (event: Event, newValue: number | number[]) => {
    return setInternalValue(newValue as number[]);
  };

  return (
    <Box width={'100%'}>
      <InputLabel>
        <Typography variant={'h5'}>{label}</Typography>
      </InputLabel>
      <MuiSlider
        getAriaLabel={() => `${label}`}
        value={internalValue}
        max={max}
        min={min}
        onChangeCommitted={handleChange}
        onChange={handleInternalChange}
        step={step}
        valueLabelDisplay="auto"
        valueLabelFormat={valueLabelFormat}
      />
      <Typography sx={{ textAlign: 'center' }}>
        {valueLabelFormat(internalValue[0])} - {valueLabelFormat(internalValue[1])}
      </Typography>
    </Box>
  );
};

export type MailroomSliderProps = Omit<SliderProps, 'onChange'> & InternalSliderProps;

export const Slider: FC<MailroomSliderProps> = ({
  onChange,
  defaultValue,
  step,
  name,
  formLabel,
  marks,
  ...otherProps
}) => {
  const handleChange = (event: Event | SyntheticEvent<Element, Event>, newValue: number | number[]) => {
    if (!Array.isArray(newValue)) {
      onChange(newValue);
    }
    return;
  };

  return (
    <Grid container justifyContent={'center'}>
      <Grid item width={'100%'}>
        <Typography>{formLabel}</Typography>
        <StyledSlider
          aria-label={formLabel}
          defaultValue={defaultValue}
          step={step}
          marks={marks}
          name={concatenateStringAndLowercaseFirstLetter(name)}
          onChangeCommitted={handleChange}
          {...otherProps}
        />
      </Grid>
    </Grid>
  );
};

export const TwoPointSlider: FC<Omit<SliderProps, 'onChange'> & InternalSliderProps> = ({
  onChange,
  defaultValue,
  step,
  name,
  formLabel,
  marks,
  ...otherProps
}) => {
  const handleChange = (event: Event | SyntheticEvent<Element, Event>, newValue: number | number[]) => {
    if (!Array.isArray(newValue)) {
      onChange(newValue);
    }
    return;
  };

  return (
    <Grid container justifyContent={'center'}>
      <Grid item width={'100%'}>
        <Typography>{formLabel}</Typography>
        <StyledTwoPointSlider
          aria-label={formLabel}
          defaultValue={defaultValue}
          step={step}
          marks={marks}
          name={concatenateStringAndLowercaseFirstLetter(name)}
          onChangeCommitted={handleChange}
          {...otherProps}
        />
      </Grid>
    </Grid>
  );
};
