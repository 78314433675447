import React, { FC } from 'react';
import { ButtonStyledText } from '../shared/typography';
import { Grid, Tooltip, Typography } from '@mui/material';
import { XFactorCard } from './xFactorCard';
import { Info } from '@mui/icons-material';

export const XFactors: FC = () => {
  return (
    <>
      <Grid container direction={'row'} sx={{ marginBottom: 1 }}>
        <ButtonStyledText>
          {'Scenario-Based Responses'}{' '}
          <Tooltip title="Scenarios are a unique, asset-based way to understand the blend of great qualities you bring to different situations. Maybe you like to step to the front of a room to bring people together. Maybe you are very effective at being the ‘glue’ behind the scenes that enables success. Or maybe you do neither and reliably stay on track and in-the-zone amid shifting conditions. There are many ways to create an impact  on teams and we want to know about all the ways you do what you do!">
            <Info fontSize="small" />
          </Tooltip>
        </ButtonStyledText>
        <Typography>
          By exploring each of these scenarios and sharing your gut reactions to them, we will make sure to curate
          growth opportunities for you that value your unique gifts and assets.
        </Typography>
      </Grid>
      <XFactorCard />
    </>
  );
};
