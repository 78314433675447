import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IAddJobRoleRequest,
  IChangeJobRoleStatusRequest,
  IGetJobRoleResponse,
  IListJobMatchesJobMatchItem,
  IListJobMatchesResponse,
  IListJobRolesJobRoleResult,
  JobReqOrder,
  ListSortDirection,
} from '../services/generated/ApiClientGenerated';
import { Loadable } from '../services/shared/baseTypes';

export type JobRoleDetails = { createdOn?: string; lastUpdatedOn?: string } & Omit<
  IGetJobRoleResponse,
  'createdOn' | 'lastUpdatedOn'
>;

export type JobRole = { startDate?: string } & Omit<IListJobRolesJobRoleResult, 'startDate'>;

export type JobRoleList = {
  currentPage?: number;
  pageCount?: number;
  pageSize?: number;
  resultCount?: number;
  firstRowOnPage?: number;
  lastRowOnPage?: number;
  results?: JobRole[] | undefined;
  totalResults?: number;
};

export type ListJobsRequest = {
  companyId?: string;
  departments?: string[];
  jobTitle?: string;
  minSalary?: number;
  maxSalary?: number;
  sortOrder?: JobReqOrder;
  currentPage?: number;
  pageSize?: number;
  useCurrentUsersSearchSettings?: boolean;
  isArchived?: boolean;
  sortDirection?: ListSortDirection;
};

export type SerializableJobMatchResponse = Omit<IListJobMatchesResponse, 'results'> & {
  results?: IListJobMatchesJobMatchItem[];
};

export type JobSliceState = {
  candidatesToInterview: IListJobMatchesJobMatchItem[];
  compareCandidatesDialog: {
    isVisible: boolean;
    roleId?: string;
  };
  isCandidateComparisonComponentVisible: boolean;
  jobSearchDialog: {
    isVisible: boolean;
  };
  loading: boolean;
  loaded: boolean;
  jobRolesList?: JobRoleList;
  //TODO: update with appropriate type
  jobRoleMatches: Loadable<SerializableJobMatchResponse>;
  postNewRollDialog: {
    isVisible: boolean;
  };
};

export const jobSlice = createSlice({
  name: 'job',
  initialState: {
    candidatesToInterview: [],
    compareCandidatesDialog: {
      isVisible: false,
    },
    isCandidateComparisonComponentVisible: false,
    jobSearchDialog: {
      isVisible: false,
    },
    loading: false,
    loaded: false,
    jobRolesList: undefined,
    jobRoleMatches: {
      isLoading: false,
      isLoaded: false,
    },
    postNewRollDialog: {
      isVisible: false,
    },
  } as JobSliceState,
  reducers: {
    addCandidateToInterview: (state, action: PayloadAction<IListJobMatchesJobMatchItem[]>) => {
      state.candidatesToInterview = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    addInterestAction: (state, action: PayloadAction<string | undefined>) => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    addJobRoleAction: (state, action: PayloadAction<IAddJobRoleRequest>) => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    addOrUpdateRequirementAction: (state, action) => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    changeJobRoleStatusAction: (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{ roleId: string; body: IChangeJobRoleStatusRequest | undefined }>,
    ) => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    deleteInterestAction: (state, action: PayloadAction<string | undefined>) => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    deleteJobRoleAction: (state, action: PayloadAction<string>) => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getJobRoleListAction: (state, action: PayloadAction<ListJobsRequest>) => {
      state.loaded = false;
      state.loading = true;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    startJobMatchingAction: (state, action: PayloadAction<string | undefined>) => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    hideCandidateComparisonAction: (state) => {
      state.candidatesToInterview = [];
      state.isCandidateComparisonComponentVisible = false;
    },
    hideCompareCandidatesDialogAction: (state) => {
      state.compareCandidatesDialog.isVisible = false;
    },
    hidePostNewRoleDialogAction: (state) => {
      state.postNewRollDialog.isVisible = false;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    sendInterviewEmailAction: (state, action: PayloadAction<{ roleId: string; matchId: string }>) => {},
    getJobMatchesAction: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{ roleId: string; page?: number; pageSize?: number }>,
    ) => {
      state.jobRoleMatches.isLoaded = false;
      state.jobRoleMatches.isLoading = true;
    },
    getAndShowJobMatchesAction: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{ roleId: string; page?: number; pageSize?: number }>,
    ) => {
      state.jobRoleMatches.isLoaded = false;
      state.jobRoleMatches.isLoading = true;
      state.compareCandidatesDialog.roleId = action.payload.roleId;
      state.compareCandidatesDialog.isVisible = true;
    },
    showCandidateComparisonAction: (state) => {
      state.isCandidateComparisonComponentVisible = true;
    },
    showPostNewRoleDialogAction: (state) => {
      state.postNewRollDialog.isVisible = true;
    },
    successGetJobRoleMatchesAction: (state, action: PayloadAction<IListJobMatchesResponse>) => {
      state.jobRoleMatches.isLoaded = true;
      state.jobRoleMatches.isLoading = false;
      state.jobRoleMatches.value = {
        ...action.payload,
        results: action.payload.results?.map((r) => r.toJSON()),
      };
    },
    successGetJobRoleListAction: (state, action: PayloadAction<JobRoleList>) => {
      state.loaded = true;
      state.loading = false;
      state.jobRolesList = action.payload;
    },
  },
});

export const {
  addCandidateToInterview,
  addInterestAction,
  addJobRoleAction,
  addOrUpdateRequirementAction,
  changeJobRoleStatusAction,
  deleteInterestAction,
  deleteJobRoleAction,
  getJobMatchesAction,
  getAndShowJobMatchesAction,
  getJobRoleListAction,
  hideCandidateComparisonAction,
  hideCompareCandidatesDialogAction,
  hidePostNewRoleDialogAction,
  sendInterviewEmailAction,
  showCandidateComparisonAction,
  showPostNewRoleDialogAction,
  startJobMatchingAction,
  successGetJobRoleListAction,
  successGetJobRoleMatchesAction,
} = jobSlice.actions;
