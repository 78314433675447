import React, { FC, useEffect } from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { loadXFactorScenarios, selectScenario } from '../../redux/xFactorsSlice';
import { FindingYourRhythmSvg } from '../../assets/findingYourRhythmSvg';
import { ANewTeamSvg } from '../../assets/ANewTeamSvg';
import { NewColleagueSvg } from '../../assets/newColleagueSvg';
import { CrunchTimeSvg } from '../../assets/crunchTimeSvg';
import { EarlyPromotionSvg } from '../../assets/earlyPromotionSvg';
import { SoftwareToolSvg } from '../../assets/softwareToolSvg';
import { StyledCard, StyledCardActionArea } from '../shared/card';
import { RootState } from '../../store';
import { XFactorQuestionForm } from './xFactorQuestionForm';
import { updateXFactors } from '../../redux/profileSlice';

export const xFactorCards = [
  {
    id: '42b1f244-976a-4301-81de-ea2a914a1af4',
    image: <NewColleagueSvg />,
    header: 'A new colleague',
    subheader: 'A new person has just been hired in a role alongside yours',
  },
  {
    id: '54f73866-7123-403b-9f71-b18c4119e60d',
    image: <CrunchTimeSvg />,
    header: 'Crunch time',
    subheader: 'Your team is entering the busiest time of year',
  },
  {
    id: '45178112-a0cb-416c-96e9-cb4d433629d8',
    image: <SoftwareToolSvg />,
    header: 'A software tool you know',
    subheader: 'Your company is starting to use a new software tool, and you have used it before',
  },
  {
    id: '30e3f0a9-bbe5-42cd-aaab-64edd34d9392',
    image: <ANewTeamSvg />,
    header: 'A new team',
    subheader: 'You just joined a high performing team',
  },
  {
    id: 'b2fa0691-0624-419c-a068-91ea11190627',
    image: <EarlyPromotionSvg />,
    header: 'An early promotion',
    subheader: 'After an amazing year-end review, you get promoted ahead of schedule',
  },
  {
    id: 'f8b07a46-d2be-4b5e-8a27-4678b1fa3414',
    image: <FindingYourRhythmSvg />,
    header: 'Finding your rhythm',
    subheader: 'You are a team manager, and your team is finding its rhythm',
  },
];

const XFactorEditLabel: FC<{ completionPercentage: number }> = ({ completionPercentage }) => {
  if (completionPercentage === 0) {
    return (
      <Typography variant="h5" color="primary">
        START
      </Typography>
    );
  } else if (completionPercentage === 1) {
    return <Typography variant="h5">EDIT</Typography>;
  }
  return (
    <Typography variant="h5" color="primary">
      INCOMPLETE
    </Typography>
  );
};

export const XFactorCard: FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(selectScenario(undefined));
  }, []);
  const currentScenario = useSelector((x: RootState) => x.xFactors.currentScenario);
  const answers = useSelector((x: RootState) => x.profile.profileDetails.xFactors);
  const scenarios = useSelector((x: RootState) => x.xFactors.scenarios);
  const lookupScenarioCompletion = (scenarioId: string) => {
    return scenarios.filter((x) => x.id == scenarioId)[0]?.completedPercentage ?? 0;
  };

  const handleOpen = (xFactorId: string) => dispatch(selectScenario(xFactorId));
  useEffect(() => {
    dispatch(loadXFactorScenarios());
  }, []);

  return (
    <Grid container direction={'row'} spacing={2}>
      {xFactorCards.map((x) => (
        <Grid item key={x.id} md={4} sm={6} xs={12}>
          <StyledCardActionArea
            sx={{
              height: '100%',
              borderColor:
                lookupScenarioCompletion(x.id) === 1
                  ? undefined
                  : lookupScenarioCompletion(x.id) === 0
                  ? '#59BDBF'
                  : '#ff4133',
            }}
            onClick={() => handleOpen(x.id)}
          >
            <StyledCard
              sx={{
                padding: 1,
                height: '100%',
              }}
            >
              <Grid
                container
                sx={{ overflow: 'hidden' }}
                direction={'column'}
                justifyContent={'space-between'}
                height={'100%'}
              >
                <Grid container alignItems={'center'} item width={'100%'}>
                  <Grid container item justifyContent={'center'} alignItems={'center'}>
                    {x.image}
                  </Grid>
                  <Box sx={{ margin: 2 }}>
                    <Typography component={'h3'} variant={'body2'}>
                      {x.header}
                    </Typography>
                    <Typography>{x.subheader}</Typography>
                  </Box>
                </Grid>
                <Grid item>
                  <Divider />
                  <Box sx={{ padding: 1 }} display="flex" alignItems="center" justifyContent="center">
                    <XFactorEditLabel completionPercentage={lookupScenarioCompletion(x.id)} />
                  </Box>
                </Grid>
              </Grid>
            </StyledCard>
          </StyledCardActionArea>
        </Grid>
      ))}
      <Grid item xs={12}>
        <Divider sx={{ padding: '4 0' }} />
      </Grid>
      {currentScenario?.questions?.value?.every((x) => !x.scenarioId) ? (
        <Grid item xs={12}>
          <XFactorQuestionForm
            questions={currentScenario?.questions?.value ?? []}
            value={answers ?? []}
            onChange={(newAnswers) => dispatch(updateXFactors(newAnswers))}
          />
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  );
};
