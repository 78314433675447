import { combineReducers } from 'redux';
import { profileSlice } from './redux/profileSlice';
import { globalSlice } from './redux/globalSlice';
import { xFactorsSlice } from './redux/xFactorsSlice';
import { jobSlice } from './redux/jobSlice';
import { skillsSlice } from './redux/skillsSlice';
import { settingsSlice } from './redux/settingsSlice';
import { userSlice } from './redux/userSlice';
import { managementSlice } from './redux/managementSlice';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createRootReducer = () => {
  return combineReducers({
    global: globalSlice.reducer,
    job: jobSlice.reducer,
    profile: profileSlice.reducer,
    user: userSlice.reducer,
    settings: settingsSlice.reducer,
    skills: skillsSlice.reducer,
    xFactors: xFactorsSlice.reducer,
    management: managementSlice.reducer,
  });
};
