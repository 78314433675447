import React, { FC } from 'react';
import { Grid, Typography } from '@mui/material';

import { StyledHeader } from '../shared/typography';
import { StyledLink } from '../shared/link';
import { ImageContainer } from '../shared/image';
import { StyledCard, StyledCardContent, StyledCardActionArea } from '../shared/card';
import { JobRecsSvg } from '../../assets/jobRecsSvg';

export const JobManagementCard: FC = () => {
  return (
    <Grid item xs={12} sm={6} md={4}>
      <StyledLink to={'/job-management'}>
        <StyledCard>
          <StyledCardActionArea>
            <StyledCardContent>
              <StyledHeader>Manage Open Jobs</StyledHeader>
              <ImageContainer container item alignContent={'center'} justifyContent={'center'}>
                <JobRecsSvg />
              </ImageContainer>
              <Typography variant={'body2'}>Edit, post, or review new roles</Typography>
              <Typography variant={'body1'}>
                Create new job postings, review applicants, and share opportunities with your colleagues.
              </Typography>
            </StyledCardContent>
          </StyledCardActionArea>
        </StyledCard>
      </StyledLink>
    </Grid>
  );
};
