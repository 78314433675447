import React, { FC } from 'react';
import { ListRanker, ListRankerItem } from './listRanker';
import { XFactorQuestionProps } from './xFactorQuestion';
import { IXFactorQuestionOption } from '../../../services/generated/ApiClientGenerated';
import { Typography } from '@mui/material';

export const RankingQuestion: FC<XFactorQuestionProps<string[]>> = ({ question, value, onChange }) => {
  const mapOption = (option: IXFactorQuestionOption) => ({
    key: option.id!,
    text: option.text!,
  });
  const selectedOptions = question.options?.filter((o) => value?.some((v) => v === o.id))?.map(mapOption) ?? [];
  const handleChange = (newValues: ListRankerItem[]) => {
    onChange(newValues.map((x) => x.key));
  };
  return (
    <>
      <Typography variant="h4" sx={{ marginBottom: 2 }}>
        SELF RANKING RESPONSES
      </Typography>
      <ListRanker
        title={question.question}
        leftTitle={'Of these statements...'}
        rightTitle={'...these ones decribe me the most'}
        options={question.options?.map(mapOption) ?? []}
        value={selectedOptions}
        onChange={handleChange}
      />
    </>
  );
};
