import React, { FC } from 'react';

export const SearchJobsSvg: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="188" height="253.665" viewBox="0 0 188 253.665">
      <defs>
        <clipPath id="clip-path">
          <path
            id="Clip_4"
            data-name="Clip 4"
            d="M0,0H14.523V7.056H0Z"
            transform="translate(0.309 0.944)"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-2">
          <path
            id="Clip_13"
            data-name="Clip 13"
            d="M0,0H14.385V7.056H0Z"
            transform="translate(0.966 0.944)"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-3">
          <path
            id="Clip_22"
            data-name="Clip 22"
            d="M0,0H9.092V25.923H0Z"
            transform="translate(0.713 0.335)"
            fill="none"
          />
        </clipPath>
      </defs>
      <g id="Group_8" data-name="Group 8" transform="translate(0 -0.335)">
        <g id="Group_7" data-name="Group 7">
          <g id="Group_4" data-name="Group 4" transform="translate(85 8)">
            <g id="Whiteboard_Stuff" data-name="Whiteboard Stuff" opacity="0.5">
              <path
                id="Wireframy_Stuff"
                data-name="Wireframy Stuff"
                d="M1.308,24.71C.207,24.2.233,23.63.682,23.19A7.752,7.752,0,0,1,.4,21.084,50.989,50.989,0,0,1,0,15.017c-.007-4,0-8.157,1.078-12.029A.979.979,0,0,1,.389,2.1C.392.068,6.38.119,9.453.169c.642.01,1.156.021,1.462.013C13.1.123,15.276.116,17.455.128c.477,0,1.01-.031,1.562-.064A11.833,11.833,0,0,1,22.28.158c2.794.667,2.616,5.586,2.674,7.966a128.321,128.321,0,0,1-.294,13.01c-.116,1.471-1.157,3.334-2.344,1.307-.662-1.129-.367-3.769-.368-5.017,0-4.437.673-9.009.026-13.423-.212-1.448-.166-1.545-1.6-1.515-1.138.024-2.274.063-3.411.087-2.316.049-4.631.083-6.946.169-.879.033-4.037.6-6.5.6,0,.02,0,.04,0,.061.034,1.711-.783,3.787-.894,5.587-.267,4.329.051,8.76-.343,13.068-.012.131-.018.27-.026.41.074-.012.146-.02.216-.024,1.836-.12,3.7-.07,5.536-.114,1.172-.028,2.359-.027,3.55-.025A65.39,65.39,0,0,0,19.436,22c.761-.1,1.028.9.686,1.422-.382.583-1.609.553-2.177.64a47.875,47.875,0,0,1-5.024.418c-1.761.08-3.524.14-5.287.191C6.611,24.7,4.753,25,3.214,25A4.8,4.8,0,0,1,1.308,24.71Z"
                transform="translate(19)"
                fill="#89c5cc"
              />
              <path
                id="Wireframy_Stuff-2"
                data-name="Wireframy Stuff"
                d="M1.556,23.648a.753.753,0,0,1,.163-.473,3.18,3.18,0,0,1-.73-1.286A22.372,22.372,0,0,1,.275,15.76C.179,11.4.227,7.035.2,2.675A1.116,1.116,0,0,1,.535,1.113c1.339-1.145,5.2-.854,6.9-.944C9.885.041,12.335.011,14.785,0c2.6-.013,5.194.034,7.788.113.963.029,7.654.2,4.663,1.81a3.133,3.133,0,0,1-.574.234,9.38,9.38,0,0,1,.12,1.855c-.127,2.32-.182,4.645-.285,6.967-.115,2.591-.226,5.182-.315,7.775-.048,1.37.424,4.557-.667,5.547-.735.667-1.237.533-1.576.08-1.874.541-5.507.443-7.813.345-.9-.039-1.606-.077-1.914-.076-1.034,0-4.482.349-7.519.349C3.962,25,1.564,24.718,1.556,23.648ZM3.2,8.785c0,4.412.158,8.8.258,13.188q0,.216-.01.4a37.45,37.45,0,0,1,7.764-.281c.9.034,1.6.068,1.932.068,2.175-.009,4.351.022,6.525.108.246.009.623,0,1.064-.013a20.226,20.226,0,0,1,2.58.053c-.012-.118-.02-.225-.024-.318-.119-2.334.01-4.692.086-7.025.076-2.353.193-4.7.284-7.054a35.758,35.758,0,0,1,.072-5.475c-1.58-.03-3.237-.22-4.3-.22-2.5,0-5-.029-7.5.021a72.957,72.957,0,0,0-8.116.439c-.147.02-.323.057-.519.1C3.7,4.477,3.207,7.21,3.2,8.785Z"
                transform="translate(54)"
                fill="#89c5cc"
              />
              <path
                id="Fill_135"
                data-name="Fill 135"
                d="M.884.247C-.657,1.18.174,4.911.708,6.2a10.512,10.512,0,0,0,4.58,4.883c3.884,2.226,9.483,1.635,12.421,5.494.03.04,1.046,1.081,1.277-.267.19-1.107-1.706-2.8-2.44-3.352-2.089-1.578-4.768-2.086-7.184-2.874-2.577-.841-5.4-2.39-6.485-5.142-.383-.975.347-6.119-1.993-4.7"
                transform="translate(31 24)"
                fill="#c1dee2"
              />
              <path
                id="Fill_137"
                data-name="Fill 137"
                d="M17.226.053c-1.425.417-.482,2.5-.485,3.538a6.422,6.422,0,0,1-1.149,3.7c-1.914,2.63-5.662,3.024-8.472,3.778C5.08,11.613.2,12.787.008,15.715c-.213,3.269,4.1-.709,4.46-.872,4.028-1.568,9.239-1.87,12.348-5.321a8.738,8.738,0,0,0,2.162-5.287c.068-1.1.079-4.712-1.753-4.181"
                transform="translate(50 26)"
                fill="#c1dee2"
              />
              <path
                id="Fill_139"
                data-name="Fill 139"
                d="M11.95,20a14.176,14.176,0,0,1-8.029-2.61A8.649,8.649,0,0,1,0,10.476,7.77,7.77,0,0,1,.927,6.729,10.057,10.057,0,0,1,3.377,3.793,18.246,18.246,0,0,1,10.547.321,10.525,10.525,0,0,1,13.11,0a9.849,9.849,0,0,1,9.813,8.652,9.62,9.62,0,0,1-2.213,7.476,11.42,11.42,0,0,1-8.262,3.862C12.284,20,12.117,20,11.95,20ZM3.667,6.982a5.426,5.426,0,0,0-.289,5.729A8.415,8.415,0,0,0,7.115,16,12.283,12.283,0,0,0,12.4,17.23a8.852,8.852,0,0,0,3.582-.7,6.914,6.914,0,0,0,3.566-3.5,7.67,7.67,0,0,0,.515-4.7,6.744,6.744,0,0,0-2.105-3.736A6.685,6.685,0,0,0,13.49,2.986a1.371,1.371,0,0,1,.343.994.708.708,0,0,1-.38.475.9.9,0,0,1-.436.131c-.362,0-.6-.319-.831-.628a1.469,1.469,0,0,0-.686-.616,1.034,1.034,0,0,0-.267-.033,4.773,4.773,0,0,0-2.258,1A8.793,8.793,0,0,0,7.96,5.3c-.938,1-2.221,2.361-3.252,2.361a1.207,1.207,0,0,1-1.042-.679Z"
                transform="translate(37 43)"
                fill="#89c5cc"
              />
              <path
                id="Fill_141"
                data-name="Fill 141"
                d="M0,1A1,1,0,0,0,2,1,1,1,0,1,0,0,1"
                transform="translate(45 51)"
                fill="#89c5cc"
              />
              <path
                id="Fill_143"
                data-name="Fill 143"
                d="M0,1A1,1,0,0,0,2,1,1,1,0,1,0,0,1"
                transform="translate(49 51)"
                fill="#89c5cc"
              />
              <path
                id="Fill_145"
                data-name="Fill 145"
                d="M.484.1c-2.285,1.035,4.25,3.093,4.508.99C5.1.183,3.963.427,3.2.361,2.476.3,1.174-.21.484.1"
                transform="translate(45 56)"
                fill="#89c5cc"
              />
              <path
                id="Fill_147"
                data-name="Fill 147"
                d="M0,1.061a75.243,75.243,0,0,0,.5,9.45c.089.593.15,2.743.514,3.183.9,1.083.91-.993.945-1.789.124-2.85-.108-5.788-.16-8.644A9.613,9.613,0,0,0,1.694.778C1.337-.166-.034-.447,0,1.061"
                transform="translate(45 63)"
                fill="#c1dee2"
              />
              <path
                id="Fill_149"
                data-name="Fill 149"
                d="M30.558.355C25.976,5.589,19.667,5.2,13.312,5.662,9.954,5.9,6.558,6.4,3.856,8.613A11.642,11.642,0,0,0,.787,12.4c-.318.642-1.828,4.346.439,3.467.8-.312,1.6-2.761,2.116-3.486a10.154,10.154,0,0,1,3.779-3.26c2.933-1.428,6.435-1.4,9.616-1.585,3.324-.189,6.893-.366,9.948-1.838a15.047,15.047,0,0,0,3.943-2.668c.413-.4,1.3-1.073,1.366-1.682.076-.7-.581-1.968-1.436-.993"
                transform="translate(12 61)"
                fill="#c1dee2"
              />
              <path
                id="Fill_151"
                data-name="Fill 151"
                d="M16.057.281C11.762.408,7.473.563,3.184.8,2.3.853-.626.7.12,2.3c.6,1.283,4.172.434,5.354.372,4.469-.236,8.957-.318,13.417-.675,1-.08,5.53-.048,3.661-1.626-.89-.751-5.266-.124-6.495-.086"
                transform="translate(4 78)"
                fill="#89c5cc"
              />
              <path
                id="Fill_153"
                data-name="Fill 153"
                d="M.873.439C-.5,2.343.131,6.97.234,9.259c.1,2.218-.307,6.274,2.433,6.618-.87-.121.661.056.91.07.556.032,1.115.042,1.673.048,1.287.015,2.575,0,3.862-.031,2.915-.062,5.838-.151,8.749-.321,1.224-.072,7.863-.012,8.126-1.355.336-1.716-6.03-.745-7.169-.691-2.775.134-5.555.163-8.333.2-1.377.019-2.755.033-4.132,0-.568-.012-2.82.235-3.165-.166-.295-.341-.065-3.477-.071-3.957C3.1,8.117,3.1,6.55,3.152,4.987c.036-1.052.574-3.055.135-4.043A1.476,1.476,0,0,0,.873.439"
                transform="translate(0 79)"
                fill="#89c5cc"
              />
              <path
                id="Fill_155"
                data-name="Fill 155"
                d="M0,1.761A39.3,39.3,0,0,0,1.11,11.874c.326,1.1.782,3.362,2.137,3.93A1.863,1.863,0,0,0,5.99,14.4c.117-1.442-.831-3.335-1.026-4.815C4.737,7.861,4.6,6.133,4.527,4.4c-.059-1.3.428-3.261-1.1-4.1C2.055-.459-.044.3,0,1.761"
                transform="translate(22 78)"
                fill="#89c5cc"
              />
              <path
                id="Fill_157"
                data-name="Fill 157"
                d="M1.018.57C-.583,2.014.133,6.588.333,8.444.479,9.8,2.121,16.492,4.582,13c1.088-1.543-.294-5.622-.285-7.421.006-1.146.454-2.594.238-3.7A2.13,2.13,0,0,0,1.018.57"
                transform="translate(30 78)"
                fill="#89c5cc"
              />
              <path
                id="Wireframy_Stuff-3"
                data-name="Wireframy Stuff"
                d="M1.918,15.987C.882,15.944-.223,15.606.039,14.273c.233-1.183,1.427-1.192,2.4-1.291a84.082,84.082,0,0,1,8.817-.392c6.195.022,12.384-.011,18.579-.042a1.342,1.342,0,0,1,.692.265c-.324-.392-.168-.972-.229-1.539-.163-1.527-.267-3.028-.558-4.555-.232-1.221-.408-3.3-1.543-4.064-.968-.654-2.855-.63-4.34-.606-.419.007-.807.014-1.132.006a103.306,103.306,0,0,0-11.949.538c-1.807.164-3.637.288-5.424.612-.995.18-3.914,1.635-4.709.74C-1.27,1.789,7.139.9,8.281.786,12.34.369,16.4.056,20.485.017c2.756-.022,7.473-.256,9.5,2C31.2,3.368,31.628,5.6,32.1,7.285c.365,1.3,1.354,3.635.651,4.974a1.384,1.384,0,0,1-1.607.908,1.461,1.461,0,0,1-.435-.19,1.121,1.121,0,0,1,.236,1.226c-.464,1.075-2.174.842-3.1.92-3.1.257-6.213.429-9.322.456-1.477.013-2.954,0-4.431-.012-1.528-.012-3.056-.024-4.585-.009C7.233,15.58,4.872,16,2.6,16Q2.257,16,1.918,15.987Z"
                transform="translate(34 77)"
                fill="#89c5cc"
              />
              <path
                id="Line"
                d="M31.141,16c-.77,0-1.563-1.276-2.088-2.12a7.416,7.416,0,0,0-.444-.669,7.244,7.244,0,0,0-3.561-2.561,42.949,42.949,0,0,0-8.6-1.569c-.986-.112-2.006-.228-2.983-.364-.5-.07-1.015-.133-1.557-.2C9.293,8.2,6.326,7.831,4.089,6.6,3.109,6.058.015,3.3,0,1.456A1.33,1.33,0,0,1,.74.237,1.577,1.577,0,0,1,1.508,0c.748,0,1.164.793,1.531,1.493.117.223.228.434.343.608A6.1,6.1,0,0,0,7.132,4.74a57.626,57.626,0,0,0,7.252,1.388c.626.092,1.274.188,1.907.287.518.081,1.055.16,1.575.236a33.923,33.923,0,0,1,8.42,1.97,15.28,15.28,0,0,1,3.239,2.606c.9.9,2.41,2.581,2.473,3.747a.936.936,0,0,1-.5.929A.719.719,0,0,1,31.141,16Z"
                transform="translate(55 60)"
                fill="#c1dee2"
              />
              <path
                id="Wireframy_Stuff-4"
                data-name="Wireframy Stuff"
                d="M3.365,16.809c-1.061-.1-2.2-.485-1.939-1.843A17.491,17.491,0,0,1,.282,10.221,51.032,51.032,0,0,1,.015,3.184,1.517,1.517,0,0,1,2.451,2a18.078,18.078,0,0,1,4.568-.739c1.9-.193,3.808-.266,5.709-.382C17.167.606,21.6.12,26.046.02c1.331-.028,3.113-.15,4.177.847C31.434,2,31.676,4.817,32.039,6.334a37.445,37.445,0,0,1,1.03,5.014,9.658,9.658,0,0,1,.1,1.688,1.055,1.055,0,0,1,.829,1.217c-.1,1.105-1.613,1.256-2.495,1.431a55.158,55.158,0,0,1-9.049.535c-4.826.138-9.91.782-14.841.782C6.179,17,4.76,16.946,3.365,16.809Zm.619-3.144c.137,0,.271,0,.4,0q4.7-.084,9.39-.274c3.191-.124,6.382-.264,9.576-.3,1.112-.013,2.24.023,3.371.058,1.448.045,2.9.091,4.338.035-.55-1.46-.467-3.759-.759-5.271a24.369,24.369,0,0,0-1-4.693c-.669-1.586-1.251-1.219-3-1.1-1.793.123-3.583.3-5.374.446C17,2.9,13.056,3.1,9.116,3.382c-2.043.144-4,.736-6,.968C3.089,5.074,2.988,5.8,3,6.333c.033,1.558.184,3.106.318,4.657.054.631.311,1.72.349,2.675h.318Z"
                transform="translate(69 76)"
                fill="#89c5cc"
              />
            </g>
            <g id="Profile" transform="translate(3 97)" opacity="0.5">
              <path
                id="face"
                d="M14.124,24h0A15.552,15.552,0,0,1,6.59,22a13.5,13.5,0,0,1-5.343-5.181A9.648,9.648,0,0,1,0,11.859,10.845,10.845,0,0,1,1.8,6.33c-.721-.144-1.114-.4-1.166-.764-.142-.986,2.21-2.447,2.8-2.755L3.644,2.7A20.647,20.647,0,0,1,10.025.2,19.528,19.528,0,0,1,12.856,0a18.1,18.1,0,0,1,7.39,1.5,11.506,11.506,0,0,1,5.492,4.835,10.584,10.584,0,0,1,.789,8.18,13.8,13.8,0,0,1-4.7,6.927A12.422,12.422,0,0,1,14.124,24ZM12.6,2.881a10.686,10.686,0,0,0-8,3.465,7.984,7.984,0,0,0-1.936,6.5,9.947,9.947,0,0,0,3.827,6.37,11.724,11.724,0,0,0,7.131,2.57c.225,0,.452-.008.675-.023a10.8,10.8,0,0,0,6.532-2.855,10.451,10.451,0,0,0,3.329-6.218,7.231,7.231,0,0,0-1.639-5.923A10.518,10.518,0,0,0,18.211,3.9a16.336,16.336,0,0,0-5.376-1.012Z"
                transform="translate(33)"
                fill="#89c5cc"
              />
              <path
                id="Fill_231"
                data-name="Fill 231"
                d="M0,1.5a1.5,1.5,0,0,0,3,0,1.5,1.5,0,0,0-3,0"
                transform="translate(40 9)"
                fill="#89c5cc"
              />
              <path
                id="Fill_233"
                data-name="Fill 233"
                d="M0,1.5a1.5,1.5,0,0,0,3,0,1.5,1.5,0,0,0-3,0"
                transform="translate(48 9)"
                fill="#89c5cc"
              />
              <path
                id="Fill_235"
                data-name="Fill 235"
                d="M.034.753C-.519,2.959,5.789,4.091,6,1.538,6.1.318.419-.786.034.753"
                transform="translate(43 15)"
                fill="#89c5cc"
              />
              <path
                id="Fill_237"
                data-name="Fill 237"
                d="M.652,3.865c6.6-.812,13.411-.437,20.046-.242,1.661.049,3.321.1,4.982.125,1.056.018,3.336.537,4.257.031C33.71,1.705,26.308.7,25.26.607A223.091,223.091,0,0,0,1.856.115C-.1.192-.524,4.009.652,3.865"
                transform="translate(58 11)"
                fill="#89c5cc"
              />
              <path
                id="Hola_:_"
                data-name="Hola :)"
                d="M89.242,34.293c.057-6.813.3-13.672.835-20.465.216-2.769.475-5.534.69-8.3.1-1.3.571-3.131-.206-4.275A2.386,2.386,0,0,0,86.947.871c-1.267,1.618-.9,4.334-1.056,6.291-.242,3.01-.535,6.017-.68,9.033A136.745,136.745,0,0,0,85.339,33.8,52.8,52.8,0,0,0,86.7,41.919c.295,1.221,2.64,6.661,2.147,7.411-.764,1.163-6.412.724-7.637.756-3.144.083-6.288.137-9.433.2q-4.038.076-8.077.111c-1.069.009-7.35-.178-5.957,1.386-15.581,1.072-31.459-.717-46.945-1.331-.782-.031-2.062-.25-3.243-.313a10.061,10.061,0,0,1-.287-1.416c-.2-2.456-.257-4.921-.477-7.377-.4-4.493-1.187-8.94-1.652-13.426s-.418-8.975-.689-13.466c-.046-.77-.156-7.074-1.768-8.605a7.41,7.41,0,0,0,2.706.267c5.745-.457,11.447-1.443,17.143-2.288,1.629-.242,5.327-.07,6.679-.874,1.17-.7,1.633-3.136-.4-2.938-5.679.6-11.327,1.532-16.962,2.45-1.507.245-3.01.508-4.513.776C6.326,3.416,3.677,3.4,2.9,4c-.946.725-1.046,1.218-.725,1.546-.445-.121-.979.069-1.621.7C-.66,7.805.48,11.641.624,13.467c.174,2.19.259,4.385.36,6.579a107.679,107.679,0,0,0,1.338,12.63c.776,4.7,1.436,9.419,2.214,14.118a32.3,32.3,0,0,0,.8,3.6c-2.1,1.058-.671,2.74.991,3.038l.036.006c.518,1.247,1.242,2.4,1.808.731a1.632,1.632,0,0,0,.069-.463c4.292.48,9.01.182,13.236.352,9.153.369,18.363.923,27.526.642a107.726,107.726,0,0,0,10.863-.907c2.107-.28,4.223-.614,6.3-1.078.148-.033.374-.068.635-.11l.126,0q4.763-.071,9.521-.262c2.663-.109,5.338-.165,7.993-.413,1.649-.154,5.134-.125,6.146-1.762.921-1.488-.618-5.321-.837-6.992a64.445,64.445,0,0,1-.507-8.879"
                transform="translate(0 12)"
                fill="#c1dee2"
              />
              <path
                id="Fill_241"
                data-name="Fill 241"
                d="M32.755.016C30.285.24,28,1.464,25.509,1.479c-2.092.013-4.152-.648-6.255-.55-2.467.114-3.825,1.8-5.986,2.362-1.721.449-2.8-.942-4.318-1.557-1.911-.777-3.323-.376-4.961.8C2.785,3.4-.022,5.334,0,7.08c.064,5.289,5.91-2.017,7.088-2.106,1.566-.118,3.315,1.767,4.981,1.874,2.2.142,3.615-1.357,5.643-1.9,2.051-.552,4.268.124,6.324.334a18.269,18.269,0,0,0,6.669-.9,12.781,12.781,0,0,1,6.309-.061c1.508.325,5.266,2.213,5.926-.215C43.838.794,34.482-.139,32.755.016"
                transform="translate(24 27)"
                fill="#89c5cc"
              />
              <path
                id="Fill_243"
                data-name="Fill 243"
                d="M25.789.006C20.609.461,15.4.859,10.2.987,7.943,1.042-.72.169.048,2.758.707,4.977,12.5,3.58,14.9,3.411c2.651-.186,5.3-.446,7.935-.748.935-.107,3.192,0,3.852-.705A1.143,1.143,0,0,0,25.789.006"
                transform="translate(33 37)"
                fill="#89c5cc"
              />
              <path
                id="have_a_nice_day_:_"
                data-name="have a nice day :)"
                d="M5.8,12h0A5.328,5.328,0,0,1,1.622,9.879,7.564,7.564,0,0,1,0,5.359C-.041,2.857,1.319.135,5.262,0h.055a.891.891,0,0,1,.738.349.742.742,0,0,0,.485.29h.015a.955.955,0,0,0,.4-.206L7.011.394A1.115,1.115,0,0,1,7.56.15h.024c1.437.094,2.7.3,3.678,1.839a6.348,6.348,0,0,1,.3,5.337A6.806,6.806,0,0,1,7.876,11.61,6.011,6.011,0,0,1,5.8,12Zm1.154-9.16a1.156,1.156,0,0,0-.622.189,2.061,2.061,0,0,1-1.071.291c-1.955.063-2.144,1.432-2.135,2.018a3.962,3.962,0,0,0,.9,2.32A2.774,2.774,0,0,0,6.106,8.806a2.459,2.459,0,0,0,1.762-.844,5.024,5.024,0,0,0,1.1-2.176,2.862,2.862,0,0,0-.255-2.341c-.316-.4-.447-.407-.685-.426a2.126,2.126,0,0,1-.44-.074A2.389,2.389,0,0,0,6.956,2.841Z"
                transform="translate(18 46)"
                fill="#89c5cc"
              />
              <path
                id="espero"
                d="M4.862,10H4.756A4.73,4.73,0,0,1,1.2,8.357,4.967,4.967,0,0,1,.041,4.47C.38,1.894,2.1.488,5.457.044A5.09,5.09,0,0,1,6.112,0,3.583,3.583,0,0,1,8.826,1.138,5.068,5.068,0,0,1,9.978,5,5.818,5.818,0,0,1,8.547,8.437,4.831,4.831,0,0,1,4.862,10ZM4.108,3.587h0l.007,0A1.59,1.59,0,0,0,3.464,5.44a1.671,1.671,0,0,0,1.551,1.3,1.763,1.763,0,0,0,.59-.107A2.451,2.451,0,0,0,6.856,5.01a1.012,1.012,0,0,0-.21-.967c.021.021.028.037.024.048s-.042.026-.1.026a8.507,8.507,0,0,1-1.407-.265c-.443-.106-.843-.211-.993-.25Z"
                transform="translate(41 46)"
                fill="#89c5cc"
              />
              <path
                id="hola"
                d="M4.3,12h0A4.148,4.148,0,0,1,1.28,10.806,4.517,4.517,0,0,1,.025,8.068,4.734,4.734,0,0,1,.82,4.9,4.963,4.963,0,0,1,4.008,2.907,1.463,1.463,0,0,1,3.38.9,1.547,1.547,0,0,1,4.668,0c1.83,0,4,4.171,4.185,5.23A7.653,7.653,0,0,1,8.575,9.3a3.99,3.99,0,0,1-3.05,2.562A5.977,5.977,0,0,1,4.3,12ZM5.641,6.144C5,6.2,3.517,6.429,3.557,7.388a1.161,1.161,0,0,0,1.1,1.031.788.788,0,0,0,.591-.264A2.6,2.6,0,0,0,5.641,6.144Z"
                transform="translate(72 45)"
                fill="#89c5cc"
              />
            </g>
          </g>
          <path
            id="Background_Shape"
            data-name="Background Shape"
            d="M.228,46.672C4.818,23.044,51.265,36.5,81.715,29.2s66.474-41.323,90.316-24.659-1.526,56.946-56.208,66.036S-4.362,70.3.228,46.672Z"
            transform="translate(0 180)"
            fill="#f2f2f2"
          />
          <g id="Group_46" data-name="Group 46" transform="translate(7)">
            <path
              id="Fill_1"
              data-name="Fill 1"
              d="M34.328,0H.12C-.545,2.159,1.776,4.225,1.559,5.789.8,11.325,5.5,24.41,5.5,24.41l1.254,7.2L8.429,58.888S6.976,72.226,6.127,82.8c-.13,1.627-.189,3.218-.193,4.786A11.767,11.767,0,0,0,6,88.8c.241,8.131,2.949,15.678,3.085,24.19L13.263,114s1.6-14.021,3.25-26.418c.277-2.1.559-4.143.833-6.072-.035.43-.072.863-.106,1.285-.131,1.627-.189,3.218-.193,4.786A12.107,12.107,0,0,0,17.11,88.8c.242,8.131,2.949,15.678,3.085,24.19L24.374,114s1.6-14.021,3.249-26.418c1.179-8.87,2.384-16.908,3.048-18.377,1.033-2.29,2.891-22.094,4.834-36.024.776-5.309.54-20.771-.027-25.983C35.2,4.666,34.653,2.51,34.328,0Z"
              transform="translate(17 116)"
              fill="#8d66fc"
            />
            <g id="Group_5" data-name="Group 5" transform="translate(35 227)">
              <path
                id="Clip_4-2"
                data-name="Clip 4"
                d="M0,0H14.523V7.056H0Z"
                transform="translate(0.309 0.944)"
                fill="none"
              />
              <g id="Group_5-2" data-name="Group 5" clipPath="url(#clip-path)">
                <path
                  id="Fill_3"
                  data-name="Fill 3"
                  d="M1.31,0,0,7.056H14.523L6.546,3.528,5.7,0Z"
                  transform="translate(0.309 0.944)"
                  fill="#393762"
                />
              </g>
            </g>
            <path
              id="Fill_6"
              data-name="Fill 6"
              d="M1.108,61.768l2.948,4.826L38.016,68l.463-4.728,1.679-1.1a4.044,4.044,0,0,0,1.753-4.23L33.217,36.008a68.546,68.546,0,0,1-.125-10.34c.449-3.385-7.831-21.9-9.424-23.973s-7.9-2.445-10.572,0S3.158,14.855,2.54,17.111,0,47.7,0,47.7-.067,60.525,1.108,61.768Z"
              transform="translate(14 55)"
              fill="#ece0d3"
            />
            <path
              id="Fill_8"
              data-name="Fill 8"
              d="M30,0C26.474.853,11.217,9.707,9.791,12.561S-3.228,46.339.874,49.792,17.638,48.2,17.638,48.2L19.32,29.87Z"
              transform="translate(0 54)"
              fill="#ece0d3"
            />
            <path
              id="Fill_10"
              data-name="Fill 10"
              d="M3.354,7.876A13.788,13.788,0,0,1,0,.4.358.358,0,0,1,.326,0,.367.367,0,0,1,.732.317,13.075,13.075,0,0,0,3.911,7.41a.351.351,0,0,1-.039.5.376.376,0,0,1-.518-.038Z"
              transform="translate(34 129)"
              fill="#734e4f"
            />
            <g id="Group_14" data-name="Group 14" transform="translate(24 227)">
              <path
                id="Clip_13-2"
                data-name="Clip 13"
                d="M0,0H14.385V7.056H0Z"
                transform="translate(0.966 0.944)"
                fill="none"
              />
              <g id="Group_14-2" data-name="Group 14" clipPath="url(#clip-path-2)">
                <path
                  id="Fill_12"
                  data-name="Fill 12"
                  d="M1.3,0,0,7.056H14.385l-7.9-3.528L5.648,0Z"
                  transform="translate(0.966 0.944)"
                  fill="#393762"
                />
              </g>
            </g>
            <path
              id="Fill_15"
              data-name="Fill 15"
              d="M.361,71a.356.356,0,0,1-.351-.438c.079-.309,7.7-30.727,9.014-36.468,1.3-5.7.59-29.662.584-29.9a.356.356,0,0,1,.1-.255L13.376.11A.365.365,0,0,1,13.887.1.349.349,0,0,1,13.9.6L10.334,4.315c.064,2.218.677,24.317-.606,29.933C8.415,40,.787,70.424.712,70.73A.36.36,0,0,1,.361,71"
              transform="translate(34 124)"
              fill="#734e4f"
            />
            <path
              id="Fill_17"
              data-name="Fill 17"
              d="M.355.88A.333.333,0,0,1,0,.578a.331.331,0,0,1,.353-.3L31.644.12h0a.333.333,0,0,1,.355.3.331.331,0,0,1-.353.3L.356.88Z"
              transform="translate(19 118)"
              fill="#cfb199"
            />
            <path
              id="Fill_19"
              data-name="Fill 19"
              d="M4.208,13.3s2.747-2.639,3.315-3.076S9.854,5.272,10,4.835s-3.252-.657-3.252-.657a24.753,24.753,0,0,0-.273-2.861C6.377.939,5.6-.755,5.253.393L4.5,3.733l-1.076,1.1a19.394,19.394,0,0,0-1.2,4.154L0,13.784Q1.781,15.373,3.533,17Z"
              transform="translate(76 17)"
              fill="#9c4121"
            />
            <g id="Group_23" data-name="Group 23" transform="translate(80)">
              <path
                id="Clip_22-2"
                data-name="Clip 22"
                d="M0,0H9.092V25.923H0Z"
                transform="translate(0.713 0.335)"
                fill="none"
              />
              <g id="Group_23-2" data-name="Group 23" clipPath="url(#clip-path-3)">
                <path
                  id="Fill_21"
                  data-name="Fill 21"
                  d="M8.073,0l-5.8,17.651L0,25.716l2.107.208L9.092.579c-.51-.288-.5-.3-1.019-.579"
                  transform="translate(0.713 0.334)"
                  fill="#ece0d3"
                />
              </g>
            </g>
            <path
              id="Fill_24"
              data-name="Fill 24"
              d="M1.9,3.7c.39-.965-.479-3.958-.826-3.682S.589,1.849.589,1.849l.334.921s-.572-.223-.6,0S.091,4.019.091,4.019L0,6Z"
              transform="translate(84 18)"
              fill="#e48868"
            />
            <path
              id="Fill_26"
              data-name="Fill 26"
              d="M6.41,47c3.566-3.652,13.41-12.445,16.584-16.941C25.994,25.81,35,4.849,35,4.849L30.049,0,15.667,20.427S5.471,26.786,0,29.093C3.323,35.193,2.73,41.115,6.41,47"
              transform="translate(45 28)"
              fill="#ece0d3"
            />
            <path
              id="Fill_28"
              data-name="Fill 28"
              d="M0,0C28.227,1.89,29.722,4.362,21.975,13.257L17.756,47,7.814,32.512Z"
              transform="translate(33 55)"
              fill="#ece0d3"
            />
            <path
              id="Fill_30"
              data-name="Fill 30"
              d="M4.988,3.927,10,0,4.988,12.024,0,13Z"
              transform="translate(26 81)"
              fill="#dc5955"
            />
            <path
              id="Fill_32"
              data-name="Fill 32"
              d="M0,7.747,1.607,0,9,2.731,3.721,14Z"
              transform="translate(22 82)"
              fill="#dc5955"
            />
            <path
              id="Fill_34"
              data-name="Fill 34"
              d="M2.28,6.945,5.367,3.936S7.825.29,8.849,0L9.3,2.423s7.482-1.078,7.669-.745S18,3.52,18,3.52l-2.249.833s.832.919.737,1.319a3.073,3.073,0,0,1-.462.89,8.141,8.141,0,0,1,.346,2.049c.02,1.038-1.808.1-2.176,1.011s-5.753,1.552-6.514,1.552S0,13,0,13Z"
              transform="translate(12 88)"
              fill="#9c4121"
            />
            <path
              id="Fill_36"
              data-name="Fill 36"
              d="M4.655.951.3.656A.313.313,0,0,1,0,.331.309.309,0,0,1,.345.051L4.7.346A.312.312,0,0,1,5,.67a.315.315,0,0,1-.322.281Z"
              transform="translate(9 93)"
              fill="#9c4121"
            />
            <path
              id="Fill_38"
              data-name="Fill 38"
              d="M.349,27a.366.366,0,0,1-.141-.029.347.347,0,0,1-.178-.46A88.861,88.861,0,0,0,5.969,7.257a11.911,11.911,0,0,0,.28-3.645A4.176,4.176,0,0,0,4.64.636a.349.349,0,0,1,.4-.574A4.871,4.871,0,0,1,6.943,3.525a12.513,12.513,0,0,1-.286,3.859A89.379,89.379,0,0,1,.668,26.79.346.346,0,0,1,.349,27"
              transform="translate(12 66)"
              fill="#cfb199"
            />
            <path
              id="Fill_40"
              data-name="Fill 40"
              d="M14.65,26a.351.351,0,0,1-.3-.178L.046.531A.359.359,0,0,1,.175.047.348.348,0,0,1,.653.178l14.3,25.291a.358.358,0,0,1-.131.483A.33.33,0,0,1,14.65,26"
              transform="translate(34 71)"
              fill="#cfb199"
            />
            <path
              id="Fill_42"
              data-name="Fill 42"
              d="M20.454,22.409s3.126-.189,3.979-1.864c.357-.7.1-3.142-.878-6.81-.1-.393,1.556-.093,1.44-.493-.225-.777-3.9-3.406-4.14-4.9-.9-5.482-1.782-6.728-3.741-7.7C12.76-1.511-.3,1.942.005,8.18s3.117,8.833,8.61,15.965l-.19,1.209a5.609,5.609,0,1,0,11.027,2.061Z"
              transform="translate(21 29)"
              fill="#9c4121"
            />
            <path
              id="Fill_44"
              data-name="Fill 44"
              d="M10.654,26l1.366-6.4s-5.407-3.036-3.083-6.024,9.228,7.007,9.228,7.007l.609-1.485-3.121-6.482s2.2-2.01,1.733-4.607c0,0,6.086-2.382,5.584-6.616S4.108,3.58.971,7.154,2.626,17.837,10.654,26"
              transform="translate(19 27)"
              fill="#393762"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
