import React, { FC } from 'react';
import { StyledSvgIcon } from '../components/shared/image';

export const SoftwareToolSvg: FC = () => {
  return (
    <StyledSvgIcon
      version="1.1"
      id="Group_10_Copy"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 140.2 114.5"
      xmlSpace="preserve"
    >
      <g>
        <path
          id="Fill_8"
          fill={'#F0F2FA'}
          d="M17,110.6h104c8.7-5.5,15.5-13.9,14.8-23.8c-0.7-9.1-7.5-16.3-13.2-23.4s-10.9-16.4-7.7-24.9
		c3.2-8.6,13.8-13.5,14.9-22.5c0.9-7.8-6.5-14.8-14.3-15.7S99.8,2.7,93.2,6.9s-12.8,9.4-20.1,12.4s-16.3,3.6-22.5-1.2
		c-2.8-2.1-4.9-5.1-7.6-7.3c-7.5-5.9-19.3-4.3-26,2.4s-8.6,17.4-6.2,26.6c1.7,6.2,5.1,11.9,6.8,18.1s1.3,13.8-3.6,18
		c-3.9,3.4-10.2,4.3-12.6,8.9C-3.9,95.1,7,104.5,17,110.6"
        />
        <path
          id="Fill_62"
          fill="#FF4DFF"
          d="M36.3,73.4c1.5-1.3,2.6-3,3.3-4.9c0.6-1.9,0-4.3-1.7-5.2c-1.2-0.5-2.6-0.5-3.8,0
		c-0.7-2-2-4.2-3.9-5c-1.9-0.9-4.3-0.5-5.7,1.1c-1,1.2-1.2,2.9-2.2,4.1c-1.7,2.3-5.3,2.6-6.7,5c-1,1.8-0.4,4.1,0.4,6
		c0.8,1.9,1.8,3.8,1.7,5.9c-0.3,3.9-4.9,6.8-4.5,10.7c0.2,2,1.6,3.7,2.1,5.6c0.9,4.2-3.1,8.2-2.3,12.3c0.6,3.1,3.8,5.3,7,5.2
		c3.2-0.2,6.1-1.8,8-4.4c1.9-2.6,3.3-5.4,4.2-8.4c1.1-3.4,2.1-6.8,2.9-10.2c0.6-1.9,0.8-3.9,0.6-5.8c-0.3-2.3-1.5-4.4-1.8-6.7
		S34,73.6,36.3,73.4"
        />
        <path
          id="Fill_66"
          fill="#0062BE"
          d="M26.2,101.4c-0.4-3.1-0.6-6.2-0.5-9.3c0.1-3.1,1.1-6.1,2.9-8.6c2.1-2.7,5.9-2.9,8.7-1.1
		s4.3,5.4,4.7,8.9c0.2,3.6-0.2,7.1-1.1,10.5c-0.9,3.4-1.5,6.9-1.7,10.5c-3.5,2.3-9.9,3.3-12.9,0.2C25.8,108.8,26.6,105.1,26.2,101.4
		z"
        />
        <path
          id="Fill_68"
          fill={'#FFC43A'}
          d="M33.5,76.4l2,6.6c-1.7,0.8-3.5,1.1-5.4,0.8c-0.3-2.3-0.7-4.6-0.9-6.9C30.5,76.2,32,76,33.5,76.4"
        />
        <path
          id="Fill_69"
          fill={'#E09F2D'}
          d="M33.3,76.4c-1.4-0.3-2.9-0.1-4.1,0.5c0.1,1.3,0.3,2.6,0.5,4c1.7,0.9,3.6,1.5,5.5,2L33.3,76.4"
        />
        <path
          id="Fill_70"
          fill={'#FFC43A'}
          d="M37,67.7c0.6,4.1,0.3,8.4-0.9,12.4c-2.6-0.1-5.7-0.5-7.8-2.1c-2.2-1.6-3.5-4.1-3.6-6.8
		c0-2.7,1.7-5.1,4.2-6.1C31.6,64.3,36.6,65.1,37,67.7"
        />
        <path
          id="Fill_71"
          fill="#FF4DFF"
          d="M38.4,69.6c0.1,0.4-0.1,0.9-0.4,1.1c-0.3,0.3-0.7,0.5-1.1,0.6c-1.2,0.3-2.5-0.1-3.3-1.1
		c-1.5,0.7-1.9,3.1-1.8,4.7c0.2,1.7,0.4,3.3,0.8,4.9c0.4,1.6,0,3.4-1,4.7c-0.6,0.7-1.4,1.1-1.9,1.9c-0.7,1.1-0.4,2.4-0.4,3.7
		c0.1,3.1-0.9,6.2-2.9,8.6c-0.4,0.6-1.1,1.1-1.8,1.4c-0.8,0.2-1.6,0.1-2.3-0.2c-1.9-0.6-3.7-1.7-5-3.3c-1.3-1.6-1.9-3.6-1.6-5.7
		c0.7,0.1,1.4-0.1,1.9-0.6c0.6-0.5,1.1-1.1,1.6-1.7l3.7-4.9c0.9-1,1.6-2.2,1.9-3.5c0.1-1.5-0.2-3-0.9-4.3c-0.8-2.3-1.3-4.6-1.6-7
		c-0.2-2,2.3-3.9,4.1-4.5c1.9-0.5,3.9-0.6,5.9-0.3c1.5,0.1,2.9,0.5,4.2,1.2C38,66.2,38.1,68.2,38.4,69.6"
        />
        <g id="Group_3" transform="translate(67.9 25.9)">
          <path id="Clip_2-2" fill={'none'} d="M0.1,0h72.2v38H0.1V0z" />
          <g>
            <defs>
              <rect id="SVGID_1_" x="0.1" y="0" width="72.2" height="38" />
            </defs>
            <clipPath id="SVGID_00000111172062892651006370000017701033806355363721_">
              <use xlinkHref="#SVGID_1_" />
            </clipPath>
            <g id="Group_3-2" clipPath={'url(#SVGID_00000111172062892651006370000017701033806355363721_)'}>
              <path
                id="Fill_1"
                fill="#0062BE"
                d="M67.7,0H27.8c-2.5,0-4.6,2.1-4.6,4.6v11.1c0,2.5,2.1,4.6,4.6,4.6h1.8L0.1,38.1l44-17.7h23.6
					c2.5,0,4.6-2.1,4.6-4.6V4.6C72.3,2.1,70.2,0,67.7,0C67.7,0,67.7,0,67.7,0"
              />
            </g>
          </g>
        </g>
        <path
          id="Arm"
          fill={'#DE80FF'}
          d="M61,63.6c0,0,1.2-10.5,1.7-11.6c1-1.2,2.1-2.3,3.3-3.2c0.5-0.4,0.3-0.8,0.1-1
		c-1.7-0.9-2.7,0.3-4.1,1.1c-1.5,0.8-1.5-1.2-2.7-0.6c0.4,0.8,1.4,2.4,1,3.4c-0.2,0.7-3.1,12.1-3.1,12.1L61,63.6z"
        />
        <path
          id="Fill_67"
          fill="#0062BE"
          d="M37.4,82.8c6.1,0.5,8.8-2.5,13.1-7c4.9-5.1,6.8-12.4,8.5-19.2c1,0,2.1,0.2,3,0.6
		c-0.6,7.3-1.3,15.1-4.9,21.5S47.2,90.4,39.9,91C36.7,88.9,37.4,82.8,37.4,82.8z"
        />
      </g>
    </StyledSvgIcon>
  );
};
