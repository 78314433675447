import { Container, Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import React, { FC, MouseEventHandler, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCompany, getCompanies, setCompanyPage, setCompanyPageSize } from '../redux/managementSlice';
import { RootState } from '../store';
import { IListCompaniesCompanyItem } from '../services/generated/ApiClientGenerated';
import { Delete, Edit } from '@mui/icons-material';
import { EditCompanyModal } from '../components/management/editCompanyModal';
import { AddCompany } from '../components/management/addCompany';

const GridText = (params: GridRenderCellParams) => <Typography>{params.value}</Typography>;
type ActionButtonProps = { onClick: MouseEventHandler<HTMLButtonElement> };
const EditCompany: FC<ActionButtonProps> = ({ onClick }) => (
  <Tooltip title={'Edit company'}>
    <IconButton size="small" onClick={onClick} sx={{ ':hover': { color: 'primary.main' } }}>
      <Edit />
    </IconButton>
  </Tooltip>
);

const DeleteCompany: FC<ActionButtonProps> = ({ onClick }) => {
  return (
    <Tooltip title={'Delete company'}>
      <IconButton size="small" onClick={onClick} sx={{ ':hover': { color: 'warning.main' } }}>
        <Delete />
      </IconButton>
    </Tooltip>
  );
};

type CompanyRowActionsProps = {
  onEditClick: MouseEventHandler<HTMLButtonElement>;
  onDeleteClick: MouseEventHandler<HTMLButtonElement>;
};

const CompanyRowActions: FC<CompanyRowActionsProps> = ({ onEditClick, onDeleteClick }) => (
  <>
    <EditCompany onClick={onEditClick} />
    <DeleteCompany onClick={onDeleteClick} />
  </>
);

export const CompanyManagement: FC = () => {
  const [editCompany, setEditCompany] = useState<IListCompaniesCompanyItem | undefined>();
  const companies = useSelector((r: RootState) => r.management.companies);
  const companyRows = useMemo(() => companies.value?.map((c) => ({ ...c, key: c.id })) ?? [], [companies]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCompanies());
  }, []);

  const handleDeleteCompany = (company: GridRenderCellParams) => {
    const companyToDelete = companies.value?.find((c) => c.id === company.id);
    if (companyToDelete?.id) {
      dispatch(deleteCompany(companyToDelete.id));
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Company Name',
      minWidth: 200,
      renderCell: GridText,
    },
    {
      field: 'domainName',
      headerName: 'Company Email Domain',
      minWidth: 360,
      flex: 1,
      renderCell: GridText,
    },
    {
      field: 'userCount',
      headerName: 'Number of Users',
      minWidth: 200,
      flex: 1,
      renderCell: GridText,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: (params) => (
        <CompanyRowActions
          onEditClick={() => {
            setEditCompany(companies.value?.find((c) => c.id === params.id));
          }}
          onDeleteClick={() => {
            handleDeleteCompany(params);
          }}
        />
      ),
    },
  ];
  return (
    <Container maxWidth="lg">
      <EditCompanyModal company={editCompany} onClose={() => setEditCompany(undefined)} />
      <Grid container direction="row" spacing={3}>
        <Grid item xs={12}>
          <Typography component={'h1'} variant={'h3'}>
            Manage Companies
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 1.5 }}>
            Manage all companies using Mailroom
          </Typography>
          <Paper sx={{ marginTop: 3, padding: 2 }}>
            <Grid container spacing={2} direction="column">
              <AddCompany />
              <Grid item sx={{ height: 640, flexGrow: 1 }}>
                <DataGrid
                  getRowId={(row) => row.id}
                  loading={companies.isLoading}
                  rows={companyRows}
                  columns={[...columns, { field: 'actions', sortable: false }]}
                  page={companies.page}
                  onPageChange={(page) => dispatch(setCompanyPage(page))}
                  rowCount={companies.totalCount}
                  pageSize={companies.pageSize}
                  disableColumnMenu
                  disableSelectionOnClick
                  onPageSizeChange={(pageSize) => dispatch(setCompanyPageSize(pageSize))}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
