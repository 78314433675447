import React, { FC } from 'react';
import { DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DialogTitleProps } from '@mui/material/DialogTitle/DialogTitle';

export type StyledDialogTitleProps = {
  onClose: () => void;
} & DialogTitleProps;

export const StyledDialogTitleWithX: FC<StyledDialogTitleProps> = ({ children, onClose, ...other }) => {
  return (
    <DialogTitle {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
