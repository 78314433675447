import React, { FC } from 'react';
import { Grid } from '@mui/material';
import { JobHistoryCard } from './jobHistoryCard';
import { ProfileDetails } from '../../../redux/profileSlice';
import { Loading } from '../../shared/loading';

export interface ProfileProps {
  loading: boolean;
  profileDetails: ProfileDetails;
  children?: JSX.Element;
}

export const JobHistory: FC<ProfileProps> = ({ loading, profileDetails }) => {
  return (
    <Loading
      noDataMessage="No job history information found. Add some using the button below!"
      loading={loading}
      value={profileDetails?.jobHistories}
    >
      <Grid container direction="column" sx={{ position: 'relative' }}>
        {(profileDetails?.jobHistories || []).map((x, index) => (
          <Grid item key={index} mb={1.5}>
            <JobHistoryCard jobHistoryEntry={x} />
          </Grid>
        ))}
      </Grid>
    </Loading>
  );
};
