import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, race, select, take, takeLatest } from 'redux-saga/effects';
import { getProfile, profileSlice } from '../redux/profileSlice';
import { cancelConfirmAction, okConfirmAction, showConfirmAction, showToastAction } from '../redux/globalSlice';
import {
  AddJobHistoryRequest,
  AddOrUpdateProfileResponse,
  FileParameter,
  GetProfileResponse,
  Skill,
  UpdateJobHistoryRequest,
} from '../services/generated/ApiClientGenerated';
import { apiClient } from '../services/apiClient';
import { RootState } from '../store';

function* addJobHistorySaga(action: PayloadAction<AddJobHistoryRequest>) {
  try {
    yield call([apiClient, apiClient.profile_AddJobHistory], action.payload);
    yield put(getProfile());
  } catch {
    yield put(
      showToastAction({
        message: 'Could not add job history',
        severity: 'warning',
      }),
    );
  }
}

function* deleteJobHistorySaga() {
  yield put(showConfirmAction({ body: 'Are you sure you want to delete this job? This action is irreversable.' }));
  const { yes } = yield race({ yes: take(okConfirmAction), no: take(cancelConfirmAction) });
  if (yes) {
    const jobHistoryId: string = yield select((r: RootState) => r.profile.jobHistoryToEdit.id);
    yield call([apiClient, apiClient.profile_DeleteJobHistory], jobHistoryId);
    yield put(
      showToastAction({
        message: 'Successfully deleted job',
        severity: 'success',
      }),
    );
    yield put(getProfile());
  }
  try {
  } catch {
    yield put(
      showToastAction({
        message: 'Could not delete job history',
        severity: 'warning',
      }),
    );
  }
}

function* getProfileSaga() {
  try {
    const response: GetProfileResponse = yield call([apiClient, apiClient.profile_GetCurrentUserProfile]);
    yield put(
      profileSlice.actions.setProfile({
        ...response.toJSON(),
        jobHistories: response.jobHistories?.map((entry) => ({
          ...entry,
          endDate: entry.endDate?.toISOString(),
          startDate: entry.startDate?.toISOString(),
        })),
      }),
    );
  } catch {
    yield put(
      showToastAction({
        message: 'Could not get profile',
        severity: 'warning',
      }),
    );
  }
}

function* updateAvatarSaga(action: PayloadAction<FileParameter>) {
  try {
    yield call([apiClient, apiClient.profile_SetAvatar], action.payload);
    yield put(getProfile());
  } catch {
    yield put(
      showToastAction({
        message: 'Could not update profile image',
        severity: 'warning',
      }),
    );
  }
}

function* updateProfileDetailsSaga(action: PayloadAction<AddOrUpdateProfileResponse>) {
  try {
    yield call([apiClient, apiClient.profile_UpdateCurrentUserProfile], action.payload);
    yield put(getProfile());
    yield put(showToastAction({ message: 'Your profile has been updated!', severity: 'success' }));
  } catch {
    yield put(
      showToastAction({
        message: 'Could not update profile',
        severity: 'warning',
      }),
    );
  }
}

function* updateJobHistorySaga(action: PayloadAction<UpdateJobHistoryRequest>) {
  const jobHistoryId: string = yield select((r: RootState) => r.profile.jobHistoryToEdit.id);
  try {
    yield call([apiClient, apiClient.profile_UpdateJobHistory], jobHistoryId, action.payload);
    yield put(getProfile());
    yield put(
      showToastAction({
        message: 'Your job history has been updated!',
        severity: 'success',
      }),
    );
  } catch {
    yield put(
      showToastAction({
        message: 'Could not update job history',
        severity: 'warning',
      }),
    );
  }
}

function* updateTechSkillsSaga(action: PayloadAction<Skill[]>) {
  try {
    yield call([apiClient, apiClient.profile_UpdateSkills], action.payload);
    yield put(getProfile());
    yield put(showToastAction({ message: 'Your technical skills have been updated!', severity: 'success' }));
  } catch {
    yield put(showToastAction({ message: 'There was an issue updating your skills', severity: 'warning' }));
  }
}

export function* profileSaga() {
  yield all([
    takeLatest(profileSlice.actions.addJobHistoryAction.type, addJobHistorySaga),
    takeLatest(profileSlice.actions.deleteJobHistoryAction.type, deleteJobHistorySaga),
    takeLatest(profileSlice.actions.getProfile.type, getProfileSaga),
    takeLatest(profileSlice.actions.updateAvatarAction.type, updateAvatarSaga),
    takeLatest(profileSlice.actions.updateJobHistoryAction.type, updateJobHistorySaga),
    takeLatest(profileSlice.actions.updateProfileDetails.type, updateProfileDetailsSaga),
    takeLatest(profileSlice.actions.updateTechSkillsAction.type, updateTechSkillsSaga),
  ]);
}
