import React, { FC, FormEvent, useState } from 'react';
import { Grid, TextField } from '@mui/material';
import { ContainedButton } from '../shared/button';
import { createCompany } from '../../redux/managementSlice';
import { useDispatch } from 'react-redux';
import { ICreateCompanyRequest } from '../../services/generated/ApiClientGenerated';

export const AddCompany: FC = () => {
  const [addCompanyDetails, setAddCompanyDetails] = useState<ICreateCompanyRequest>();
  const dispatch = useDispatch();

  const handleSave = (e: FormEvent) => {
    dispatch(
      createCompany({
        body: addCompanyDetails,
      }),
    );
    e.preventDefault();
  };

  return (
    <form onSubmit={handleSave}>
      <Grid container alignItems={'center'} p={2} spacing={1}>
        <Grid item xs={4}>
          <TextField
            margin="dense"
            required
            name={'companyName'}
            label="Company Name"
            placeholder="Company Name"
            type="text"
            fullWidth
            variant="outlined"
            onChange={(e) =>
              setAddCompanyDetails({
                ...addCompanyDetails,
                name: e.currentTarget.value,
              })
            }
          />
        </Grid>
        <Grid item xs={4} my={'auto'}>
          <TextField
            margin="dense"
            required
            name={'companyEmailDomain'}
            label="Company Email Domain Name"
            placeholder="example.com"
            type="text"
            fullWidth
            variant="outlined"
            onChange={(e) =>
              setAddCompanyDetails({
                ...addCompanyDetails,
                emailDomain: e.currentTarget.value,
              })
            }
          />
        </Grid>
        <Grid container item xs={4} justifyContent={'flex-end'} px={1}>
          <ContainedButton type="submit" sx={{ height: '50px', mt: 0 }} text={'Add'} />
        </Grid>
      </Grid>
    </form>
  );
};
