import React, { FC } from 'react';
import { Box, Chip, Divider, Grid, Tooltip, Typography } from '@mui/material';
import { format } from 'date-fns';
import { DescriptionContainer, MainDetailsHeaderContainer } from '../../shared/grid';
import { IconButton, TrashButton } from '../../shared/button';
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import { addInterestAction, deleteInterestAction, JobRole } from '../../../redux/jobSlice';
import { useDispatch } from 'react-redux';

export interface JobCardType {
  roleEntry: JobRole;
}

export const JobSearchCard: FC<JobCardType> = ({ roleEntry }) => {
  const dispatch = useDispatch();

  return (
    <>
      <Grid container item direction="column" sx={{ marginBottom: 3 }}>
        <MainDetailsHeaderContainer container>
          <Grid item xs={3}>
            <Typography variant="h6">Job Title</Typography>
            <Typography variant="body1">{roleEntry.title}</Typography>
          </Grid>
          <Grid container item xs={1} justifyContent={'center'}>
            <Divider orientation="vertical" flexItem />
          </Grid>
          <Grid item xs={3}>
            <Typography variant="h6">Department</Typography>
            <Typography variant="body1">{roleEntry.department}</Typography>
          </Grid>

          <Grid item>
            {roleEntry?.interested ? (
              <TrashButton
                onClick={() => dispatch(deleteInterestAction(roleEntry.id))}
                text={'No Longer Interested'}
                icon={<DeleteIcon />}
              />
            ) : (
              <Tooltip title="Does this sound like a dream job? Flip this switch to let hiring managers know that you're extra-interested.">
                <Box>
                  <IconButton
                    onClick={() => dispatch(addInterestAction(roleEntry.id))}
                    text={'Show Interest'}
                    icon={<ArrowCircleRightRoundedIcon fontSize={'large'} sx={{ marginLeft: 0.5 }} />}
                  />
                </Box>
              </Tooltip>
            )}
          </Grid>
        </MainDetailsHeaderContainer>

        <DescriptionContainer container mt={1}>
          <Grid item xs={9}>
            <Grid container direction={'column'} spacing={2}>
              <Grid item>
                <Typography variant="h6">Duties and Description</Typography>
                <Typography variant="body1">{roleEntry.description}</Typography>
              </Grid>
              {roleEntry.skills && roleEntry.skills[0] !== null && (
                <Grid item>
                  <Typography variant="h6">Technical Skills</Typography>
                  <Typography variant="body1">
                    {roleEntry.skills.map((s) => (
                      <Chip key={s} label={s} />
                    ))}
                  </Typography>
                </Grid>
              )}
              <Grid item>
                <Typography variant="h6">Nature of Job</Typography>
                <Typography variant="body1">{roleEntry.locationDisposition}</Typography>
              </Grid>
              {roleEntry.startDate && (
                <Grid item>
                  <Typography variant="h6">Start Date</Typography>
                  <Typography variant="body1">{format(new Date(roleEntry.startDate), 'MM dd yyyy')}</Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </DescriptionContainer>
      </Grid>
    </>
  );
};
