import { StyledModalPaperContainer } from '../../shared/grid';
import { Button, Divider, Grid, Tooltip } from '@mui/material';
import React, { FC, useState } from 'react';
import { MainCandidateCardDividerArea } from './mainCandidateCardDividerArea';
import { StyledCandidateCard } from '../../shared/card';
import { CandidatePaperType } from '../../../services/shared/baseTypes';
import Avatar from 'boring-avatars';
import LightbulbIcon from '@mui/icons-material/Lightbulb';

export const tooltipInterestedText = 'This candidate has shown interest in this role';

export const PrimaryCandidateCard: FC<CandidatePaperType> = ({ onComparisonClick, mouseHover, candidate, xs }) => {
  const [isMouseInside, setIsMouseInside] = useState(false);

  return (
    <StyledModalPaperContainer
      width={'100%'}
      item
      xs={xs}
      onMouseEnter={mouseHover ? () => setIsMouseInside(true) : undefined}
      onMouseLeave={mouseHover ? () => setIsMouseInside(false) : undefined}
    >
      <StyledCandidateCard sx={{ height: '100%' }}>
        <Grid container alignItems={'center'} justifyContent={'space-between'}>
          <Grid item mx={2}>
            <Grid container item>
              <Grid item>
                <Avatar size={64} variant="bauhaus" name={candidate.id} />
              </Grid>
              {candidate.isInterested && (
                <Grid item>
                  <Tooltip placement={'right'} title={tooltipInterestedText} arrow>
                    <LightbulbIcon color={'primary'} />
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          </Grid>

          {isMouseInside && (
            <Grid item mx={2}>
              <Tooltip title={'Select up to 3 candidates for side-by-side comparison'}>
                <Button onClick={onComparisonClick}>Compare Candidate</Button>
              </Tooltip>
            </Grid>
          )}
        </Grid>

        {candidate.skills && (
          <>
            <Divider sx={{ my: 2 }} />
            <Grid item mx={2}>
              <MainCandidateCardDividerArea label={'Skills'} skills={candidate.skills} />
            </Grid>
          </>
        )}

        {candidate.xFactors && (
          <>
            <Divider sx={{ my: 2 }} />
            <Grid item mx={2}>
              <MainCandidateCardDividerArea label={'X-Factors'} skills={candidate.xFactors} />
            </Grid>
          </>
        )}
      </StyledCandidateCard>
    </StyledModalPaperContainer>
  );
};
