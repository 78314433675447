import React, { FC } from 'react';
import { StyledSvgIcon } from '../components/shared/image';

export const NewColleagueSvg: FC = () => {
  return (
    <StyledSvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120.249 129.764">
      <defs>
        <clipPath id="clip-path">
          <path id="Clip_4" data-name="Clip 4" d="M0,0H11V33.4H0Z" transform="translate(0.112 0.046)" fill="none" />
        </clipPath>
        <clipPath id="clip-path-2">
          <path id="Clip_7" data-name="Clip 7" d="M0,0H7.9V35.877H0Z" transform="translate(0.01 0.123)" fill="none" />
        </clipPath>
        <clipPath id="clip-path-3">
          <path
            id="Clip_30"
            data-name="Clip 30"
            d="M0,0H8.775V7.342H0Z"
            transform="translate(0.042 0.071)"
            fill="none"
          />
        </clipPath>
      </defs>
      <g id="Group_60" data-name="Group 60" transform="translate(-0.161 -0.221)">
        <g id="Group_9" data-name="Group 9">
          <path
            id="Fill_1"
            data-name="Fill 1"
            d="M78.492,20.085C84.423,9.995,81.038,1.675,72.4.2,60.983-1.751,58.883,11.335,46.395,7.408,25.99.994,17.864,6.8,17.864,25.3S6.386,43.807,1.483,55.21c-4.246,9.874.447,26.253,16.381,34.617,7.676,4.029,22.679,6.818,35.74,4.094C72.465,89.987,88.332,76.95,82.93,55.21c-2.455-9.88-9.5-8.63-9.943-21.276C72.861,30.267,76.344,23.74,78.492,20.085Z"
            transform="translate(0.161 42.221) rotate(-30)"
            fill="#eff1ff"
            opacity="0.504"
          />
          <g id="A_Human_Standing" data-name="A Human/Standing" transform="translate(78.82 44.83)">
            <g id="Head_Front" data-name="Head/Front" transform="translate(-47.2)">
              <g id="Head" transform="translate(19.776 7.392)">
                <path
                  id="Head-2"
                  data-name="Head"
                  d="M0,17.408-1.993,8.224A6.419,6.419,0,0,0-.255,3.583C-.617-1.52-7.8-.4-9.2,2.178s-1.232,9.109.575,9.569c.72.184,2.255-.266,3.817-1.2l-.98,6.857Z"
                  transform="translate(0 0)"
                  fill="#b28b67"
                />
              </g>
              <path
                id="hair"
                d="M-10.531,5.811a7.636,7.636,0,0,1,2.422-.265A4.077,4.077,0,0,1-8.4,3.465a6.3,6.3,0,0,1,.683-1.882A2.572,2.572,0,0,1-7.215.8,1.732,1.732,0,0,1-6.4.369a2.212,2.212,0,0,1,.942,0c.137.028.26.09.394.125s.2-.05.3-.135a1.733,1.733,0,0,1,.9-.344A2.339,2.339,0,0,1-2.839.135a2,2,0,0,1,.851.539,1.409,1.409,0,0,1,.255.386c.025.059.029.093.088.115s.119.02.175.039A2.2,2.2,0,0,1-.2,2.351,3.566,3.566,0,0,1,0,3.832a6.394,6.394,0,0,1-1.11,2.815A3.551,3.551,0,0,1-2.943,7.668,5.157,5.157,0,0,1-1.987,9.43a8.541,8.541,0,0,1,.438,2.141,6.911,6.911,0,0,1-.109,2.111,2.288,2.288,0,0,1-1.034,1.489,4.967,4.967,0,0,1-.8.37,5.285,5.285,0,0,1-.82.26,1.66,1.66,0,0,1-1.558-.329,2.9,2.9,0,0,1-.419-.526c-.039-.066-.062-.11-.015-.175a1.26,1.26,0,0,1,.221-.2,2.048,2.048,0,0,0,.735-1.361,1.447,1.447,0,0,0-.635-1.523,2.376,2.376,0,0,0-2.027.366A3.282,3.282,0,0,0-9.614,9.947c-.912-.454-1.959-.507-2.9-.9a1.73,1.73,0,0,1-.673-.423.859.859,0,0,1-.193-.313.5.5,0,0,1,.134-.393A7.379,7.379,0,0,1-10.531,5.811Z"
                transform="translate(23.395 0.954)"
                fill="#191847"
              />
            </g>
            <g id="Bottom_Skinny_Jeans_1" data-name="Bottom/Skinny Jeans 1" transform="translate(-3.2 44.8)">
              <path
                id="Skin"
                d="M-13.712,33.187c.24-3.029.687-13.532.24-18.418-.134-1.47-.276-2.889-.419-4.215,4.3,7.7,11.127,18.691,11.127,18.691L0,27.98S-3.255,17.244-5.339,12.821C-7.718,7.772-9.948,3.45-10.886,1.927c-1.24-2.011-3.25-1.745-4.667-.749-1.723-2.339-6.031-.79-6.029,1.146.007,5.084,4.643,29.221,4.885,30.745a1.727,1.727,0,0,0,1.711,1.355A1.222,1.222,0,0,0-13.712,33.187Z"
                transform="translate(-37.148 3.169)"
                fill="#b28b67"
              />
              <path
                id="Leg_Back"
                data-name="Leg Back"
                d="M0,26.861-1.221,2.48C-2.106-1.735-9.6.223-9.6,2.483c.006,5.129,3.08,22.847,3.081,24.376Z"
                transform="translate(-46.533 1.933) rotate(-20)"
                fill="#f87100"
              />
              <path
                id="Leg_Front"
                data-name="Leg Front"
                d="M-40.56,7.189c0,6.375,2.792,21.362,2.794,22.766L-31,29.726s-1.773-19.545-1.5-19.677c.417-.2,17.075,2.308,22.071,2.5C-3.222,12.819-.242,8.395,0,0H-13.955s-17.9,2.139-23.529,2.975C-39.894,3.333-40.56,5.432-40.56,7.189Z"
                transform="translate(-18.409 0.06)"
                fill="#ff9800"
              />
              <g id="Left_Shoe" data-name="Left Shoe" transform="translate(-51.831 27.911) rotate(-14)">
                <path
                  id="shoe"
                  d="M-1.074,1.461a.48.48,0,0,1,.616.324L0,3.4A15.445,15.445,0,0,1-.216,5.3H-13.753s-.528-1.746.609-1.885,1.889-.251,1.889-.251L-4.963.353a.24.24,0,0,1,.32.128l.4.982a5.436,5.436,0,0,0,1.71.491Z"
                  transform="translate(14.4 4.312)"
                  fill="#e4e4e4"
                />
              </g>
              <g id="Right_Shoe" data-name="Right Shoe" transform="translate(-64.489 26.663)">
                <path
                  id="shoe-2"
                  data-name="shoe"
                  d="M-1.074,1.461a.48.48,0,0,1,.616.324L0,3.4A15.445,15.445,0,0,1-.216,5.3H-13.753s-.528-1.746.609-1.885,1.889-.251,1.889-.251L-4.963.353a.24.24,0,0,1,.32.128l.4.982a5.436,5.436,0,0,0,1.71.491Z"
                  transform="translate(14.4 4.312)"
                  fill="#e4e4e4"
                />
              </g>
              <rect
                id="Base"
                width="12.72"
                height="1.681"
                rx="0.841"
                transform="translate(-63.049 36.272)"
                fill="#1c134d"
              />
              <path
                id="Seat"
                d="M4,0H27.341a4,4,0,0,1,4,4V9.457a1.6,1.6,0,0,1-1.6,1.6H0a0,0,0,0,1,0,0V4A4,4,0,0,1,4,0Z"
                transform="translate(-46.631 4.557)"
                fill="#1a164a"
              />
              <path
                id="Wheel_Stuff"
                data-name="Wheel Stuff"
                d="M0,0H0A3.624,3.624,0,0,0-3.543,2.863L-9.318,29.768h2.928"
                transform="translate(-42.212 5.783)"
                fill="#1a164a"
              />
              <ellipse
                id="Front_Wheel"
                data-name="Front Wheel"
                cx="4.32"
                cy="4.324"
                rx="4.32"
                ry="4.324"
                transform="translate(-54.649 31.708)"
                fill="#191847"
              />
              <g id="Wheel" transform="translate(-0.169 3.363)">
                <path
                  id="Wheel-2"
                  data-name="Wheel"
                  d="M-18.24,36.512a18.112,18.112,0,0,0,7.1-1.435,18.179,18.179,0,0,0,5.8-3.912,18.2,18.2,0,0,0,3.909-5.8A18.154,18.154,0,0,0,0,18.257,18.156,18.156,0,0,0-1.433,11.15a18.2,18.2,0,0,0-3.909-5.8,18.179,18.179,0,0,0-5.8-3.913A18.112,18.112,0,0,0-18.24,0a18.111,18.111,0,0,0-7.1,1.435,18.177,18.177,0,0,0-5.8,3.913,18.2,18.2,0,0,0-3.909,5.8,18.157,18.157,0,0,0-1.433,7.106,18.155,18.155,0,0,0,1.433,7.106,18.2,18.2,0,0,0,3.909,5.8,18.178,18.178,0,0,0,5.8,3.912A18.111,18.111,0,0,0-18.24,36.512Zm0-33.149A14.777,14.777,0,0,1-7.718,7.725,14.8,14.8,0,0,1-3.36,18.257,14.8,14.8,0,0,1-7.718,28.787,14.777,14.777,0,0,1-18.24,33.149a14.776,14.776,0,0,1-10.522-4.362A14.8,14.8,0,0,1-33.12,18.257,14.8,14.8,0,0,1-28.762,7.725,14.776,14.776,0,0,1-18.24,3.363Z"
                  fill="#191847"
                />
                <rect
                  id="Wheel_Stuff-2"
                  data-name="Wheel Stuff"
                  width="1.92"
                  height="31.708"
                  transform="translate(-19.2 2.402)"
                  fill="#191847"
                />
                <path
                  id="Wheel_Stuff-3"
                  data-name="Wheel Stuff"
                  d="M0,.8A.8.8,0,0,0-.8,0h-.321a.8.8,0,0,0-.8.8l-.013,30.094a.8.8,0,0,0,.8.8h.321a.8.8,0,0,0,.8-.8Z"
                  transform="translate(-28.762 6.366) rotate(-45)"
                  fill="#191847"
                />
                <rect
                  id="Wheel_Stuff-4"
                  data-name="Wheel Stuff"
                  width="1.922"
                  height="31.68"
                  rx="0.8"
                  transform="translate(-34.08 19.217) rotate(-90)"
                  fill="#191847"
                />
                <path
                  id="Wheel_Stuff-5"
                  data-name="Wheel Stuff"
                  d="M-.014.8a.8.8,0,0,0-.8-.8h-.321a.8.8,0,0,0-.8.8l.013,30.094a.8.8,0,0,0,.8.8H-.8a.8.8,0,0,0,.8-.8Z"
                  transform="translate(-30.13 28.778) rotate(-135)"
                  fill="#191847"
                />
              </g>
            </g>
            <g id="Body_Long_Sleeve_1" data-name="Body/Long Sleeve 1" transform="translate(-61.6 19.6)">
              <path
                id="Skin-2"
                data-name="Skin"
                d="M-7.6,28.969,0,25.831l-1.7-4.176-7.675,5.612a20.712,20.712,0,0,0-5.218,2.088c-.33.3-.729.905.64.8s2.812-.154,3,.311-.639,1.02-.257,1.647a.182.182,0,0,0,.167.089Q-10.366,32.2-7.6,28.969ZM-2.5,5.645l-9.121-2.726A20.679,20.679,0,0,0-16.438.027c-.439-.082-1.165-.034-.257.991s1.816,2.144,1.557,2.573-1.2.106-1.477.787q-.273.681,4.692.975l7.092,4.155Z"
                transform="translate(18.034 -5.085)"
                fill="#b28b67"
              />
              <path
                id="Coat_Back"
                data-name="Coat Back"
                d="M-3.245,0C-9.291,6.41-16.669,10.591-26.733,14.3l2.241,4.456c10.387-.964,15.94-1.738,20.287-8.163C-3.114,8.977-1.167,3.427,0,.544Z"
                transform="matrix(0.966, -0.259, 0.259, 0.966, 31.394, 0.622)"
                fill="#2026a2"
              />
              <path
                id="Shirt"
                d="M0,27.311H-18.528S-12.233,8.2-12.1,1.071c0-.242.454-.669.652-.613A8.077,8.077,0,0,0-6.779,0C-2.386,7.033-.867,15.693,0,27.311Z"
                transform="translate(39.944)"
                fill="#f2f2f2"
              />
              <path
                id="Coat_Front"
                data-name="Coat Front"
                d="M-.342,35.362C1.227,24.864-2.9,10.307-6.72,2.965a1.3,1.3,0,0,0-1.189-.809h-2.871l0,.011c-.967.008-2.044.046-3.218.085C-19.276,2.425-26.521,2.6-34.648,0l-.981,4.881c7.511,4.809,15.116,8.535,21.78,8.006-2.894,12.358-2.76,22.476,3.628,22.476Z"
                transform="translate(42.367 -2.156)"
                fill="#1f28cf"
              />
            </g>
          </g>
          <g id="Group_42" data-name="Group 42" transform="translate(68.962 25.687)">
            <path
              id="Fill_1-2"
              data-name="Fill 1"
              d="M7.081,16.571l1.96-9.5,1.242.024.834,9.473h7.649c.116-4.2.134-12.166-.015-16.086L.612,0C.426,3.134.331,11.776,0,16.571Z"
              transform="translate(5.143 42.286)"
              fill="#393762"
            />
            <g id="Group_5" data-name="Group 5" transform="translate(1.429 58.571)">
              <path
                id="Clip_4-2"
                data-name="Clip 4"
                d="M0,0H11V33.4H0Z"
                transform="translate(0.112 0.046)"
                fill="none"
              />
              <g id="Group_5-2" data-name="Group 5" clipPath="url(#clip-path)">
                <path
                  id="Fill_3"
                  data-name="Fill 3"
                  d="M3.6,0c-.71,13.531-.945,31.428-.945,31.428L0,33.449H4.147L11.669,0,7.149.046Z"
                  transform="translate(0.112 0)"
                  fill="#393762"
                />
              </g>
            </g>
            <g id="Group_8" data-name="Group 8" transform="translate(16 56)">
              <path
                id="Clip_7-2"
                data-name="Clip 7"
                d="M0,0H7.9V35.877H0Z"
                transform="translate(0.01 0.123)"
                fill="none"
              />
              <g id="Group_8-2" data-name="Group 8" clipPath="url(#clip-path-2)">
                <path
                  id="Fill_6"
                  data-name="Fill 6"
                  d="M8.1,1.6,2.348,0,0,3.138,3.746,35.877H8.1L5.623,34.036a295.149,295.149,0,0,0,2.561-30.9Z"
                  transform="translate(-0.276 0.123)"
                  fill="#393762"
                />
              </g>
            </g>
            <path
              id="Fill_9"
              data-name="Fill 9"
              d="M1.266,4.571a.163.163,0,0,1-.157-.124L.006.211a.168.168,0,0,1,.115-.2.162.162,0,0,1,.2.119l1.1,4.237a.168.168,0,0,1-.115.2.159.159,0,0,1-.042.006"
              transform="translate(13.714 44.286)"
              fill="#49478a"
            />
            <path
              id="Fill_11"
              data-name="Fill 11"
              d="M3.938,0C2.7,1.448.357,7.787.357,8.884s-1.2,11.48.787,11.677,3.427-2.527,3.427-2.527Z"
              transform="translate(0 20.286)"
              fill="#ece0d3"
            />
            <path
              id="Fill_13"
              data-name="Fill 13"
              d="M22.064,8.226,20.813,30.79,3.483,29.429l-2.326,1.48C.2,25.383-.483,18.827.429,13.109c.29-1.816,1.18-6.149,1.543-7.967.2-.981,8.169-5.226,9.62-5.142a17.826,17.826,0,0,1,4.363.4c2.534.578,6.978,3.034,7.916,4.173s2.4,16.253,2.083,17.7C25.175,25.792,23.2,32,23.2,32l-2.39-1.21"
              transform="translate(2.286 13.143)"
              fill="#dc5955"
            />
            <path
              id="Fill_15"
              data-name="Fill 15"
              d="M.155,6.857a.155.155,0,0,1-.073-.292A24.067,24.067,0,0,0,7.723.059.157.157,0,0,1,7.94.031a.156.156,0,0,1,.028.218,24.4,24.4,0,0,1-7.74,6.59.156.156,0,0,1-.073.018"
              transform="translate(8.857 30.286)"
              fill="#c74749"
            />
            <path
              id="Fill_17"
              data-name="Fill 17"
              d="M.16,8a.164.164,0,0,1-.1-.033.154.154,0,0,1-.027-.219A24.506,24.506,0,0,0,4.545.108a.159.159,0,0,1,.2-.1.155.155,0,0,1,.1.2A24.772,24.772,0,0,1,.286,7.94.161.161,0,0,1,.16,8"
              transform="translate(14.286 31.143)"
              fill="#c74749"
            />
            <path
              id="Fill_19"
              data-name="Fill 19"
              d="M.161,24H.148A.159.159,0,0,1,0,23.829C.215,21,.472,18.294.721,15.678c.187-1.961.38-3.988.557-6.088C1.492,7.058,1.534,2.238.335.24A.157.157,0,0,1,.391.022.16.16,0,0,1,.611.078c1.24,2.067,1.2,6.969.987,9.539-.177,2.1-.37,4.129-.557,6.091C.791,18.323.534,21.027.32,23.853A.16.16,0,0,1,.161,24"
              transform="translate(6.286 17.429)"
              fill="#c74749"
            />
            <path
              id="Fill_21"
              data-name="Fill 21"
              d="M0,0V3.709s2.829,3.176,5.429.074L5.2,0Z"
              transform="translate(12.857 9.429)"
              fill="#e48868"
            />
            <path
              id="Fill_23"
              data-name="Fill 23"
              d="M8.533,5.936a.916.916,0,0,0-.284-.086C8.646,3.185,8.476-.126,4.337,0,1.868.081,1.134,2.47.974,4.967a.691.691,0,0,0-.56-.02c-.853.27-.261,2.537.623,2.885.327,2.283,1.471,3.745,3.109,3.874,1.514.12,2.7-1.121,3.409-2.992.922-.216,1.794-2.393.979-2.778"
              transform="translate(10.857 1.143)"
              fill="#e48868"
            />
            <path
              id="Fill_25"
              data-name="Fill 25"
              d="M2.516,1.714c-.071,0-.143,0-.215-.009A3.077,3.077,0,0,1,.034.292.2.2,0,0,1,.057.04.139.139,0,0,1,.272.066,2.788,2.788,0,0,0,2.323,1.348,2.57,2.57,0,0,0,4.59.19.138.138,0,0,1,4.807.179a.2.2,0,0,1,.01.253,2.95,2.95,0,0,1-2.3,1.283"
              transform="translate(12.857 11.429)"
              fill="#ba745d"
            />
            <path
              id="Fill_27"
              data-name="Fill 27"
              d="M0,1.49S2.379,6.136,3.2,4.018A4.252,4.252,0,0,0,2.33,0Z"
              transform="translate(3.429 42.286)"
              fill="#e48868"
            />
            <g id="Group_31" data-name="Group 31" transform="translate(11.143)">
              <path
                id="Clip_30-2"
                data-name="Clip 30"
                d="M0,0H8.775V7.342H0Z"
                transform="translate(0.042 0.071)"
                fill="none"
              />
              <g id="Group_31-2" data-name="Group 31" clipPath="url(#clip-path-3)">
                <path
                  id="Fill_29"
                  data-name="Fill 29"
                  d="M.593,5.966l.539.9H1.7V5.32a3.188,3.188,0,0,0,.8-1.631l4.323.322a2.782,2.782,0,0,0,.2,1.547V7.342h.533l.488-.481S9.1,2.9,8.673,1.909a.994.994,0,0,0-1.3-.635A3.441,3.441,0,0,0,3.959.124C1.969.719.371,1.988.067,2.861S.593,5.966.593,5.966"
                  transform="translate(0.042 0.07)"
                  fill="#464473"
                />
              </g>
            </g>
            <path
              id="Fill_32"
              data-name="Fill 32"
              d="M1.536,0S-.855,2.614.33,4.229,4,1.222,4,1.222A20.1,20.1,0,0,0,1.536,0"
              transform="translate(21.714 43.429)"
              fill="#e48868"
            />
            <path
              id="Fill_34"
              data-name="Fill 34"
              d="M.382,25.714a.142.142,0,0,1-.133-.11.162.162,0,0,1,.091-.2c.011-.005.328-.137.414-.805a2.43,2.43,0,0,0-.267-.9c-.383-.93-.961-2.335.2-3.582.936-1.005.671-2.264.437-3.375A3.015,3.015,0,0,1,1.04,15.1c.24-.565.272-5.791.294-9.607.018-3.089.033-5,.106-5.371A.141.141,0,0,1,1.606,0a.159.159,0,0,1,.105.187c-.07.357-.084,2.761-.1,5.306-.031,5.163-.074,9.159-.322,9.741a2.878,2.878,0,0,0,.105,1.433c.239,1.134.536,2.546-.516,3.676-1.017,1.092-.531,2.274-.14,3.222a2.434,2.434,0,0,1,.291,1.08C.916,25.523.443,25.7.423,25.707a.128.128,0,0,1-.041.007"
              transform="translate(22.857 17.714)"
              fill="#c74749"
            />
            <path
              id="Fill_36"
              data-name="Fill 36"
              d="M.635,4.571a.148.148,0,0,1-.1-.035.162.162,0,0,1-.023-.222A2.967,2.967,0,0,0,1.107,2.2,2.919,2.919,0,0,0,.059.283.162.162,0,0,1,.031.062.147.147,0,0,1,.244.033,3.2,3.2,0,0,1,1.408,2.162,3.251,3.251,0,0,1,.753,4.513a.148.148,0,0,1-.118.058"
              transform="translate(7.143 43.429)"
              fill="#49478a"
            />
            <path
              id="Fill_38"
              data-name="Fill 38"
              d="M.142,1.714A.151.151,0,0,1,0,1.581a.174.174,0,0,1,.108-.2A3.71,3.71,0,0,0,2.039.053a.127.127,0,0,1,.2-.008.2.2,0,0,1,.007.242A4,4,0,0,1,.174,1.71a.117.117,0,0,1-.031,0"
              transform="translate(20.857 48.857)"
              fill="#49478a"
            />
            <path
              id="Fill_40"
              data-name="Fill 40"
              d="M0,1.6C.79,3.063.577,9.22.577,9.22v2.494S3.889,5.372,2.987,0Z"
              transform="translate(7.429 14.286)"
              fill="#ece0d3"
            />
          </g>
        </g>
      </g>
    </StyledSvgIcon>
  );
};
