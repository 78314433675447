import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGetUserSettingsResponse } from '../services/generated/ApiClientGenerated';

export type SettingsType = {
  userSettings: SerializableIGetUserSettingsResponse;
};

export type SerializableIGetUserSettingsResponse = {
  birthDay?: string;
} & Omit<IGetUserSettingsResponse, 'birthDay'>;

export type SerializableIUpdateUserSettingsRequest = {
  birthDay?: string;
} & Omit<IGetUserSettingsResponse, 'birthDay'>;

const initialState = {} as SettingsType;

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getSettingsAction: (state, action: PayloadAction) => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateSettingsAction: (state, action: PayloadAction<SerializableIUpdateUserSettingsRequest>) => {},
    setSettingsAction: (state, action: PayloadAction<SerializableIGetUserSettingsResponse>) => {
      state.userSettings = action.payload;
    },
  },
});

export const { getSettingsAction, updateSettingsAction, setSettingsAction } = settingsSlice.actions;
