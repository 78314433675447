import React, { FC, useEffect, useState } from 'react';
import { Card, Divider, Grid, IconButton, styled, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { ContainedButton } from '../../../shared/button';
import CloseIcon from '@mui/icons-material/Close';
import { sendInterviewEmailAction } from '../../../../redux/jobSlice';
import { IListJobMatchesJobMatchItem } from '../../../../services/generated/ApiClientGenerated';

export type CompareCandidatesType = {
  candidates: IListJobMatchesJobMatchItem[];
  onRemoveCandidate: (value: IListJobMatchesJobMatchItem[]) => void;
  roleId?: string;
};

export const StyledComparisonCard = styled(Card)(({}) => ({
  borderRadius: '6px',
  height: '100%',
}));

export const CompareCandidateArea = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: '13px',
  border: `1px solid ${theme.palette.primary.main}`,
  height: '100%',
  marginLeft: 0,
  marginRight: 0,
  width: '100%',
}));

export const CandidateComparison: FC<CompareCandidatesType> = ({ roleId, candidates, onRemoveCandidate }) => {
  const dispatch = useDispatch();
  const [interviewArray, setInterviewArray] = useState<IListJobMatchesJobMatchItem[]>();

  useEffect(() => {
    setInterviewArray(candidates);
  }, [candidates]);

  const handleRemoveCandidate = (indexToRemove: number) => {
    const newArr = interviewArray?.filter((candidate, candidateIndex) => candidateIndex !== indexToRemove);
    setInterviewArray(newArr);
    onRemoveCandidate(newArr ? newArr : []);
  };

  const handleSendInterview = () => {
    interviewArray?.forEach((candidateToInterview) => {
      if (roleId && candidateToInterview.id) {
        dispatch(sendInterviewEmailAction({ roleId, matchId: candidateToInterview.id }));
      }
    });
  };

  return (
    <>
      <Grid container justifyContent={'center'} mb={2}>
        <Grid item>
          <Typography variant={'h4'}>Compare Candidates</Typography>
        </Grid>
      </Grid>

      <CompareCandidateArea container mb={2}>
        {interviewArray?.map((candidate, index) => (
          <Grid width={'100%'} item key={`${candidate?.id} ${index}`} xs={4} px={1}>
            <StyledComparisonCard>
              <Grid container direction={'column'}>
                <Grid container item justifyContent={'space-between'}>
                  <Grid item p={1}>
                    <Typography variant={'h4'}>Candidate {index + 1}</Typography>
                  </Grid>
                  <Grid item>
                    <IconButton aria-label="remove candidate" onClick={() => handleRemoveCandidate(index)}>
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Divider />
                <Grid container direction={'column'} item>
                  <Grid item p={1}>
                    {candidate.skills && <Typography variant={'h5'}>Skills</Typography>}
                    {candidate.skills?.map((skill) => (
                      <Grid item key={skill}>
                        {skill}
                      </Grid>
                    ))}
                  </Grid>
                  <Divider />
                  <Grid item p={1}>
                    {candidate.xFactors && <Typography variant={'h5'}>X-Factors</Typography>}
                    {candidate.xFactors?.map((skill) => (
                      <Grid item key={skill}>
                        {skill}
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </StyledComparisonCard>
          </Grid>
        ))}
        <Grid container justifyContent={'center'} pb={2}>
          <ContainedButton text="Send Interview Offer" onClick={handleSendInterview} />
        </Grid>
      </CompareCandidateArea>
    </>
  );
};
