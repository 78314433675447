import React, { FC } from 'react';
import { Chip, Divider, Grid, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { DescriptionContainer, MainDetailsHeaderContainer } from '../../shared/grid';
import { IconButton, TrashButton } from '../../shared/button';
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  changeJobRoleStatusAction,
  deleteJobRoleAction,
  getAndShowJobMatchesAction,
  JobRole,
  startJobMatchingAction,
} from '../../../redux/jobSlice';
import { useDispatch } from 'react-redux';
import { IChangeJobRoleStatusRequest } from '../../../services/generated/ApiClientGenerated';
import { SelectDropdown } from '../../shared/select';

export interface JobCardType {
  roleEntry: JobRole;
}

export const HandleJobMatchingStatus: FC<JobCardType> = ({ roleEntry }) => {
  const dispatch = useDispatch();

  if (roleEntry.isJobMatchingCompleted) {
    return (
      <IconButton
        onClick={() => {
          dispatch(
            getAndShowJobMatchesAction({
              roleId: roleEntry.id ? roleEntry.id : '',
              page: 0,
              pageSize: 50,
            }),
          );
        }}
        text={'Compare Candidates'}
        icon={<ArrowCircleRightRoundedIcon fontSize={'large'} sx={{ marginLeft: 0.5 }} />}
      />
    );
  } else if (roleEntry.isJobMatchingStarted && !roleEntry.isJobMatchingCompleted) {
    return (
      <IconButton
        disabled
        text={'Finding Candidates'}
        icon={<HourglassBottomIcon fontSize={'large'} sx={{ marginLeft: 0.5 }} />}
      />
    );
  } else if (!roleEntry.isJobMatchingStarted && !roleEntry.isJobMatchingCompleted) {
    return (
      <IconButton
        onClick={() => dispatch(startJobMatchingAction(roleEntry.id))}
        text={'Find Candidates'}
        icon={<ArrowCircleRightRoundedIcon fontSize={'large'} sx={{ marginLeft: 0.5 }} />}
      />
    );
  } else return <></>;
};

export const JobManagementCard: FC<JobCardType> = ({ roleEntry }) => {
  const dispatch = useDispatch();

  const handleSelectChange = (e: { target: { name: any; value: any } }) => {
    const { value } = e.target;

    if (roleEntry.id) {
      const newBody: IChangeJobRoleStatusRequest = { jobRoleId: roleEntry.id, status: value };
      dispatch(changeJobRoleStatusAction({ roleId: roleEntry.id, body: newBody }));
    }
  };

  const handleDeleteJob = () => {
    roleEntry.id && dispatch(deleteJobRoleAction(roleEntry.id));
  };

  return (
    <>
      <Grid container item direction="column" sx={{ marginBottom: 3 }}>
        <MainDetailsHeaderContainer container>
          <Grid item xs={2}>
            <Typography variant="h6">Job Title</Typography>
            <Typography variant="body1">{roleEntry.title}</Typography>
          </Grid>
          <Grid container item xs={1} justifyContent={'center'}>
            <Divider orientation="vertical" flexItem />
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h6">Department</Typography>
            <Typography variant="body1">{roleEntry.department}</Typography>
          </Grid>
          <Grid container item xs={1} justifyContent={'center'}>
            <Divider orientation="vertical" flexItem />
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h6">Location</Typography>
            <Typography variant="body1">{roleEntry.locationDisposition}</Typography>
          </Grid>

          <Grid container item xs={3} justifyContent="flex-end">
            <HandleJobMatchingStatus roleEntry={roleEntry} />
          </Grid>
        </MainDetailsHeaderContainer>

        <DescriptionContainer container mt={1}>
          <Grid item xs={9}>
            <Grid container direction={'column'} spacing={2}>
              <Grid item>
                <Typography variant="h6">Duties and Description</Typography>
                <Typography variant="body1">{roleEntry.description}</Typography>
              </Grid>
              {roleEntry.skills && roleEntry.skills[0] !== null && (
                <Grid item>
                  <Typography variant="h6">Technical Skills</Typography>
                  <Stack direction="row" spacing={1}>
                    {roleEntry.skills.map((skill, index) => (
                      <Chip label={skill} key={index} />
                    ))}
                  </Stack>
                </Grid>
              )}
              {roleEntry.xFactors && roleEntry.xFactors[0] !== null && (
                <Grid item>
                  <Typography variant="h6">X Factors</Typography>
                  <Stack direction="row" spacing={1}>
                    {roleEntry.xFactors.map((xfactor, index) => (
                      <Chip label={xfactor} key={index} />
                    ))}
                  </Stack>
                </Grid>
              )}
              <Grid item>
                <Typography variant="h6">Nature of Job</Typography>
                <Typography variant="body1">{roleEntry.locationDisposition}</Typography>
              </Grid>
              {roleEntry.startDate && (
                <Grid item>
                  <Typography variant="h6">Start Date</Typography>
                  <Typography variant="body1">{format(new Date(roleEntry.startDate), 'MM dd yyyy')}</Typography>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid container item xs={3} direction={'column'} justifyContent={'space-between'}>
            <Grid item>
              <SelectDropdown
                width={'100%'}
                defaultValue={roleEntry.status}
                onChange={handleSelectChange}
                options={['Draft', 'Open', 'Closed']}
                label={'Status'}
              />
            </Grid>
            <Grid container justifyContent={'flex-end'} item>
              <TrashButton onClick={handleDeleteJob} text={'Delete job'} icon={<DeleteIcon />} />
            </Grid>
          </Grid>
        </DescriptionContainer>
      </Grid>
    </>
  );
};
