import React, { FC, HTMLAttributes } from 'react';
import { Box, Grid, styled } from '@mui/material';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const TabBody = styled(Grid)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(2),
  minHeight: '400px',
}));

export const TabPanel: FC<TabPanelProps & HTMLAttributes<HTMLDivElement>> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} aria-labelledby={`tab-${index}`} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
};
