import { all, call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  getAvailableCategoriesAction,
  getAvailableSkillsAction,
  setCategoriesAction,
  setSkillTypesAction,
  skillsSlice,
} from '../redux/skillsSlice';
import { apiCall, apiClient } from '../services/apiClient';
import {
  AddSkillRequest,
  IGetSkillCategoriesResponse,
  IGetSkillsResponse,
} from '../services/generated/ApiClientGenerated';
import { showToastAction } from '../redux/globalSlice';

function* addSelectedSkillsSaga(action: PayloadAction<{ [x: string]: unknown }>) {
  try {
    console.log('in addSelectedSkillsSaga', action.payload);
    yield put(getAvailableCategoriesAction({ prefix: undefined }));
  } catch (err) {
    console.log(err);
  }
}

function* addSkillsSaga(action: PayloadAction<AddSkillRequest>) {
  try {
    console.log(action.payload);
    yield apiCall(apiClient.skills_AddSkill, action.payload);
    yield put(getAvailableCategoriesAction({ prefix: undefined }));
    yield put(getAvailableSkillsAction({ prefix: undefined, type: undefined, includeArchived: false }));
    yield put(showToastAction({ message: 'Your skills database has been updated!', severity: 'success' }));
  } catch {
    yield put(showToastAction({ message: 'There was an issue updating your skills database', severity: 'warning' }));
  }
}

function* deleteSelectedSkillSaga(action: PayloadAction<string>) {
  try {
    yield call([apiClient, apiClient.skills_DeleteSkill], action.payload);
    yield put(getAvailableSkillsAction({ prefix: undefined, type: undefined, includeArchived: false }));
  } catch {
    yield put(showToastAction({ message: 'Could not delete skill', severity: 'warning' }));
  }
}

function* getAvailableCategoriesSaga(action: PayloadAction<{ prefix: string | undefined }>) {
  try {
    const response: IGetSkillCategoriesResponse = yield call(
      [apiClient, apiClient.skills_AutocompleteSkillCategories],
      action.payload.prefix,
    );
    yield put(setCategoriesAction({ ...response }));
  } catch {
    yield put(showToastAction({ message: 'Could not get categories', severity: 'warning' }));
  }
}

function* getAvailableSkillsSaga(
  action: PayloadAction<{ type: string | undefined; prefix: string | undefined; includeArchived: boolean }>,
) {
  try {
    const response: IGetSkillsResponse = yield call(
      [apiClient, apiClient.skills_GetSkills],
      action.payload.type,
      action.payload.prefix,
      action.payload.includeArchived,
    );
    yield put(setSkillTypesAction(response.availableSkills?.map((skill) => ({ ...skill })) || []));
  } catch {
    yield put(showToastAction({ message: 'Could not get skills', severity: 'warning' }));
  }
}

export function* skillsSaga() {
  yield all([
    takeLatest(skillsSlice.actions.addSelectedSkillsAction.type, addSelectedSkillsSaga),
    takeLatest(skillsSlice.actions.addSkillsAction.type, addSkillsSaga),
    takeLatest(skillsSlice.actions.deleteSelectedSkillAction.type, deleteSelectedSkillSaga),
    takeLatest(skillsSlice.actions.getAvailableCategoriesAction.type, getAvailableCategoriesSaga),
    takeLatest(skillsSlice.actions.getAvailableSkillsAction.type, getAvailableSkillsSaga),
  ]);
}
