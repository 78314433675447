import { profileSaga } from './sagas/profileSaga';
import { globalSaga } from './sagas/globalSaga';
import { spawn } from 'redux-saga/effects';
import { xFactorsSaga } from './sagas/xFactorsSaga';
import { jobSaga } from './sagas/jobSaga';
import { skillsSaga } from './sagas/skillsSaga';
import { settingsSaga } from './sagas/settingsSaga';
import { userSaga } from './sagas/userSaga';
import { managementSaga } from './sagas/managementSaga';

export function* rootSagas() {
  yield spawn(globalSaga);
  yield spawn(jobSaga);
  yield spawn(profileSaga);
  yield spawn(settingsSaga);
  yield spawn(skillsSaga);
  yield spawn(xFactorsSaga);
  yield spawn(userSaga);
  yield spawn(managementSaga);
}
