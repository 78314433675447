import { IXFactorQuestion, QuestionType } from '../../../services/generated/ApiClientGenerated';
import React from 'react';
import { Box } from '@mui/material';
import { LikertQuestion } from './likertQuestion';
import { FalseOppositeQuestion } from './falseOppositeQuestion';
import { RankingQuestion } from './rankingQuestion';

export type XFactorQuestionProps<T extends string | string[]> = {
  question: IXFactorQuestion;
  value: T;
  onChange: (value: T) => any;
};

export function XFactorQuestion<T extends string | string[]>(
  props: XFactorQuestionProps<T>,
): React.ReactElement | null {
  switch (props.question.type) {
    case QuestionType.Likert:
      return (
        <Box sx={{ paddingTop: 6 }}>
          <LikertQuestion {...(props as any)} />
        </Box>
      );
    case 'FalseOpposite':
      return (
        <Box sx={{ paddingTop: 6 }}>
          <FalseOppositeQuestion {...(props as any)} />
        </Box>
      );
    case 'Ranking':
      return (
        <Box sx={{ paddingTop: 6 }}>
          <RankingQuestion {...(props as any)} />
        </Box>
      );
    default:
      return null;
  }
}
