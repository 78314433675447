import React, { FC, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getJobRoleListAction, ListJobsRequest } from '../../../redux/jobSlice';
import { JobInterestHeader } from '../jobInterestHeader';
import { RootState } from '../../../store';
import { Loading } from '../../shared/loading';
import { JobManagementCard } from './JobManagementCard';

export interface JobsType {
  archived: boolean;
}

export const JobManagementTab: FC<JobsType> = ({ archived }) => {
  const userInfo = useSelector((r: RootState) => r.user);
  const jobsState = useSelector((r: RootState) => r.job);
  const [listJobsRequest, setListJobsRequest] = useState<ListJobsRequest>({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (userInfo.companyId) {
      dispatch(getJobRoleListAction({ ...listJobsRequest, companyId: userInfo.companyId, isArchived: archived }));
    }
  }, [userInfo, listJobsRequest]);

  return (
    <Grid container direction={'column'}>
      <JobInterestHeader value={listJobsRequest} onChange={setListJobsRequest} />

      <Loading
        loading={jobsState.loading}
        noDataMessage="No jobs found. Try updating your search or come back later."
        value={jobsState.jobRolesList?.results}
      >
        <Grid item>
          {jobsState.jobRolesList?.results &&
            jobsState.jobRolesList.results.map((role, index) => (
              <Grid item key={index} sx={{ marginBottom: 1.5 }}>
                <JobManagementCard roleEntry={role} />
              </Grid>
            ))}
        </Grid>
      </Loading>
    </Grid>
  );
};
