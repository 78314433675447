import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { GetUserSettingsResponse, UpdateUserSettingsRequest } from '../services/generated/ApiClientGenerated';
import { apiClient } from '../services/apiClient';
import { getSettingsAction, settingsSlice } from '../redux/settingsSlice';
import { RootState } from '../store';
import { PayloadAction } from '@reduxjs/toolkit';
import { showToastAction } from '../redux/globalSlice';

function* getSettingsSaga() {
  const userId: string = yield select((r: RootState) => r.profile.profileDetails.userId);
  try {
    const response: GetUserSettingsResponse = yield call([apiClient, apiClient.settings_GetSettings], userId);
    yield put(
      settingsSlice.actions.setSettingsAction({
        ...response,
        birthDay: response.birthDay?.toISOString(),
      }),
    );
  } catch {
    yield put(
      showToastAction({
        message: 'There was an issue getting your settings',
        severity: 'warning',
      }),
    );
  }
}

function* updateSettingsSaga(action: PayloadAction<UpdateUserSettingsRequest>) {
  try {
    yield call([apiClient, apiClient.settings_UpdateSettings], action.payload);
    yield put(getSettingsAction());
    yield put(showToastAction({ message: 'Your settings have been updated!', severity: 'success' }));
  } catch {
    yield put(showToastAction({ message: 'There was an issue updating your settings', severity: 'warning' }));
  }
}

export function* settingsSaga() {
  yield all([
    takeLatest(settingsSlice.actions.getSettingsAction.type, getSettingsSaga),
    takeLatest(settingsSlice.actions.updateSettingsAction.type, updateSettingsSaga),
  ]);
}
