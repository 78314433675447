import React, { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import { StyledHeader } from '../shared/typography';
import { StyledLink } from '../shared/link';
import { ImageContainer } from '../shared/image';
import { StyledCard, StyledCardContent, StyledCardActionArea } from '../shared/card';
import { EditProfileSvg } from '../../assets/editProfile';

export const ProfileCard: FC = () => {
  return (
    <Grid item xs={12} sm={6} md={4}>
      <StyledLink to={'/profile'}>
        <StyledCard>
          <StyledCardActionArea>
            <StyledCardContent>
              <StyledHeader>Edit My Profile</StyledHeader>
              <ImageContainer container item alignContent={'center'} justifyContent={'center'}>
                <EditProfileSvg />
              </ImageContainer>
              <Typography variant={'body2'}>Add to my resume</Typography>
              <Typography variant={'body1'}>
                Make sure hiring managers know what experiences have shaped the worker you are today!
              </Typography>
            </StyledCardContent>
          </StyledCardActionArea>
        </StyledCard>
      </StyledLink>
    </Grid>
  );
};
