import { Grid, styled } from '@mui/material';

export const DescriptionContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(0.8, 2),
}));

export const MainDetailsHeaderContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(2),
  justifyContent: 'space-between',
}));

export const ProfileDetailsContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
}));

export const StyledModalPaperContainer = styled(Grid)(({}) => ({
  '& .MuiPaper-root': {
    borderRadius: '6px',
  },
}));

export const HeaderGridItem = styled(Grid)(({ theme }) => ({
  '& .MuiTypography-root': {
    color: theme.palette.text.secondary,
  },
}));
