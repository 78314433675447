import React, { FC } from 'react';
import { Divider, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { StyledModalPaperContainer } from '../../../shared/grid';
import { StyledPaper } from '../../../shared/paper';
import { DepartmentAutocomplete, TitleAutocomplete } from '../../../shared/select';
import { RadioGroup } from '../../../shared/radioGroup';
import {
  ExperienceLevel,
  IAddJobRoleRequest,
  LocationDisposition,
} from '../../../../services/generated/ApiClientGenerated';

export type RoleDescriptionType = {
  value: IAddJobRoleRequest;
  onChange: (value: IAddJobRoleRequest) => void;
};

export const RoleDescription: FC<RoleDescriptionType> = ({ value, onChange }) => {
  function handleChange<K extends keyof IAddJobRoleRequest>(key: K, newValue: IAddJobRoleRequest[K]) {
    onChange({
      ...value,
      [key]: newValue,
    });
  }

  return (
    <StyledModalPaperContainer item width={'100%'}>
      <StyledPaper sx={{ height: '100%' }}>
        <Grid container px={2}>
          <Typography variant={'h5'}>Role Description</Typography>
        </Grid>
        <Divider />
        <Grid container p={2} spacing={1}>
          <Grid item xs={6}>
            <DepartmentAutocomplete
              freeSolo
              required
              label={'Department'}
              value={value.department || ''}
              onChange={(value) => handleChange('department', value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TitleAutocomplete
              freeSolo
              required
              label={'Title'}
              value={value.title || ''}
              onChange={(value) => handleChange('title', value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              required
              fullWidth
              label="Duties and Description"
              variant="outlined"
              rows={4}
              name={'description'}
              onChange={(e) => handleChange('description', e.currentTarget.value)}
            />
          </Grid>
        </Grid>
        <Grid container px={3} spacing={1}>
          <Grid item xs={12}>
            <RadioGroup
              row
              formLabel={'Location'}
              options={[
                { label: 'Remote', value: LocationDisposition.Remote },
                {
                  label: 'Hybrid',
                  value: LocationDisposition.Hybrid,
                },
                { label: 'In-Person', value: LocationDisposition.InPerson },
              ]}
              onChange={(value) => handleChange('locationDisposition', value as LocationDisposition)}
            />
          </Grid>
          <Grid item xs={6}>
            <RadioGroup
              formLabel={'Experience Level'}
              options={[
                {
                  label: 'Internship',
                  value: ExperienceLevel.Internship,
                },
                {
                  label: 'Entry Level',
                  value: ExperienceLevel.EntryLevel,
                },
                {
                  label: 'Associate',
                  value: ExperienceLevel.Associate,
                },
                {
                  label: 'Mid-Senior Level',
                  value: ExperienceLevel.MidSeniorLevel,
                },
                {
                  label: 'Director',
                  value: ExperienceLevel.Director,
                },
                {
                  label: 'Executive',
                  value: ExperienceLevel.Executive,
                },
              ]}
              onChange={(value) => handleChange('experienceLevel', value as ExperienceLevel)}
            />
          </Grid>

          <Grid item xs={6} container justifyContent={'space-around'} direction={'column'}>
            <Grid item>
              <TextField
                sx={{ width: '14.0625rem' }}
                margin="dense"
                label="Salary"
                name="salary"
                required
                type="text"
                variant="outlined"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  inputProps: { inputMode: 'numeric', pattern: '[0-9,]*' },
                }}
                onChange={(e) => handleChange('salary', Number(e.currentTarget.value))}
              />
            </Grid>

            <Grid item>
              <Grid container direction={'column'}>
                <TextField
                  sx={{ width: '14.0625rem' }}
                  type="date"
                  required
                  label={'Start Date'}
                  name="startDate"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  onChange={(e) => handleChange('startDate', new Date(e.currentTarget.value))}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </StyledPaper>
    </StyledModalPaperContainer>
  );
};
