import {
  Autocomplete,
  AutocompleteProps,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { concatenateStringAndLowercaseFirstLetter } from '../../services/shared/stringHelpers';
import { apiClient } from '../../services/apiClient';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

export type SelectType = {
  onChange: (e: { target: { name: any; value: any } }) => void;
  options: (string | undefined)[];
  label: string;
  error?: boolean;
  helperText?: string;
  width?: string;
  defaultValue?: string;
};

export const StyledFormControl = styled(FormControl)(({ theme }) => ({
  '& .MuiFormHelperText-root': {
    color: theme.palette.error.main,
  },
}));

export const SelectDropdown: FC<SelectType> = ({
  defaultValue,
  options,
  label,
  width,
  onChange,
  error,
  helperText,
}) => {
  return (
    <StyledFormControl sx={{ width: width ? width : '100%' }}>
      <InputLabel id={label + 'select-label'}>
        <Typography variant={'h5'}>{label}</Typography>
      </InputLabel>
      <Select
        defaultValue={defaultValue}
        error={error ? error : false}
        labelId={label + 'select-label'}
        id={label + 'select'}
        label={label}
        name={label}
        onChange={onChange}
      >
        {options &&
          options?.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </StyledFormControl>
  );
};

type MailroomAutocompleteProps<DisableClearable extends boolean | undefined, FreeSolo extends boolean | undefined> = {
  onChange: (value: string) => any;
  label: string;
  error?: boolean;
  helperText?: string;
  required?: boolean;
  variant?: 'outlined' | 'filled' | 'standard';
} & Omit<AutocompleteProps<string, false, DisableClearable, FreeSolo>, 'onChange' | 'multiple' | 'renderInput'>;

export function MailroomAutocomplete<
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>(props: MailroomAutocompleteProps<DisableClearable, FreeSolo>) {
  const { onChange, error, options, label, helperText, required, variant, value, ...otherProps } = props;
  const textFieldName = useMemo(() => concatenateStringAndLowercaseFirstLetter(label), [label]);

  return (
    <Autocomplete
      {...otherProps}
      value={value ?? ''}
      options={options || []}
      multiple={false}
      onChange={(event, option) => onChange(option || '')}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          error={error ? error : false}
          helperText={error && helperText}
          name={textFieldName}
          variant={variant ? variant : 'outlined'}
          label={label}
        />
      )}
    />
  );
}

type MultiSelectAutocompleteProps = {
  onChange: (value: string[]) => any;
  label?: string;
  error?: boolean;
  helperText?: string;
  required?: boolean;
  chipSize?: 'small' | 'medium' | undefined;
  variant?: 'filled' | 'standard' | 'outlined' | undefined;
};

export function MultiSelectAutocomplete<DisableClearable extends boolean | undefined = undefined>(
  props: MultiSelectAutocompleteProps &
    Omit<Partial<AutocompleteProps<string, true, DisableClearable, true>>, 'onChange'>,
) {
  const { chipSize, options, freeSolo, label, onChange, required, variant, ...otherProps } = props;
  return (
    <Autocomplete
      {...otherProps}
      multiple
      freeSolo={freeSolo}
      options={options || []}
      onChange={(event, options) => {
        if (!options) {
          onChange([]);
          return;
        }
        onChange(options);
      }}
      renderTags={(value: (string | undefined)[], getTagProps) =>
        value.map((option: string | undefined, index: number) =>
          option ? (
            <Chip
              size={chipSize ? chipSize : undefined}
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          ) : (
            <></>
          ),
        )
      }
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          name={concatenateStringAndLowercaseFirstLetter(label)}
          variant={variant ? variant : 'outlined'}
          label={label ? label : undefined}
        />
      )}
    />
  );
}

export type SkillsAutocompleteProps = {
  skillCategory: string;
};

export function SkillsAutocomplete<
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  skillCategory,
  onChange,
  ...otherProps
}: SkillsAutocompleteProps & Omit<MailroomAutocompleteProps<DisableClearable, FreeSolo>, 'options'>) {
  const [textValue, setTextValue] = useState(otherProps.value);
  const [options, setOptions] = useState<string[]>([]);

  useEffect(() => {
    apiClient.skills_GetSkills(skillCategory, textValue ?? undefined, false).then(({ availableSkills }) => {
      const options = availableSkills?.filter((x) => x?.name)?.map((x) => x?.name ?? '') ?? [];
      setOptions(options);
    });
  }, [textValue, skillCategory]);

  return (
    <MailroomAutocomplete
      {...otherProps}
      onInputChange={(e, v) => (otherProps.freeSolo ? onChange(v) : null)}
      onChange={(v) => {
        console.log(v);
        setTextValue(v);
        onChange(v);
      }}
      options={options}
    />
  );
}

export function SkillCategoryAutocomplete<
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({ onChange, ...otherProps }: Omit<MailroomAutocompleteProps<DisableClearable, FreeSolo>, 'options'>) {
  const [textValue, setTextValue] = useState(otherProps.value);
  const [options, setOptions] = useState<string[]>([]);

  useEffect(() => {
    apiClient.skills_AutocompleteSkillCategories(textValue ?? undefined).then((response) => {
      setOptions(response.categories ?? []);
    });
  }, [textValue]);

  return (
    <MailroomAutocomplete
      {...otherProps}
      onInputChange={(e, v) => (otherProps.freeSolo ? onChange(v) : null)}
      onChange={(v) => {
        setTextValue(v);
        onChange(v);
      }}
      options={options}
    />
  );
}

export function DepartmentMultiAutocomplete<DisableClearable extends boolean | undefined = undefined>({
  onChange,
  ...otherProps
}: Omit<
  MultiSelectAutocompleteProps & Partial<Omit<AutocompleteProps<string, true, DisableClearable, true>, 'onChange'>>,
  'options'
>) {
  const [textValue, setTextValue] = useState(otherProps.inputValue);
  const [options, setOptions] = useState<string[]>([]);
  const companyId = useSelector((r: RootState) => r.user.companyId);

  useEffect(() => {
    apiClient.jobRole_AutocompleteDepartments(companyId, textValue ?? undefined, true).then((response) => {
      setOptions(response.departmentOptions ?? []);
    });
  }, [textValue]);

  return (
    <MultiSelectAutocomplete
      {...otherProps}
      onChange={(v) => {
        onChange(v);
      }}
      onInputChange={(e, v) => {
        setTextValue(v);
      }}
      options={options}
    />
  );
}

export function DepartmentAutocomplete<
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({ onChange, ...otherProps }: Omit<MailroomAutocompleteProps<DisableClearable, FreeSolo>, 'options'>) {
  const [textValue, setTextValue] = useState(otherProps.value);
  const [options, setOptions] = useState<string[]>([]);
  const companyId = useSelector((r: RootState) => r.user.companyId);

  useEffect(() => {
    apiClient.jobRole_AutocompleteDepartments(companyId, textValue ?? undefined, true).then((response) => {
      setOptions(response.departmentOptions ?? []);
    });
  }, [textValue]);

  return (
    <MailroomAutocomplete
      {...otherProps}
      onInputChange={(e, v) => (otherProps.freeSolo ? onChange(v) : null)}
      onChange={(v) => {
        setTextValue(v);
        onChange(v);
      }}
      options={options}
    />
  );
}

export function JobTitleMultiAutocomplete<DisableClearable extends boolean | undefined = undefined>({
  onChange,
  ...otherProps
}: Omit<
  MultiSelectAutocompleteProps & Partial<Omit<AutocompleteProps<string, true, DisableClearable, true>, 'onChange'>>,
  'options'
>) {
  const [textValue, setTextValue] = useState(otherProps.inputValue);
  const [options, setOptions] = useState<string[]>([]);
  const companyId = useSelector((r: RootState) => r.user.companyId);

  useEffect(() => {
    apiClient.jobRole_AutocompleteTitles(companyId, textValue ?? undefined, true).then((response) => {
      setOptions(response.jobTitleOptions ?? []);
    });
  }, [textValue]);

  return (
    <MultiSelectAutocomplete
      {...otherProps}
      onChange={(v) => {
        onChange(v);
      }}
      onInputChange={(e, v) => {
        setTextValue(v);
      }}
      options={options}
    />
  );
}

export function TitleAutocomplete<
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({ onChange, ...otherProps }: Omit<MailroomAutocompleteProps<DisableClearable, FreeSolo>, 'options'>) {
  const [textValue, setTextValue] = useState(otherProps.value);
  const [options, setOptions] = useState<string[]>([]);
  const companyId = useSelector((r: RootState) => r.user.companyId);

  useEffect(() => {
    apiClient.jobRole_AutocompleteTitles(companyId, textValue ?? undefined, true).then((response) => {
      setOptions(response.jobTitleOptions ?? []);
    });
  }, [textValue]);

  return (
    <MailroomAutocomplete
      {...otherProps}
      onInputChange={(e, v) => (otherProps.freeSolo ? onChange(v) : null)}
      onChange={(v) => {
        setTextValue(v);
        onChange(v);
      }}
      options={options}
    />
  );
}
