import { Container, Grid, Paper, Tooltip, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import React, { FC, SyntheticEvent, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCompanyUsers,
  removeUserRoleAction,
  setCompanyUserPage,
  setCompanyUserPageSize,
  updateUserRoleAction,
} from '../redux/managementSlice';
import { RootState } from '../store';
import { MultiSelectAutocomplete } from '../components/shared/select';
import { IListCompanyUsersCompanyUser } from '../services/generated/ApiClientGenerated';
import { InfoOutlined } from '@mui/icons-material';

const GridText = (params: GridRenderCellParams) => <Typography>{params.value}</Typography>;

type UserRowRolesProps = {
  userToEdit: IListCompanyUsersCompanyUser | undefined;
};

const UserRowRoles: FC<UserRowRolesProps> = ({ userToEdit }) => {
  const userRoleOptions = ['Super Admin', 'Admin', 'Hiring Manager'];
  const dispatch = useDispatch();
  const currentUserId = useSelector((r: RootState) => r.profile.profileDetails.userId);

  const handleChange = (value: string[] | SyntheticEvent) => {
    if (Array.isArray(value)) {
      const newRoles = value.filter((role) => !userToEdit?.roles?.some((r) => r === role));
      const rolesToRemove = userToEdit?.roles?.filter((role) => !value.some((r) => r === role)) ?? [];
      newRoles.forEach((role) => {
        dispatch(
          updateUserRoleAction({
            userEmail: userToEdit?.email,
            roleName: role.replace(/\s+/g, ''),
          }),
        );
      });
      rolesToRemove.forEach((role) => {
        dispatch(
          removeUserRoleAction({
            userEmail: userToEdit?.email,
            roleName: role.replace(/\s+/g, ''),
          }),
        );
      });
    }
  };

  return (
    <MultiSelectAutocomplete
      fullWidth
      disabled={currentUserId == userToEdit?.userId ? true : false}
      chipSize={'small'}
      variant={'standard'}
      options={userRoleOptions}
      defaultValue={userToEdit?.roles ? userToEdit.roles : []}
      onChange={handleChange}
    />
  );
};

export const UserManagement: FC = () => {
  const users = useSelector((r: RootState) => r.management.users);
  const userRows = useMemo(() => users.value?.map((c) => ({ ...c, key: c.userId })) ?? [], [users]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCompanyUsers());
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'firstName',
      headerName: 'First Name',
      minWidth: 200,
      renderCell: GridText,
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      minWidth: 200,
      renderCell: GridText,
    },
    {
      field: 'email',
      headerName: 'Email',
      minWidth: 200,
      flex: 1,
      renderCell: GridText,
    },
    {
      field: 'roles',
      headerName: 'Roles',
      minWidth: 200,
      flex: 1,
      renderHeader: () => (
        <>
          {'Roles'}
          <Tooltip
            arrow
            title={
              <React.Fragment>
                <Grid container direction={'column'}>
                  <Typography>
                    <b>Company Manager:</b> Able to manage the company and its users. Also includes capabilities of the{' '}
                    <b>Hiring Manager</b> user.
                  </Typography>
                  <Typography>
                    <b>Hiring Manager:</b> Able to post new jobs and search for existing jobs. Also includes
                    capabilities of the <b>Employee</b> user.
                  </Typography>
                  <Typography>
                    <b>Employee:</b> Able to create a mailroom profile and find and apply to new roles.
                  </Typography>
                </Grid>
              </React.Fragment>
            }
          >
            <InfoOutlined sx={{ marginLeft: 1 }} />
          </Tooltip>
        </>
      ),
      renderCell: (params) => <UserRowRoles userToEdit={users.value?.find((c) => c.userId === params.id)} />,
    },
  ];
  return (
    <Container maxWidth="lg">
      <Grid container direction="row" spacing={3}>
        <Grid item xs={12}>
          <Typography component={'h1'} variant={'h3'}>
            Manage Users
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 1.5 }}>
            Manage your company's users of Mailroom
          </Typography>
          <Paper sx={{ marginTop: 3, padding: 2 }}>
            <Grid container spacing={2} direction="column">
              <Grid item sx={{ height: 640, flexGrow: 1 }}>
                <DataGrid
                  getRowId={(row) => row.key}
                  loading={users.isLoading}
                  rows={userRows}
                  columns={[...columns, { field: 'roles', sortable: false }]}
                  page={users.page}
                  onPageChange={(page) => dispatch(setCompanyUserPage(page))}
                  rowCount={users.totalCount}
                  pageSize={users.pageSize}
                  disableColumnMenu
                  disableSelectionOnClick
                  onPageSizeChange={(pageSize) => dispatch(setCompanyUserPageSize(pageSize))}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
