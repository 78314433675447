import { Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { SelectDropdown, SkillCategoryAutocomplete, SkillsAutocomplete } from '../../../shared/select';
import ClearIcon from '@mui/icons-material/Clear';
import React, { FC } from 'react';
import { InfoOutlined } from '@mui/icons-material';

export type TechSkillRowProps = {
  onChangeCategory: (value: string) => any;
  onChangeSkill: (value: string) => any;
  categoryOption: string | undefined;
  onWeightChange: (value: { target: { name: any; value: any } }) => void;
  onClickDelete: (value: any) => void;
};

export const TechSkillRow: FC<TechSkillRowProps> = ({
  onChangeCategory,
  onChangeSkill,
  categoryOption,
  onWeightChange,
  onClickDelete,
}) => {
  return (
    <Grid container p={1} spacing={2}>
      <Grid item xs={4}>
        <SkillCategoryAutocomplete freeSolo required label={'Skills category'} onChange={onChangeCategory} />
      </Grid>
      <Grid item xs={4}>
        <SkillsAutocomplete
          freeSolo
          required
          label={'Skill'}
          skillCategory={categoryOption ?? ''}
          onChange={onChangeSkill}
        />
      </Grid>
      <Grid container item sx={{ position: 'relative' }} xs={3}>
        <SelectDropdown
          defaultValue={'Beginner'}
          onChange={onWeightChange}
          options={['Beginner', 'Intermediate', 'Advanced']}
          label={'Level'}
        />
        <Tooltip
          title={
            <Stack spacing={1}>
              <Typography variant="caption">
                Beginner — may need some direction, and you want to grow them in this skill
              </Typography>
              <Typography variant="caption">
                Intermediate — someone who can perform the skill with minimal guidance
              </Typography>
              <Typography variant="caption">Advanced — someone who can help others grow in this area</Typography>
            </Stack>
          }
        >
          <InfoOutlined sx={{ position: 'absolute', right: 32, alignSelf: 'center' }} />
        </Tooltip>
      </Grid>
      <Grid item xs={1}>
        <IconButton aria-label="delete" color={'warning'} onClick={onClickDelete}>
          <ClearIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};
