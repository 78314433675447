import React, { FC, FormEvent, useEffect, useState } from 'react';
import '../App.css';
import { Checkbox, Container, Divider, FormControlLabel, FormGroup, Grid, Paper, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { AddSkill } from '../components/skills/addSkill';
import { IAddSkillRequest } from '../services/generated/ApiClientGenerated';
import {
  addSkillsAction,
  deleteSelectedSkillAction,
  getAvailableCategoriesAction,
  getAvailableSkillsAction,
} from '../redux/skillsSlice';
import { concatenateStringAndLowercaseFirstLetter } from '../services/shared/stringHelpers';

export const SkillsDatabase: FC = () => {
  const categories = useSelector((r: RootState) => r.skills.categories);
  const skills = useSelector((r: RootState) => r.skills.skills);
  const [category, setCategory] = useState<string>();
  const [skill, setSkill] = useState<string>();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAvailableCategoriesAction({ prefix: undefined }));
    dispatch(getAvailableSkillsAction({ type: undefined, prefix: undefined, includeArchived: false }));
  }, []);

  const handleSubmitNewSkill = (e: FormEvent) => {
    const payload: IAddSkillRequest = { name: skill, category: category };
    dispatch(addSkillsAction(payload));
    e.preventDefault();
  };

  const deleteSkill = (skillId?: string) => {
    if (skillId) dispatch(deleteSelectedSkillAction(skillId));
  };

  return (
    <Container maxWidth="lg">
      <Grid container direction={'row'} spacing={3}>
        <Grid item xs={12}>
          <Typography component={'h1'} variant={'h3'}>
            Skills Database
          </Typography>
          <Typography variant="body1">
            Please add the technical skills that you'll be looking for when evaluating candidates. The skills you add
            here will be available for everyone in your company to add to their profile or to job postings.
          </Typography>

          <Paper sx={{ marginTop: 3 }}>
            <form onSubmit={handleSubmitNewSkill}>
              <AddSkill
                skill={skill || ''}
                category={category || ''}
                onCategoryChanged={setCategory}
                onSkillChanged={setSkill}
              />
            </form>

            <Grid container direction={'column'} px={2} spacing={1}>
              {categories &&
                categories.map((categoryOption) => (
                  <React.Fragment key={categoryOption}>
                    <Grid item mt={2}>
                      <Divider />
                    </Grid>
                    <Grid item mx={2} mt={2}>
                      <Typography variant="body2">
                        {categoryOption && categoryOption.charAt(0).toUpperCase() + categoryOption.slice(1)}
                      </Typography>
                    </Grid>
                    <Grid item mx={2}>
                      <FormGroup>
                        <Grid container direction={'row'}>
                          {skills
                            ?.filter((skill) => skill.category === categoryOption)
                            .map((skill, index) => (
                              <Grid item sm={3} key={index}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      defaultChecked={true}
                                      name={skill.name ? concatenateStringAndLowercaseFirstLetter(skill.name) : ''}
                                      onChange={() => deleteSkill(skill.id)}
                                    />
                                  }
                                  label={skill.name ? skill.name : ''}
                                />
                              </Grid>
                            ))}
                        </Grid>
                      </FormGroup>
                    </Grid>
                  </React.Fragment>
                ))}
            </Grid>

            <Divider sx={{ paddingTop: 3 }} />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
