import React, { FC } from 'react';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { XFactorQuestionProps } from './xFactorQuestion';

export const LikertQuestion: FC<XFactorQuestionProps<string>> = ({ question, value, onChange }) => (
  <FormControl required>
    <FormLabel id={question.id}>{question.question}</FormLabel>
    <RadioGroup aria-labelledby={question.id} value={value} onChange={(e, v) => onChange(v)}>
      {question.options?.map((o) => (
        <FormControlLabel
          key={`${question.id}-${o.value}`}
          control={<Radio />}
          label={o.text ?? ''}
          sx={{ color: o.value?.toString() === value?.toString() ? '#59BDBF' : undefined }}
          value={o.value}
        />
      ))}
    </RadioGroup>
  </FormControl>
);
