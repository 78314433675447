import React, { FC } from 'react';
import { Grid, Typography } from '@mui/material';

export type MainCandidateCardDividerAreaType = {
  skills?: string[];
  label: string;
};

export const MainCandidateCardDividerArea: FC<MainCandidateCardDividerAreaType> = ({ label, skills }) => {
  return (
    <>
      <Typography variant={'h5'}>{label}</Typography>
      <Grid container item sx={{ minHeight: '50px' }}>
        {skills?.map((skill) => (
          <Grid item key={skill} sx={{ mr: 1 }}>
            <Typography>{skill}</Typography>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
