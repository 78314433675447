import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ICreateCompanyRequest,
  IListCompaniesCompanyItem,
  IListCompaniesResponse,
  IListCompanyUsersCompanyUser,
  ListCompanyUsersResponse,
} from '../services/generated/ApiClientGenerated';
import { Loadable, Paginated } from '../services/shared/baseTypes';

export type ManagementSliceState = {
  companies: Loadable<IListCompaniesCompanyItem[]> & Paginated<IListCompaniesCompanyItem>;
  users: Loadable<IListCompanyUsersCompanyUser[]> & Paginated<IListCompanyUsersCompanyUser>;
};

export const managementSlice = createSlice({
  name: 'management',
  initialState: {
    companies: {
      isLoaded: false,
      isLoading: false,
      page: 0,
      pageSize: 25,
      totalCount: 0,
      value: [],
    },
    users: {
      isLoaded: false,
      isLoading: false,
      page: 0,
      pageSize: 25,
      totalCount: 0,
      value: [],
    },
  } as ManagementSliceState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    createCompany: (state, action: PayloadAction<{ body?: ICreateCompanyRequest }>) => {
      state.companies.isLoading = true;
      state.companies.isLoaded = false;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    deleteCompany: (state, action: PayloadAction<string>) => {},
    getCompanies: (state) => {
      state.companies.isLoading = true;
      state.companies.isLoaded = false;
    },
    getCompaniesSuccess: (state, action: PayloadAction<IListCompaniesResponse>) => {
      state.companies.isLoading = false;
      state.companies.isLoaded = true;
      state.companies.page = action.payload.currentPage ?? state.companies.page;
      state.companies.totalCount = action.payload.resultCount ?? 0;
      state.companies.value = action.payload?.results ?? [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getCompany: (state, action: PayloadAction<string>) => {},
    getCompanyUsers: (state) => {
      state.users.isLoading = true;
      state.users.isLoaded = false;
    },
    getCompanyUsersSuccess: (state, action: PayloadAction<ListCompanyUsersResponse>) => {
      state.users.isLoading = false;
      state.users.isLoaded = true;
      state.users.totalCount = action.payload.resultCount ?? 0;
      state.users.value = action.payload?.results ?? [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    removeUserRoleAction: (state, action: PayloadAction<{ userEmail?: string; roleName?: string }>) => {},
    setCompanyPage: (state, action: PayloadAction<number>) => {
      state.companies.page = action.payload;
    },
    setCompanyPageSize: (state, action: PayloadAction<number>) => {
      state.companies.pageSize = action.payload;
    },
    setCompanyUserPage: (state, action: PayloadAction<number>) => {
      state.users.page = action.payload;
    },
    setCompanyUserPageSize: (state, action: PayloadAction<number>) => {
      state.users.pageSize = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateCompany: (state, action: PayloadAction<{ id: string; name: string; domainName: string }>) => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateUserRoleAction: (state, action: PayloadAction<{ userEmail?: string; roleName?: string }>) => {},
  },
});

export const {
  createCompany,
  deleteCompany,
  getCompanies,
  getCompaniesSuccess,
  getCompany,
  getCompanyUsers,
  getCompanyUsersSuccess,
  removeUserRoleAction,
  setCompanyPage,
  setCompanyPageSize,
  setCompanyUserPage,
  setCompanyUserPageSize,
  updateCompany,
  updateUserRoleAction,
} = managementSlice.actions;
