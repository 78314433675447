import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type UserSliceState = {
  userId?: string;
  companyId?: string;
  isAuthenticated: boolean;
  roles: string[];
};

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    isAuthenticated: false,
    roles: [],
  } as UserSliceState,
  reducers: {
    setAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    getUserInfo: () => {},
    successGetUserInfo: (state, action: PayloadAction<{ userId?: string; companyId?: string; roles?: string[] }>) => {
      state.userId = action.payload.userId;
      state.roles = action.payload.roles ?? [];
      state.companyId = action.payload.companyId;
    },
    failureGetUserInfo: () => {},
  },
});

export const { setAuthenticated, getUserInfo, successGetUserInfo, failureGetUserInfo } = userSlice.actions;
