import React, { FC, FormEvent, useEffect, useState } from 'react';
import { Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { Slider } from '../../shared/slider';
import { useDispatch, useSelector } from 'react-redux';
import { updateTechSkillsAction } from '../../../redux/profileSlice';
import { ButtonStyledText } from '../../shared/typography';
import { ISkill } from '../../../services/generated/ApiClientGenerated';
import { RootState } from '../../../store';
import ClearIcon from '@mui/icons-material/Clear';
import { SkillCategoryAutocomplete, SkillsAutocomplete } from '../../shared/select';
import { ContainedButton } from '../../shared/button';
import { getAvailableSkillsAction } from '../../../redux/skillsSlice';
import { InfoOutlined } from '@mui/icons-material';
import { showToastAction } from '../../../redux/globalSlice';

export const Skills: FC = () => {
  const techSkillsState = useSelector((r: RootState) => r.profile.profileDetails.skills);

  const [currentCategory, setCurrentCategory] = useState<string>();
  const [currentSkill, setCurrentSkill] = useState<string>();
  const [techSkillsArray, setTechSkillsArray] = useState<ISkill[] | undefined>(techSkillsState);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAvailableSkillsAction({ type: undefined, prefix: undefined, includeArchived: false }));
  }, []);

  useEffect(() => {
    setTechSkillsArray(techSkillsState);
  }, [techSkillsState]);

  useEffect(() => {
    if (techSkillsArray && techSkillsArray !== techSkillsState) {
      dispatch(updateTechSkillsAction(techSkillsArray));
    }
  }, [techSkillsArray]);

  const handleSliderInputChange = (name: string | undefined, value: string | number | undefined) => {
    if (typeof value === 'number') {
      const techSkillsRoundedSingleDecimalValue = Number((value * 0.01).toFixed(1));
      const newArr = techSkillsArray?.map((skill) => {
        if (skill.name === name) {
          return { ...skill, level: techSkillsRoundedSingleDecimalValue };
        }
        return skill;
      });
      setTechSkillsArray(newArr);
    } else return;
  };

  const removeSkill = (
    itemToRemove: string | React.MouseEvent<HTMLButtonElement, MouseEvent>,
    indexToRemove: number,
  ) => {
    setTechSkillsArray(techSkillsArray?.filter((item, itemIndex) => itemIndex !== indexToRemove));
  };

  const handleAddSkill = (e: FormEvent) => {
    e.preventDefault();
    if (techSkillsArray) {
      if (techSkillsArray.some((skill) => skill.name === currentSkill && skill.category === currentCategory)) {
        dispatch(showToastAction({ message: 'Skill already exists', severity: 'warning' }));
        return;
      }
      setTechSkillsArray([
        ...techSkillsArray,
        {
          name: currentSkill,
          category: currentCategory,
          level: 0,
        },
      ]);
    } else {
      setTechSkillsArray([
        {
          name: currentSkill,
          category: currentCategory,
          level: 0,
        },
      ]);
    }
    console.log(techSkillsArray, currentSkill, currentCategory);

    if (currentCategory && currentSkill && techSkillsArray) {
      dispatch(updateTechSkillsAction(techSkillsArray));
    }
  };
  const groupedTechSkills =
    techSkillsArray
      ?.filter((s) => s && s.name && s.category)
      ?.sort((s1, s2) => s1.name!.localeCompare(s2.name!))
      .reduce((acc, curr) => {
        if (!curr || !curr.category || !curr.name) return acc;
        if (!acc[curr.category]) {
          acc[curr.category] = [curr];
        } else {
          acc[curr.category].push(curr);
        }
        return acc;
      }, {} as { [key: string]: ISkill[] }) ?? {};
  const groupedTechSkillsArr = Object.entries(groupedTechSkills).sort(([a], [b]) => a.localeCompare(b));

  return (
    <Grid container direction="column" sx={{ position: 'relative' }}>
      <form onSubmit={handleAddSkill}>
        <Grid container item spacing={1}>
          <Grid item xs={5}>
            <SkillCategoryAutocomplete
              freeSolo
              label={'Skills category'}
              required
              value={currentCategory}
              onChange={(v) => setCurrentCategory(v)}
            />
          </Grid>

          <Grid item xs={4}>
            <SkillsAutocomplete
              freeSolo
              helperText={'Please select a skill'}
              label={'Skill'}
              required
              skillCategory={currentCategory ?? ''}
              value={currentSkill}
              onChange={(v) => setCurrentSkill(v)}
            />
          </Grid>

          <Grid item>
            <ContainedButton type="submit" sx={{ height: '50px', mt: 0 }} text={'Add'} />
          </Grid>
        </Grid>
      </form>

      <Grid container mt={2} direction="column">
        {techSkillsState && techSkillsState.length > 0 && (
          <ButtonStyledText sx={{ paddingLeft: 0 }}>
            {'Experience Level'}{' '}
            <Tooltip
              title={
                <Stack spacing={1}>
                  <Typography>
                    <b>Beginner</b> means that you've just gotten started, are still learning, or have only used this
                    skill in a limited capacity.
                  </Typography>
                  <Typography>
                    <b>Intermediate</b> means that you're comfortable with this skill and may use it regularly.
                  </Typography>
                  <Typography>
                    <b>Advanced</b> means that you probably know things about this skill that most people don't. If you
                    had to, you'd be comfortable training other people about what you know.
                  </Typography>
                </Stack>
              }
            >
              <InfoOutlined fontSize="small" />
            </Tooltip>
          </ButtonStyledText>
        )}

        {groupedTechSkillsArr.map(([category, skills]) => (
          <Stack key={category}>
            <Typography variant={'h5'}>{category}</Typography>
            {skills?.map((techSkill, i) => (
              <Grid container m={2} key={techSkill.name}>
                <Grid item xs={11}>
                  <Slider
                    marks={[
                      {
                        value: 0,
                        label: 'Beginner',
                      },
                      {
                        value: 50,
                        label: 'Intermediate',
                      },
                      {
                        value: 100,
                        label: 'Advanced',
                      },
                    ]}
                    defaultValue={techSkill.level ? techSkill.level * 100 : 0}
                    step={10}
                    formLabel={techSkill.name ? techSkill.name : ''}
                    name={techSkill.name ? techSkill.name : ''}
                    onChange={(value) => handleSliderInputChange(techSkill.name, value)}
                  />
                </Grid>
                <Grid item xs={1}>
                  <IconButton aria-label="delete" color={'warning'} onClick={(value) => removeSkill(value, i)}>
                    <ClearIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </Stack>
        ))}
      </Grid>
    </Grid>
  );
};
