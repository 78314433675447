import { Card, CardActionArea, CardContent, styled } from '@mui/material';

export const StyledCard = styled(Card)(({}) => ({
  borderRadius: '13px',
  height: '100%',
}));

export const StyledCardActionArea = styled(CardActionArea)(({ theme }) => ({
  borderRadius: '13px',
  border: '1px solid transparent',
  '&:hover': {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  height: '100%',
}));

export const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(4),
  height: '100%',
}));

export const StyledCandidateCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2, 0),
  height: '100%',
  borderRadius: '6px',
  border: '1px solid transparent',
  '&:hover': {
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

export const StyledGreyCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
  background: theme.palette.secondary.main,
  borderRadius: '6px',
  border: '1px solid transparent',
  '&:hover': {
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));
