import { all, put, select, take, takeLatest } from 'redux-saga/effects';
import { apiCall, apiClient } from '../services/apiClient';
import {
  IXFactorAnswer,
  XFactorAnswer,
  XFactorQuestion,
  GetXFactorScenariosXFactorScenarioWithCompletion,
  IGetXFactorScenariosXFactorScenarioWithCompletion,
} from '../services/generated/ApiClientGenerated';
import {
  failedLoadedQuestions,
  failedLoadedXFactorScenarios,
  loadXFactorScenarios,
  selectScenario,
  successLoadedQuestions,
  successLoadedXFactorScenarios,
} from '../redux/xFactorsSlice';
import { showConfettiAction, showToastAction } from '../redux/globalSlice';
import { PayloadAction } from '@reduxjs/toolkit';
import { successUpdateXFactors, updateXFactors } from '../redux/profileSlice';
import { RootState } from '../store';

function* loadXFactorScenariosSaga() {
  try {
    const scenarios: GetXFactorScenariosXFactorScenarioWithCompletion[] = yield apiCall(
      apiClient.xFactors_GetXFactorScenarios,
    );
    yield put(successLoadedXFactorScenarios(scenarios.map((s) => s.toJSON())));
  } catch {
    yield put(failedLoadedXFactorScenarios());
    yield put(
      showToastAction({
        severity: 'warning',
        message: 'Could not load x-factor scenarios',
      }),
    );
  }
}

function* selectScenarioSaga(action: PayloadAction<string | undefined>) {
  try {
    const questions: XFactorQuestion[] = action.payload
      ? yield apiCall(apiClient.xFactors_GetXFactorQuestions, action.payload)
      : yield apiCall(apiClient.xFactors_GetXFactorQuestionsForNoScenario);
    yield put(successLoadedQuestions(questions.map((q) => q.toJSON())));
  } catch {
    yield put(failedLoadedQuestions());
    yield put(
      showToastAction({
        message: 'Could not load question information',
        severity: 'warning',
      }),
    );
  }
}

function* updateXFactorsSaga(action: PayloadAction<IXFactorAnswer[]>) {
  try {
    const uncompletedScenarios: IGetXFactorScenariosXFactorScenarioWithCompletion[] = yield select((state: RootState) =>
      state.xFactors.scenarios.filter((s) => s.completedPercentage !== 1),
    );
    yield apiCall(
      apiClient.profile_UpdateXFactorSkills,
      action.payload.map((x) => new XFactorAnswer(x)),
    );
    yield put(successUpdateXFactors(action.payload));
    yield put(loadXFactorScenarios());
    const result: PayloadAction<IGetXFactorScenariosXFactorScenarioWithCompletion[]> = yield take(
      successLoadedXFactorScenarios.type,
    );
    if (uncompletedScenarios.length > 0 && result.payload.every((s) => s.completedPercentage === 1)) {
      yield put(
        showToastAction({
          message: 'Congratulations on finishing all scenarios! Come back anytime to view your responses.',
          severity: 'success',
        }),
      );
      yield put(showConfettiAction());
    } else {
      yield put(showToastAction({ message: 'Successfully updated scenarios', severity: 'success' }));
    }
  } catch {
    yield put(showToastAction({ message: 'Could not update scenario', severity: 'warning' }));
  }
}

export function* xFactorsSaga() {
  yield all([
    takeLatest(loadXFactorScenarios.type, loadXFactorScenariosSaga),
    takeLatest(selectScenario.type, selectScenarioSaga),
    takeLatest(updateXFactors.type, updateXFactorsSaga),
  ]);
}
