import React, { FC, FormEvent, useEffect, useState } from 'react';
import '../App.css';
import { Container, Divider, Grid, Paper, Typography } from '@mui/material';
import { JobSearchPreferences } from '../components/settings/jobSearchPreferences';
import { Demographics } from '../components/settings/demographics';
import { ContainedButton } from '../components/shared/button';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { IUpdateUserSettingsRequest } from '../services/generated/ApiClientGenerated';
import {
  getSettingsAction,
  SerializableIUpdateUserSettingsRequest,
  updateSettingsAction,
} from '../redux/settingsSlice';
import { Loading } from '../components/shared/loading';

export const Settings: FC = () => {
  const userSettings = useSelector((r: RootState) => r.settings.userSettings);
  const [unsavedSettings, setUnsavedSettings] = useState<SerializableIUpdateUserSettingsRequest>(userSettings);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSettingsAction());
  }, []);

  useEffect(() => {
    setUnsavedSettings(userSettings);
  }, [userSettings]);

  const handleAutocompleteChange = (key: keyof IUpdateUserSettingsRequest, value: any) => {
    setUnsavedSettings({
      ...unsavedSettings,
      [key]: value,
    });
  };

  const handleSave = (e: FormEvent) => {
    dispatch(updateSettingsAction(unsavedSettings));
    e.preventDefault();
  };

  const handleRangeSliderChange = (
    keyOne: keyof IUpdateUserSettingsRequest,
    keyTwo: keyof IUpdateUserSettingsRequest,
    value: (number | undefined)[],
  ) => {
    setUnsavedSettings({
      ...unsavedSettings,
      [keyOne]: value[0],
      [keyTwo]: value[1],
    });
  };

  const handleChange = (value: Partial<SerializableIUpdateUserSettingsRequest>) => {
    console.log('change', value);
    setUnsavedSettings({
      ...unsavedSettings,
      ...value,
    });
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUnsavedSettings({
      ...unsavedSettings,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <Container maxWidth="lg">
      <Grid container direction={'row'}>
        <Grid item xs={12}>
          <Typography component={'h1'} variant={'h3'}>
            Settings
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 1.5 }}>
            Help us find the best matches for you by filling out all your job search preferences completely. Your
            demographic information will not be available to hiring managers until you are selected for an interview.
          </Typography>
          <form onSubmit={handleSave}>
            <Paper>
              <Loading
                noDataMessage={'There was an issue loading your settings'}
                loading={!unsavedSettings}
                value={unsavedSettings}
              >
                <Grid container py={3} px={2}>
                  <Demographics
                    onAutocompleteChange={handleAutocompleteChange}
                    onChange={handleChange}
                    value={unsavedSettings}
                  />
                  <Grid container item xs={1} justifyContent={'center'}>
                    <Divider orientation="vertical" variant="middle" flexItem />
                  </Grid>
                  <JobSearchPreferences
                    onAutocompleteChange={handleAutocompleteChange}
                    onSliderChange={handleRangeSliderChange}
                    onSwitchChange={handleSwitchChange}
                    onChange={handleChange}
                    value={unsavedSettings}
                  />
                </Grid>
              </Loading>
            </Paper>
            <Grid container justifyContent={'flex-end'}>
              <ContainedButton type={'submit'} text={'Save'} />
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};
