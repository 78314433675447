import React, { FC } from 'react';
import { Grid } from '@mui/material';
import { SkillCategoryAutocomplete, SkillsAutocomplete } from '../shared/select';
import { ContainedButton } from '../shared/button';

export type AddSkillProps = {
  category: string;
  skill: string;
  onCategoryChanged: (value: string) => void;
  onSkillChanged: (value: string) => void;
};

export const AddSkill: FC<AddSkillProps> = ({ category, onCategoryChanged, onSkillChanged }) => {
  return (
    <Grid container p={2} spacing={1}>
      <Grid item xs={4}>
        <SkillCategoryAutocomplete
          freeSolo
          label={'Skills category'}
          fullWidth
          variant="outlined"
          required
          onChange={onCategoryChanged}
        />
      </Grid>
      <Grid item xs={4} my={'auto'}>
        <SkillsAutocomplete
          skillCategory={category}
          freeSolo
          label={'Enter new skill'}
          fullWidth
          variant="outlined"
          required
          onChange={onSkillChanged}
        />
      </Grid>
      <Grid container item xs={4} justifyContent={'flex-end'} px={1}>
        <ContainedButton type="submit" sx={{ height: '50px', mt: 0 }} text={'Add'} />
      </Grid>
    </Grid>
  );
};
