import { Box, Dialog, DialogContent, DialogContentText, Grid, List, ListItem, Typography } from '@mui/material';
import React, { FC } from 'react';
import { PrimaryCandidateCard } from './primaryCandidateCard';
import { SecondaryCandidateCard } from './secondaryCandidateCard';
import { ContainedButton } from '../../shared/button';
import { StyledDialogTitleWithX } from '../../shared/dialog';
import { StyledLink } from '../../shared/link';
import { Loading } from '../../shared/loading';
import { Loadable } from '../../../services/shared/baseTypes';
import {
  addCandidateToInterview,
  SerializableJobMatchResponse,
  showCandidateComparisonAction,
} from '../../../redux/jobSlice';
import { IListJobMatchesJobMatchItem } from '../../../services/generated/ApiClientGenerated';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

interface CompareCandidatesModalProps {
  open: boolean;
  onClose: () => void;
  value?: Loadable<SerializableJobMatchResponse>;
  roleId?: string;
}

export const CompareCandidatesModal: FC<CompareCandidatesModalProps> = ({ onClose, open, value, roleId }) => {
  const results = value?.value?.results;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleComparisonClick = (candidate: IListJobMatchesJobMatchItem) => {
    dispatch(addCandidateToInterview([candidate]));
    dispatch(showCandidateComparisonAction());
    navigate(`/job/matches/${roleId}`);
  };

  return (
    <Dialog fullWidth maxWidth={'md'} open={open} aria-labelledby={'Compare Candidates'} onClose={onClose}>
      <StyledDialogTitleWithX onClose={onClose}> Compare Candidates </StyledDialogTitleWithX>
      <DialogContent>
        <Box sx={{ margin: 2, padding: 2, border: 1, borderColor: '#59BDBF' }}>
          <Typography>Candidates are compared based on the following criteria:</Typography>
          <List>
            <ListItem>
              <Typography>
                1. First, candidates are included or excluded based on <b>their own job search preferences</b>.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                2. Next, mailroom looks at all possible candidates to try and find candidates based on their presence of
                up to <span style={{ fontWeight: 'bold' }}>4 skills</span> you listed
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                3. If not enough candidates are found, the threshold of required skills will be reduced to{' '}
                <b>3 skills</b>
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                4. Again, if not enough candidates are found, the threshold of required skills will be reduced to{' '}
                <b>2 skills</b>
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                5. After candidates are found, they are ranked based on how closely they match the{' '}
                <b>x-factor criteria</b> you listed
              </Typography>
            </ListItem>
          </List>
        </Box>
        <Loading noDataMessage={'No candidates were found'} loading={value?.isLoading ?? false} value={value?.value}>
          <DialogContentText>
            {results?.length ?? 0} candidates matched job specifications
            {results?.length ? ' - click each tile for quick view and to schedule interviews.' : ''}
          </DialogContentText>

          <Grid container spacing={1} width={'100%'} my={2}>
            {results?.slice(0, 2)?.map(
              (candidate, index) =>
                index <= 1 && (
                  <Grid item key={index} xs={6}>
                    <PrimaryCandidateCard
                      onComparisonClick={() => handleComparisonClick(candidate)}
                      key={index}
                      mouseHover={true}
                      xs={12}
                      candidate={candidate}
                    />
                  </Grid>
                ),
            )}
          </Grid>

          <Grid container spacing={1} width={'100%'} my={2}>
            {results?.map(
              (candidate, index) => index > 1 && <SecondaryCandidateCard key={index} candidate={candidate} />,
            )}
          </Grid>

          <Grid container justifyContent={'flex-end'}>
            <Grid item>
              <StyledLink to={`/job/matches/${roleId}`}>
                <ContainedButton text={'View all matches'} />
              </StyledLink>
            </Grid>
          </Grid>
        </Loading>
      </DialogContent>
    </Dialog>
  );
};
