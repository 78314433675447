import { FormControlLabel, Grid, Switch } from '@mui/material';
import { DepartmentMultiAutocomplete, TitleAutocomplete } from '../shared/select';
import React, { FC } from 'react';
import { RangeSlider } from '../shared/slider';
import { ListJobsRequest } from '../../redux/jobSlice';

type JobInterestHeaderProps = {
  value: ListJobsRequest;
  onChange: (newValue: ListJobsRequest) => any;
};

export const JobInterestHeader: FC<JobInterestHeaderProps> = ({ value, onChange }) => {
  const handleChange = (key: keyof ListJobsRequest) => (newValue: ListJobsRequest[keyof ListJobsRequest]) => {
    onChange({
      ...value,
      [key]: newValue,
    });
  };

  const handleRangeSliderChange =
    (keyOne: keyof ListJobsRequest, keyTwo: keyof ListJobsRequest) => (newValue: (number | undefined)[]) => {
      onChange({
        ...value,
        [keyOne]: newValue[0],
        [keyTwo]: newValue[1],
      });
    };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...value,
      [event.target.name]: !event.target.checked,
    });
  };

  return (
    <Grid container alignItems={'center'} mb={2} spacing={2}>
      <Grid item xs={3}>
        <DepartmentMultiAutocomplete
          label={'Department'}
          value={value.departments ?? []}
          onChange={handleChange('departments')}
        />
      </Grid>
      <Grid item xs={3}>
        <TitleAutocomplete label={'Job Title'} value={value.jobTitle ?? ''} onChange={handleChange('jobTitle')} />
      </Grid>
      <Grid item xs={3}>
        <RangeSlider
          value={[value.minSalary ?? 0, value.maxSalary ?? 500000]}
          onChange={handleRangeSliderChange('minSalary', 'maxSalary')}
          min={0}
          max={500000}
          label={'Desired Salary Range'}
          step={10000}
        />
      </Grid>

      <Grid container justifyContent={'flex-end'} item xs={3}>
        <FormControlLabel
          control={<Switch onChange={handleSwitchChange} name="useCurrentUsersSearchSettings" />}
          label="See all jobs"
        />
      </Grid>
    </Grid>
  );
};
