import { styled, Typography } from '@mui/material';

export const ButtonStyledText = styled(Typography)(({ theme }) => ({
  // test: theme.typography.button,
  padding: theme.spacing(1),
  textTransform: 'uppercase',
}));

export const StyledHeader = styled(Typography)(({}) => ({
  textTransform: 'uppercase',
  marginBottom: '3.125rem',
}));
