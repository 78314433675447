import { call, put } from 'redux-saga/effects';
import * as ApiClientGenerated from './generated/ApiClientGenerated';
import { TokenSingleton } from './TokenSingleton';
import { showToastAction } from '../redux/globalSlice';

export const apiClient = new ApiClientGenerated.ApiClient(process.env.REACT_APP_API_URL, {
  fetch: async (url, init?: RequestInit) => {
    const token = TokenSingleton.getAccessToken();
    const response = await fetch(url, {
      ...init,
      headers: { ...(init?.headers || {}), Authorization: `Bearer ${token}` },
    });
    if (response.status === 401 && !window.location.pathname.match(/^\/login/)) {
      // may need to http encoded location.pathname
      history.pushState({}, '', `/login?redirectUrl=${location.pathname}`);
    }
    return response;
  },
});

/**
 * Helper to call our API from redux-saga and handle errors in one place as necessary
 */
export function* apiCall<Fn extends (this: ApiClientGenerated.ApiClient, ...args: any[]) => any>(
  fn: Fn,
  ...args: Parameters<Fn>
): Generator<any> {
  try {
    return yield call<ApiClientGenerated.ApiClient, Fn>([apiClient, fn], ...args);
  } catch (e: any) {
    if (e.status === 403) {
      yield put(
        showToastAction({
          message: 'Permission denied',
          severity: 'warning',
          title: 'Unauthorized',
          body: e.detail,
        }),
      );
      return {};
    } else {
      throw e;
    }
  }
}
