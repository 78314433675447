import { FormControlLabel, FormLabel, Radio, RadioGroup as MuiRadioGroup } from '@mui/material';
import React, { FC } from 'react';

export type InternalRadioInputProps = {
  onChange: (value: string) => any;
  name?: string;
  options: {
    label: string;
    value: string;
  }[];
  formLabel: string;
  row?: boolean;
  value?: string;
};

export const RadioGroup: FC<InternalRadioInputProps> = ({ value, onChange, name, options, formLabel, row }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <>
      <FormLabel>{formLabel}</FormLabel>
      <MuiRadioGroup row={row} name={name} onChange={handleChange} value={value}>
        {options.map((option, index) => (
          <FormControlLabel key={index} control={<Radio />} label={option.label} value={option.value} />
        ))}
      </MuiRadioGroup>
    </>
  );
};
