import React, { FC } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Slider as XFactorsSlider } from '../../shared/slider';
import { XFactorQuestionProps } from './xFactorQuestion';

export const FalseOppositeQuestion: FC<XFactorQuestionProps<string>> = ({ question, value, onChange }) => {
  const calculateFontColor = (value: number, inverse: boolean) => {
    const calculatingValue = inverse ? 1 - value : value;
    if (calculatingValue <= 0.2) return '#A1A1A1';
  };
  const calculateFontWeight = (value: number, inverse: boolean) => {
    const calculatingValue = inverse ? 1 - value : value;
    if (calculatingValue === 1) return 'bold';
  };
  if (question?.options) {
    return (
      <Box>
        <XFactorsSlider
          marks={Array.from(Array(11).keys()).map((x) => ({ label: '', value: x / 10 }))}
          formLabel={question.question ?? ''}
          name={question.question ?? ''}
          min={0}
          max={1}
          step={0.1}
          defaultValue={value === '' ? 0.5 : parseFloat(value ?? '0.5')}
          onChange={(value) => onChange(value?.toString() ?? '0')}
        />
        <Grid container>
          <Grid item xs={4}>
            <Typography
              color={calculateFontColor(parseFloat(value ?? '0.5'), true)}
              fontWeight={calculateFontWeight(parseFloat(value ?? '0.5'), true)}
            >
              {question.options[0].text}
            </Typography>
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={4}>
            <Typography
              color={calculateFontColor(parseFloat(value ?? '0.5'), false)}
              fontWeight={calculateFontWeight(parseFloat(value ?? '0.5'), false)}
            >
              {question.options[1].text}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    );
  }

  return null;
};
