import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import React, { FC } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';
import { LinearProgress } from '@mui/material';

export type MailroomRole = 'SuperAdmin' | 'Admin' | 'HiringManager';

export type AuthenticatedProps = {
  role?: MailroomRole | MailroomRole[];
};

export const Authenticated: FC<AuthenticatedProps> = ({ role, children }) => {
  const user = useSelector((r: RootState) => r.user);
  const isAuthenticated = user.isAuthenticated;
  const roles = typeof role === 'string' ? [role] : role;
  const matchesRole =
    typeof roles === 'undefined' ||
    !roles ||
    user.roles.indexOf('SUPERADMIN') >= 0 ||
    roles.some((r) => user.roles.indexOf(r.toUpperCase()) >= 0);

  return isAuthenticated && matchesRole && (children as any);
};

export const PrivateRoute: FC<any> = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) return <LinearProgress variant="indeterminate" />;

  return isAuthenticated ? children : <Navigate to="/login" replace={true} />;
};
