export class TokenSingleton {
  private static instance: TokenSingleton;
  private AccessToken: string;

  private constructor() {
    this.AccessToken = '';
  }

  private static getInstance(): TokenSingleton {
    if (!TokenSingleton.instance) {
      TokenSingleton.instance = new TokenSingleton();
    }

    return TokenSingleton.instance;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public static getAccessToken() {
    const instance = TokenSingleton.getInstance();
    return instance.AccessToken;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public static setAccessToken(token: string) {
    const instance = TokenSingleton.getInstance();
    instance.AccessToken = token;
  }
}
