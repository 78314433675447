import React, { FC } from 'react';
import { Grid, LinearProgress, Typography } from '@mui/material';

export type LoadingProps = { noDataMessage: string; loading: boolean; value: any };
export const Loading: FC<LoadingProps> = ({ loading, noDataMessage, value, children }) => {
  if (loading) {
    return <LinearProgress variant="indeterminate" />;
  } else if (!value || (Array.isArray(value) && value.length == 0)) {
    return (
      <Grid container alignItems="center" justifyContent="center" direction="column" sx={{ marginTop: 12 }}>
        <Grid item>
          <Typography variant="h3">{noDataMessage}</Typography>
        </Grid>
      </Grid>
    );
  } else {
    return <>{children}</>;
  }
};
