import React, { FC } from 'react';

export const EditProfileSvg: FC = () => {
  return (
    <svg
      id="Group_9"
      data-name="Group 9"
      xmlns="http://www.w3.org/2000/svg"
      width="241.999"
      height="249.392"
      viewBox="0 0 241.999 249.392"
    >
      <defs>
        <clipPath id="clip-path">
          <path id="Clip_2" data-name="Clip 2" d="M0,0H57.471V70.8H0Z" transform="translate(0.058 0.608)" fill="none" />
        </clipPath>
        <clipPath id="clip-path-2">
          <path id="Clip_11" data-name="Clip 11" d="M0,0H25.969V53.847H0Z" transform="translate(0 0.58)" fill="none" />
        </clipPath>
      </defs>
      <g id="Background_Shape_2" data-name="Background Shape 2" transform="translate(26.999 192.392)" opacity="0.505">
        <path
          id="Background_Shape"
          data-name="Background Shape"
          d="M145.936,35.6c2.128,15.912-49.484,26.291-93.908,19.048s-64.8-35.529-43.605-50.6S143.808,19.689,145.936,35.6Z"
          fill="#f2f2f2"
        />
      </g>
      <g id="List" transform="translate(25.999 25.392)" opacity="0.5">
        <path
          id="Fill_253"
          data-name="Fill 253"
          d="M.026,1.988c0,3.667-.2,7.41.52,11.033.218,1.1.389,3.2,1.62,3.767a1.9,1.9,0,0,0,2.741-1.262c.358-1.386-.428-3.461-.507-4.916-.109-1.988-.117-3.979-.118-5.969,0-1.437.5-3.75-1.3-4.489A2.165,2.165,0,0,0,.026,1.988"
          transform="translate(2 4)"
          fill="#c1dee2"
        />
        <path
          id="Fill_255"
          data-name="Fill 255"
          d="M58.485.025C46.057,1.255,33.451,1.51,20.978,2.151c-5.766.3-12.733-.082-18.165,2.118C1.8,4.678-1.808,6.951,1.125,7.877c.914.289,1.843-.682,2.593-1.007a16.82,16.82,0,0,1,4.03-.92C19.082,4.156,30.937,4.468,42.387,3.63c2.934-.215,5.869-.436,8.8-.72,2.092-.2,6.488-1.689,8.419-.929,3.3,1.3,3.221,9.736,3.287,12.638.034,1.5-1.444,6.888,1.494,6.342,2.351-.437,1.5-6.034,1.317-7.564C65.219,9.384,64.4-.565,58.485.025"
          transform="translate(2)"
          fill="#c1dee2"
        />
        <path
          id="Fill_257"
          data-name="Fill 257"
          d="M60.961.006C50.239,1.024,39.27.025,28.5.425c-5.643.21-11.28.545-16.924.741-2.441.085-4.884.089-7.326.107-1.168.008-6.484.753-3.2,3.188,1.456,1.078,6.1.21,7.766.189,2.509-.031,5.017-.141,7.525-.244,5.808-.239,11.613-.57,17.424-.735,5.559-.158,11.12-.192,16.679-.35a66.841,66.841,0,0,0,8.311-.593c.619-.1,2.628-.172,3.057-.9.406-.686.121-1.923-.861-1.824"
          transform="translate(4 21)"
          fill="#89c5cc"
        />
        <path
          id="Fill_259"
          data-name="Fill 259"
          d="M5.935,12h0A5.6,5.6,0,0,1,1.8,10.25,6.561,6.561,0,0,1,.029,6.327,5.807,5.807,0,0,1,1.01,2.421,6.172,6.172,0,0,1,5.029.026,1.385,1.385,0,0,1,5.291,0a1.744,1.744,0,0,1,.838.264A1.942,1.942,0,0,0,6.8.515a1.8,1.8,0,0,0,.215.012A4,4,0,0,0,7.646.462,4.13,4.13,0,0,1,8.278.4a1.669,1.669,0,0,1,.508.074,5.123,5.123,0,0,1,2.869,3.35,6.454,6.454,0,0,1-.467,5.218A5.679,5.679,0,0,1,7.441,11.83,7.011,7.011,0,0,1,5.935,12ZM7.607,4.035h0c-1.333.007-3.718.306-4.2,1.383-.225.5-.017,1.122.618,1.844a2.566,2.566,0,0,0,1.957.962A2.469,2.469,0,0,0,8.208,6.765a2.476,2.476,0,0,0-.6-2.729Z"
          transform="translate(9 8)"
          fill="#c1dee2"
        />
        <path
          id="Fill_261"
          data-name="Fill 261"
          d="M21.045.076C15.908.092,10.769.307,5.632.4,4.056.432.006-.262,0,2.184-.007,4.8,4.5,3.832,6.005,3.8c5.1-.12,10.2-.34,15.279-.817C22.566,2.859,27,3.067,27,1.351,27-.468,22.08.076,21.045.076"
          transform="translate(26 11)"
          fill="#c1dee2"
        />
        <path
          id="Fill_263"
          data-name="Fill 263"
          d="M58.1.013C45.125.361,32.165.482,19.2,1.146c-4.851.248-9.7.433-14.553.433-.936,0-2.82-.362-3.7.227-2.39,1.6.331,2.969,1.889,3.055,10.654.591,21.462-.88,32.11-1.212,6.233-.194,12.468-.252,18.7-.569,1.7-.087,3.4-.187,5.1-.319.976-.076,3.556.132,4.345-.439C66.525-.169,59.265-.018,58.1.013"
          transform="translate(2 29)"
          fill="#89c5cc"
        />
        <path
          id="Fill_265"
          data-name="Fill 265"
          d="M.075,2.182A55.159,55.159,0,0,0,.385,11.9c.176,1.277.257,2.726,1.827,3.051,1.426.294,2.149-.734,2.444-1.874.721-2.784.087-6.791.078-9.683,0-1.43-.005-3.03-1.858-3.354a2.334,2.334,0,0,0-2.8,2.144"
          transform="translate(1 33)"
          fill="#89c5cc"
        />
        <path
          id="Fill_267"
          data-name="Fill 267"
          d="M73.59,0Q42.2,1.648,10.821,2.937C9.188,3.005-3.5,2.868.936,7.264c1.37,1.358,4.9.438,6.672.352,2.663-.13,5.326-.229,7.989-.364,10.557-.534,21.141-.678,31.689-1.277q8.708-.495,17.372-1.517c2.747-.319,6.156-.286,8.729-1.356,1.323-.55,2.752-3.231.2-3.1"
          transform="translate(0 43)"
          fill="#89c5cc"
        />
        <path
          id="Fill_269"
          data-name="Fill 269"
          d="M.962.9C-.617,2.5.2,5.647.314,7.621c.164,2.716.362,5.434.7,8.135.217,1.755.1,4.485,1.024,6a2.531,2.531,0,0,0,4.431-.136c.863-1.516.426-4.707.4-6.388-.031-2.324-.173-4.651-.309-6.971-.114-1.938.319-5.045-.731-6.7A3.109,3.109,0,0,0,.962.9"
          transform="translate(60 25)"
          fill="#89c5cc"
        />
        <path
          id="Fill_271"
          data-name="Fill 271"
          d="M5.543,10h0a6.1,6.1,0,0,1-1-.08A5.654,5.654,0,0,1,.563,7.042,4.657,4.657,0,0,1,.448,2.837,4.182,4.182,0,0,1,3.138.622,15.63,15.63,0,0,1,6.707.041h.015L7.014.015C7.129.005,7.247,0,7.363,0a4.636,4.636,0,0,1,3.342,1.508,4.675,4.675,0,0,1,1.286,3.523,4.816,4.816,0,0,1-2.253,3.7A7.658,7.658,0,0,1,5.543,10ZM3.967,4.017a.365.365,0,0,0-.248.113A1.091,1.091,0,0,0,3.7,5.53a1.98,1.98,0,0,0,1.786,1c1.215,0,2.95-1.013,3.062-2.049.037-.345-.118-.8-1.068-1.006H7.417a8.743,8.743,0,0,0-2.691.755.307.307,0,0,1-.131.03A.64.64,0,0,1,4.28,4.14.635.635,0,0,0,3.967,4.017Z"
          transform="translate(10 34)"
          fill="#89c5cc"
        />
        <path
          id="Fill_273"
          data-name="Fill 273"
          d="M10.432.2c-2.411.043-7.15-.746-9.25.555C-.9,2.041.03,4.334,1.926,4.882c1.253.362,3.3-.222,4.606-.281,1.716-.077,3.434-.094,5.152-.12,2.888-.043,5.776-.007,8.664-.007,2.95,0,5.951.089,8.9-.151,3.726-.3,3.626-3.394-.018-3.78A154.184,154.184,0,0,0,10.432.2"
          transform="translate(26 36)"
          fill="#89c5cc"
        />
        <path
          id="Fill_275"
          data-name="Fill 275"
          d="M0,.5c0,1.125,1.007,0,1,0S0-.625,0,.5"
          transform="translate(31 67)"
          fill="#89c5cc"
        />
        <path
          id="Fill_277"
          data-name="Fill 277"
          d="M0,1.875c.074,3.9.123,7.8.285,11.7.051,1.232-.291,3.638,1.156,4.266,1.611.7,2.111-1.044,2.3-2.225.587-3.741.012-8.013-.045-11.8C3.674,2.546,3.941.776,2.542.143A1.87,1.87,0,0,0,0,1.875"
          transform="translate(4 55)"
          fill="#89c5cc"
        />
        <path
          id="Fill_279"
          data-name="Fill 279"
          d="M66.623,14h0a1.826,1.826,0,0,1-1.493-1.072c-.56-.943-.318-2.6-.084-4.2a12.566,12.566,0,0,0,.216-2.542c-.013-.16-.024-.316-.034-.47-.077-1.129-.144-2.1-1.092-2.521a3.407,3.407,0,0,0-1.346-.211c-.555,0-1.146.06-1.718.118-.473.048-.919.093-1.274.1-2.975.014-6,.022-8.921.03-8.888.024-18.078.048-27.111.231-3.622.074-8.4.191-13.275.45a36.275,36.275,0,0,0-6.069.7,12.82,12.82,0,0,1-2.5.291C.89,4.9.266,4.694.068,4.286c-.183-.378.008-.929.568-1.639.99-1.252,4.406-1.494,6.447-1.639.373-.026.7-.049.964-.075,2.2-.209,4.466-.3,6.653-.4l.037,0L15.688.5C21,.266,26.4.184,31.619.1l1.937-.03C36.736.024,40,0,43.55,0,46.173,0,48.9.013,51.88.041c3.487.033,7.006.2,10.759.5a8.894,8.894,0,0,1,3.455.68A4.387,4.387,0,0,1,67.95,4.107c.654,2.222,1.348,5.078.916,7.465C68.658,12.72,67.685,14,66.623,14Z"
          transform="translate(3 54)"
          fill="#89c5cc"
        />
        <path
          id="Fill_281"
          data-name="Fill 281"
          d="M56.914,1.14c-13.1,1.527-26.567.627-39.734.681-3.2.013-6.407.039-9.605.195C5.884,2.1,1.88,1.63.722,2.938-1.718,5.7,2.677,6.1,4.282,5.983c3.244-.231,6.5-.337,9.749-.362,11.456-.089,22.977.448,34.424-.12A99.564,99.564,0,0,0,62.646,3.52c1.043-.2,8.181-1.566,4.111-3.2-2.406-.965-7.316.522-9.843.818"
          transform="translate(3 69)"
          fill="#89c5cc"
        />
        <path
          id="Fill_284"
          data-name="Fill 284"
          d="M5.718,10h0A5.959,5.959,0,0,1,1.067,8.005c-2.128-2.564-.7-5.168.981-7.376A1.708,1.708,0,0,1,3.511,0,7.537,7.537,0,0,1,5.237.285c.305.075.593.146.835.18.178.025.348.047.509.068a3.813,3.813,0,0,1,3.11,1.506,5.624,5.624,0,0,1,1.247,4.334A3.85,3.85,0,0,1,9.158,9.057,6.491,6.491,0,0,1,5.718,10Zm.424-5.944a1.858,1.858,0,0,0-1.357.384.426.426,0,0,0-.113.341c.046.352.564.726,1.433.726a3.751,3.751,0,0,0,1.026-.154,3.154,3.154,0,0,0-.615-1.284C6.389,4.06,6.263,4.056,6.143,4.056Z"
          transform="translate(15 60)"
          fill="#89c5cc"
        />
        <path
          id="Fill_286"
          data-name="Fill 286"
          d="M0,1.981C0,5.033,12.462,3.719,14.679,3.716c1.857,0,13.086,1.08,12.28-2.06C26.3-.925,15.236.358,12.88.354,10.844.351,0-1.127,0,1.981"
          transform="translate(35 63)"
          fill="#89c5cc"
        />
      </g>
      <g id="List_Copy" data-name="List Copy" transform="translate(166.999 132.392)" opacity="0.5">
        <path
          id="Fill_253-2"
          data-name="Fill 253"
          d="M.026,1.988c0,3.667-.2,7.41.52,11.033.218,1.1.389,3.2,1.62,3.767a1.9,1.9,0,0,0,2.741-1.262c.358-1.386-.428-3.461-.507-4.916-.109-1.988-.117-3.979-.118-5.969,0-1.437.5-3.75-1.3-4.489A2.165,2.165,0,0,0,.026,1.988"
          transform="translate(2 4)"
          fill="#c1dee2"
        />
        <path
          id="Fill_255-2"
          data-name="Fill 255"
          d="M58.485.025C46.057,1.255,33.451,1.51,20.978,2.151c-5.766.3-12.733-.082-18.165,2.118C1.8,4.678-1.808,6.951,1.125,7.877c.914.289,1.843-.682,2.593-1.007a16.82,16.82,0,0,1,4.03-.92C19.082,4.156,30.937,4.468,42.387,3.63c2.934-.215,5.869-.436,8.8-.72,2.092-.2,6.488-1.689,8.419-.929,3.3,1.3,3.221,9.736,3.287,12.638.034,1.5-1.444,6.888,1.494,6.342,2.351-.437,1.5-6.034,1.317-7.564C65.219,9.384,64.4-.565,58.485.025"
          transform="translate(2)"
          fill="#c1dee2"
        />
        <path
          id="Fill_257-2"
          data-name="Fill 257"
          d="M60.961.006C50.239,1.024,39.27.025,28.5.425c-5.643.21-11.28.545-16.924.741-2.441.085-4.884.089-7.326.107-1.168.008-6.484.753-3.2,3.188,1.456,1.078,6.1.21,7.766.189,2.509-.031,5.017-.141,7.525-.244,5.808-.239,11.613-.57,17.424-.735,5.559-.158,11.12-.192,16.679-.35a66.841,66.841,0,0,0,8.311-.593c.619-.1,2.628-.172,3.057-.9.406-.686.121-1.923-.861-1.824"
          transform="translate(4 21)"
          fill="#89c5cc"
        />
        <path
          id="Fill_259-2"
          data-name="Fill 259"
          d="M5.935,12h0A5.6,5.6,0,0,1,1.8,10.25,6.561,6.561,0,0,1,.029,6.327,5.807,5.807,0,0,1,1.01,2.421,6.172,6.172,0,0,1,5.029.026,1.385,1.385,0,0,1,5.291,0a1.744,1.744,0,0,1,.838.264A1.942,1.942,0,0,0,6.8.515a1.8,1.8,0,0,0,.215.012A4,4,0,0,0,7.646.462,4.13,4.13,0,0,1,8.278.4a1.669,1.669,0,0,1,.508.074,5.123,5.123,0,0,1,2.869,3.35,6.454,6.454,0,0,1-.467,5.218A5.679,5.679,0,0,1,7.441,11.83,7.011,7.011,0,0,1,5.935,12ZM7.607,4.035h0c-1.333.007-3.718.306-4.2,1.383-.225.5-.017,1.122.618,1.844a2.566,2.566,0,0,0,1.957.962A2.469,2.469,0,0,0,8.208,6.765a2.476,2.476,0,0,0-.6-2.729Z"
          transform="translate(9 8)"
          fill="#c1dee2"
        />
        <path
          id="Fill_261-2"
          data-name="Fill 261"
          d="M21.045.076C15.908.092,10.769.307,5.632.4,4.056.432.006-.262,0,2.184-.007,4.8,4.5,3.832,6.005,3.8c5.1-.12,10.2-.34,15.279-.817C22.566,2.859,27,3.067,27,1.351,27-.468,22.08.076,21.045.076"
          transform="translate(26 11)"
          fill="#c1dee2"
        />
        <path
          id="Fill_263-2"
          data-name="Fill 263"
          d="M58.1.013C45.125.361,32.165.482,19.2,1.146c-4.851.248-9.7.433-14.553.433-.936,0-2.82-.362-3.7.227-2.39,1.6.331,2.969,1.889,3.055,10.654.591,21.462-.88,32.11-1.212,6.233-.194,12.468-.252,18.7-.569,1.7-.087,3.4-.187,5.1-.319.976-.076,3.556.132,4.345-.439C66.525-.169,59.265-.018,58.1.013"
          transform="translate(2 29)"
          fill="#89c5cc"
        />
        <path
          id="Fill_265-2"
          data-name="Fill 265"
          d="M.075,2.182A55.159,55.159,0,0,0,.385,11.9c.176,1.277.257,2.726,1.827,3.051,1.426.294,2.149-.734,2.444-1.874.721-2.784.087-6.791.078-9.683,0-1.43-.005-3.03-1.858-3.354a2.334,2.334,0,0,0-2.8,2.144"
          transform="translate(1 33)"
          fill="#89c5cc"
        />
        <path
          id="Fill_267-2"
          data-name="Fill 267"
          d="M73.59,0Q42.2,1.648,10.821,2.937C9.188,3.005-3.5,2.868.936,7.264c1.37,1.358,4.9.438,6.672.352,2.663-.13,5.326-.229,7.989-.364,10.557-.534,21.141-.678,31.689-1.277q8.708-.495,17.372-1.517c2.747-.319,6.156-.286,8.729-1.356,1.323-.55,2.752-3.231.2-3.1"
          transform="translate(0 43)"
          fill="#89c5cc"
        />
        <path
          id="Fill_269-2"
          data-name="Fill 269"
          d="M.962.9C-.617,2.5.2,5.647.314,7.621c.164,2.716.362,5.434.7,8.135.217,1.755.1,4.485,1.024,6a2.531,2.531,0,0,0,4.431-.136c.863-1.516.426-4.707.4-6.388-.031-2.324-.173-4.651-.309-6.971-.114-1.938.319-5.045-.731-6.7A3.109,3.109,0,0,0,.962.9"
          transform="translate(60 25)"
          fill="#89c5cc"
        />
        <path
          id="Fill_271-2"
          data-name="Fill 271"
          d="M5.543,10h0a6.1,6.1,0,0,1-1-.08A5.654,5.654,0,0,1,.563,7.042,4.657,4.657,0,0,1,.448,2.837,4.182,4.182,0,0,1,3.138.622,15.63,15.63,0,0,1,6.707.041h.015L7.014.015C7.129.005,7.247,0,7.363,0a4.636,4.636,0,0,1,3.342,1.508,4.675,4.675,0,0,1,1.286,3.523,4.816,4.816,0,0,1-2.253,3.7A7.658,7.658,0,0,1,5.543,10ZM3.967,4.017a.365.365,0,0,0-.248.113A1.091,1.091,0,0,0,3.7,5.53a1.98,1.98,0,0,0,1.786,1c1.215,0,2.95-1.013,3.062-2.049.037-.345-.118-.8-1.068-1.006H7.417a8.743,8.743,0,0,0-2.691.755.307.307,0,0,1-.131.03A.64.64,0,0,1,4.28,4.14.635.635,0,0,0,3.967,4.017Z"
          transform="translate(10 34)"
          fill="#89c5cc"
        />
        <path
          id="Fill_273-2"
          data-name="Fill 273"
          d="M10.432.2c-2.411.043-7.15-.746-9.25.555C-.9,2.041.03,4.334,1.926,4.882c1.253.362,3.3-.222,4.606-.281,1.716-.077,3.434-.094,5.152-.12,2.888-.043,5.776-.007,8.664-.007,2.95,0,5.951.089,8.9-.151,3.726-.3,3.626-3.394-.018-3.78A154.184,154.184,0,0,0,10.432.2"
          transform="translate(26 36)"
          fill="#89c5cc"
        />
        <path
          id="Fill_275-2"
          data-name="Fill 275"
          d="M0,.5c0,1.125,1.007,0,1,0S0-.625,0,.5"
          transform="translate(31 67)"
          fill="#89c5cc"
        />
        <path
          id="Fill_277-2"
          data-name="Fill 277"
          d="M0,1.875c.074,3.9.123,7.8.285,11.7.051,1.232-.291,3.638,1.156,4.266,1.611.7,2.111-1.044,2.3-2.225.587-3.741.012-8.013-.045-11.8C3.674,2.546,3.941.776,2.542.143A1.87,1.87,0,0,0,0,1.875"
          transform="translate(4 55)"
          fill="#89c5cc"
        />
        <path
          id="Fill_279-2"
          data-name="Fill 279"
          d="M66.623,14h0a1.826,1.826,0,0,1-1.493-1.072c-.56-.943-.318-2.6-.084-4.2a12.566,12.566,0,0,0,.216-2.542c-.013-.16-.024-.316-.034-.47-.077-1.129-.144-2.1-1.092-2.521a3.407,3.407,0,0,0-1.346-.211c-.555,0-1.146.06-1.718.118-.473.048-.919.093-1.274.1-2.975.014-6,.022-8.921.03-8.888.024-18.078.048-27.111.231-3.622.074-8.4.191-13.275.45a36.275,36.275,0,0,0-6.069.7,12.82,12.82,0,0,1-2.5.291C.89,4.9.266,4.694.068,4.286c-.183-.378.008-.929.568-1.639.99-1.252,4.406-1.494,6.447-1.639.373-.026.7-.049.964-.075,2.2-.209,4.466-.3,6.653-.4l.037,0L15.688.5C21,.266,26.4.184,31.619.1l1.937-.03C36.736.024,40,0,43.55,0,46.173,0,48.9.013,51.88.041c3.487.033,7.006.2,10.759.5a8.894,8.894,0,0,1,3.455.68A4.387,4.387,0,0,1,67.95,4.107c.654,2.222,1.348,5.078.916,7.465C68.658,12.72,67.685,14,66.623,14Z"
          transform="translate(3 54)"
          fill="#89c5cc"
        />
        <path
          id="Fill_281-2"
          data-name="Fill 281"
          d="M56.914,1.14c-13.1,1.527-26.567.627-39.734.681-3.2.013-6.407.039-9.605.195C5.884,2.1,1.88,1.63.722,2.938-1.718,5.7,2.677,6.1,4.282,5.983c3.244-.231,6.5-.337,9.749-.362,11.456-.089,22.977.448,34.424-.12A99.564,99.564,0,0,0,62.646,3.52c1.043-.2,8.181-1.566,4.111-3.2-2.406-.965-7.316.522-9.843.818"
          transform="translate(3 69)"
          fill="#89c5cc"
        />
        <path
          id="Fill_284-2"
          data-name="Fill 284"
          d="M5.718,10h0A5.959,5.959,0,0,1,1.067,8.005c-2.128-2.564-.7-5.168.981-7.376A1.708,1.708,0,0,1,3.511,0,7.537,7.537,0,0,1,5.237.285c.305.075.593.146.835.18.178.025.348.047.509.068a3.813,3.813,0,0,1,3.11,1.506,5.624,5.624,0,0,1,1.247,4.334A3.85,3.85,0,0,1,9.158,9.057,6.491,6.491,0,0,1,5.718,10Zm.424-5.944a1.858,1.858,0,0,0-1.357.384.426.426,0,0,0-.113.341c.046.352.564.726,1.433.726a3.751,3.751,0,0,0,1.026-.154,3.154,3.154,0,0,0-.615-1.284C6.389,4.06,6.263,4.056,6.143,4.056Z"
          transform="translate(15 60)"
          fill="#89c5cc"
        />
        <path
          id="Fill_286-2"
          data-name="Fill 286"
          d="M0,1.981C0,5.033,12.462,3.719,14.679,3.716c1.857,0,13.086,1.08,12.28-2.06C26.3-.925,15.236.358,12.88.354,10.844.351,0-1.127,0,1.981"
          transform="translate(35 63)"
          fill="#89c5cc"
        />
      </g>
      <g id="Checkmark_" data-name="Checkmark!" transform="translate(-0.001 31.392)" opacity="0.5">
        <path
          id="Fill_292"
          data-name="Fill 292"
          d="M10.108,17a7.948,7.948,0,0,1-4.168-1.258,10.284,10.284,0,0,1-3.594-3.814A9.005,9.005,0,0,1,1.229,6.8a1.187,1.187,0,0,1-.615.228.5.5,0,0,1-.452-.27A2.038,2.038,0,0,1,.208,5.077,12.64,12.64,0,0,1,1.551,3.191c.093-.115.182-.225.268-.334A7.529,7.529,0,0,1,4.38.711,7.334,7.334,0,0,1,7.651,0h.133a13.558,13.558,0,0,1,5.235,1.04,8.224,8.224,0,0,1,4.023,3.4,8.073,8.073,0,0,1,.835,5.282,9.2,9.2,0,0,1-2.363,4.825A7.408,7.408,0,0,1,10.108,17ZM7.7,2.1a3.935,3.935,0,0,0-3.53,1.6A6.77,6.77,0,0,0,3.058,8.366c.4,3.043,3.425,6.724,6.726,6.91.1.005.194.008.291.008A5.759,5.759,0,0,0,14.452,13.1a7.45,7.45,0,0,0,1.789-4.677c.033-4.507-4.353-5.828-6.97-6.214A12.916,12.916,0,0,0,7.7,2.1Z"
          transform="translate(0 1)"
          fill="#89c5cc"
        />
        <path
          id="Fill_294"
          data-name="Fill 294"
          d="M11.468.245a22.471,22.471,0,0,0-4.34,4.311c-1.2,1.541-2,3.465-3.447,4.808C2.95,8.575,2.094,6.445.637,7.29-.572,7.991.206,9.2.8,9.989c1.521,2.045,3.21,2.868,5.16.847C7.727,9,8.733,6.554,10.389,4.614a25.386,25.386,0,0,0,2.428-2.636c.524-.852-.124-2.626-1.349-1.733"
          transform="translate(4)"
          fill="#89c5cc"
        />
      </g>
      <g id="Checkmark_Copy" data-name="Checkmark! Copy" transform="translate(140.999 134.392)" opacity="0.5">
        <path
          id="Fill_292-2"
          data-name="Fill 292"
          d="M10.108,17a7.948,7.948,0,0,1-4.168-1.258,10.284,10.284,0,0,1-3.594-3.814A9.005,9.005,0,0,1,1.229,6.8a1.187,1.187,0,0,1-.615.228.5.5,0,0,1-.452-.27A2.038,2.038,0,0,1,.208,5.077,12.64,12.64,0,0,1,1.551,3.191c.093-.115.182-.225.268-.334A7.529,7.529,0,0,1,4.38.711,7.334,7.334,0,0,1,7.651,0h.133a13.558,13.558,0,0,1,5.235,1.04,8.224,8.224,0,0,1,4.023,3.4,8.073,8.073,0,0,1,.835,5.282,9.2,9.2,0,0,1-2.363,4.825A7.408,7.408,0,0,1,10.108,17ZM7.7,2.1a3.935,3.935,0,0,0-3.53,1.6A6.77,6.77,0,0,0,3.058,8.366c.4,3.043,3.425,6.724,6.726,6.91.1.005.194.008.291.008A5.759,5.759,0,0,0,14.452,13.1a7.45,7.45,0,0,0,1.789-4.677c.033-4.507-4.353-5.828-6.97-6.214A12.916,12.916,0,0,0,7.7,2.1Z"
          transform="translate(0 1)"
          fill="#89c5cc"
        />
        <path
          id="Fill_294-2"
          data-name="Fill 294"
          d="M11.468.245a22.471,22.471,0,0,0-4.34,4.311c-1.2,1.541-2,3.465-3.447,4.808C2.95,8.575,2.094,6.445.637,7.29-.572,7.991.206,9.2.8,9.989c1.521,2.045,3.21,2.868,5.16.847C7.727,9,8.733,6.554,10.389,4.614a25.386,25.386,0,0,0,2.428-2.636c.524-.852-.124-2.626-1.349-1.733"
          transform="translate(4)"
          fill="#89c5cc"
        />
      </g>
      <g id="Checkmark_Copy_2" data-name="Checkmark! Copy 2" transform="translate(140.999 160.392)" opacity="0.5">
        <path
          id="Fill_292-3"
          data-name="Fill 292"
          d="M10.108,17a7.948,7.948,0,0,1-4.168-1.258,10.284,10.284,0,0,1-3.594-3.814A9.005,9.005,0,0,1,1.229,6.8a1.187,1.187,0,0,1-.615.228.5.5,0,0,1-.452-.27A2.038,2.038,0,0,1,.208,5.077,12.64,12.64,0,0,1,1.551,3.191c.093-.115.182-.225.268-.334A7.529,7.529,0,0,1,4.38.711,7.334,7.334,0,0,1,7.651,0h.133a13.558,13.558,0,0,1,5.235,1.04,8.224,8.224,0,0,1,4.023,3.4,8.073,8.073,0,0,1,.835,5.282,9.2,9.2,0,0,1-2.363,4.825A7.408,7.408,0,0,1,10.108,17ZM7.7,2.1a3.935,3.935,0,0,0-3.53,1.6A6.77,6.77,0,0,0,3.058,8.366c.4,3.043,3.425,6.724,6.726,6.91.1.005.194.008.291.008A5.759,5.759,0,0,0,14.452,13.1a7.45,7.45,0,0,0,1.789-4.677c.033-4.507-4.353-5.828-6.97-6.214A12.916,12.916,0,0,0,7.7,2.1Z"
          transform="translate(0 1)"
          fill="#89c5cc"
        />
        <path
          id="Fill_294-3"
          data-name="Fill 294"
          d="M11.468.245a22.471,22.471,0,0,0-4.34,4.311c-1.2,1.541-2,3.465-3.447,4.808C2.95,8.575,2.094,6.445.637,7.29-.572,7.991.206,9.2.8,9.989c1.521,2.045,3.21,2.868,5.16.847C7.727,9,8.733,6.554,10.389,4.614a25.386,25.386,0,0,0,2.428-2.636c.524-.852-.124-2.626-1.349-1.733"
          transform="translate(4)"
          fill="#89c5cc"
        />
      </g>
      <g id="Group_37" data-name="Group 37" transform="translate(79.999 -0.608)">
        <g id="Group_3" data-name="Group 3" transform="translate(13.158)">
          <path
            id="Clip_2-2"
            data-name="Clip 2"
            d="M0,0H57.471V70.8H0Z"
            transform="translate(0.058 0.608)"
            fill="none"
          />
          <g id="Group_3-2" data-name="Group 3" clipPath="url(#clip-path)">
            <path
              id="Fill_1"
              data-name="Fill 1"
              d="M15.013,37.574C12.47,43.585,3.742,44.679.864,50.535c-1.935,3.933-.427,8.919,2.6,12.074s7.259,4.8,11.453,5.966c9.346,2.594,19.422,3.189,28.686.312a24.182,24.182,0,0,0,9.149-4.873,13.748,13.748,0,0,0,4.7-9.1,9.614,9.614,0,0,0-4.471-8.9c-1.837-1.037-4.086-1.354-5.652-2.773-2.319-2.1-2.281-5.706-2.194-8.853a113.168,113.168,0,0,0-.885-17.561c-.577-4.489-1.541-9.192-4.548-12.55A13.751,13.751,0,0,0,26.264.425,17.16,17.16,0,0,0,15.392,9.678a22.968,22.968,0,0,0-1.541,14.475c.917,4.482,2.944,9.213,1.163,13.421"
              transform="translate(0.058 0.608)"
              fill="#dc5955"
            />
          </g>
        </g>
        <path
          id="Fill_4"
          data-name="Fill 4"
          d="M6.93,39.109,6.874,50.725s-4.72,16.2-5.323,19.9c-.488,3-1.271,29.794-1.55,39.865-.065,2.361,4.986,1.256,4.986,1.256l.307-1.256L30.211,8.385l.558,102.108s5.191,2.472,5.753-.008C38.845,100.23,44.841,73.6,45.145,70.62c.377-3.7-1.32-19.9-1.32-19.9l2.6-11.618L35.151,0Z"
          transform="translate(11.053 104.238)"
          fill="#e48868"
        />
        <path
          id="Fill_6"
          data-name="Fill 6"
          d="M8.036,0c-.066,2.272-.1,3.664-.1,3.664L0,8.871H12.318L12.96,1.21l.3-1.21Z"
          transform="translate(3.316 215.129)"
          fill="#393762"
        />
        <path
          id="Fill_8"
          data-name="Fill 8"
          d="M5.493,0H0L.047,8.871H11.053L4.627,3.855S4.958,2.381,5.493,0"
          transform="translate(42 215.129)"
          fill="#393762"
        />
        <g id="Group_12" data-name="Group 12" transform="translate(0 37.703)">
          <path
            id="Clip_11-2"
            data-name="Clip 11"
            d="M0,0H25.969V53.847H0Z"
            transform="translate(0 0.58)"
            fill="none"
          />
          <g id="Group_12-2" data-name="Group 12" clipPath="url(#clip-path-2)">
            <path
              id="Fill_10"
              data-name="Fill 10"
              d="M22.787,0C18.594,2.57.826,22.363.022,24.766S20.9,53.847,20.9,53.847l3.117-5.387L12.006,27.834,25.97,11.826Z"
              transform="translate(0 0.58)"
              fill="#e48868"
            />
          </g>
        </g>
        <path
          id="Fill_13"
          data-name="Fill 13"
          d="M4.109,0c4.1,2.581,20.5,21.607,21.291,24.019S4.966,53.228,4.966,53.228l-3.05-5.412L13.668,27.1,0,11.023Z"
          transform="translate(58.579 38.812)"
          fill="#e48868"
        />
        <path
          id="Fill_15"
          data-name="Fill 15"
          d="M.582,91.363l-.1,21.746H40.508l5.2-23.029c3.783-26.044-8.783-41.761-8.783-41.761l4.26-18.649c3.834-3.6,4.046-9.325,4.046-11.869V8.265S28.2,0,24.362,0,4.768,7.416,4.768,7.416V17.59C3.489,25.007,9.24,29.881,9.24,29.881l3.407,18.438S-3.2,68.953.582,91.363"
          transform="translate(17.684 31.05)"
          fill="#393762"
        />
        <path
          id="Fill_17"
          data-name="Fill 17"
          d="M0,0V9.6s4.608,8.219,8.842.193L8.472,0Z"
          transform="translate(38.684 23.287)"
          fill="#e48868"
        />
        <path
          id="Fill_19"
          data-name="Fill 19"
          d="M7.952.164C.143,1.222-1.107,10.546.77,17.914c1.4,5.5,4.589,11.049,9.6,10.916S18.4,21.617,19.3,17.277C20.74,10.292,20.472-1.531,7.952.164"
          transform="translate(32.053 1.109)"
          fill="#e48868"
        />
        <path
          id="Fill_21"
          data-name="Fill 21"
          d="M3.844,2.359S3.524-.853.953.219,1.518,8.528,4.38,7.7s-.536-5.337-.536-5.337"
          transform="translate(29.842 14.416)"
          fill="#e48868"
        />
        <path
          id="Fill_23"
          data-name="Fill 23"
          d="M1.488,2.455S1.586-.717,4.374.15,4.4,8.338,1.327,7.726s.161-5.271.161-5.271"
          transform="translate(48.632 14.416)"
          fill="#e48868"
        />
        <path
          id="Fill_25"
          data-name="Fill 25"
          d="M3.68,2.218A6.114,6.114,0,0,1,.2,1.129.51.51,0,0,1,.054.493.366.366,0,0,1,.6.33,5.524,5.524,0,0,0,7.116.08.363.363,0,0,1,7.668.2a.512.512,0,0,1-.1.643A6.223,6.223,0,0,1,3.68,2.218"
          transform="translate(38.684 28.832)"
          fill="#ba745d"
        />
        <path
          id="Fill_27"
          data-name="Fill 27"
          d="M17.953,5.808A25.626,25.626,0,0,1,14.544,19.9c-2.589,4.17-6.984,7.374-11.893,7.824C.913,23.491-.386,18.842.1,14.3A16.87,16.87,0,0,1,6.423,2.537C10.108-.2,15.4-.906,19.412,1.324c3.359,1.868,5.455,5.473,6.354,9.194a29.484,29.484,0,0,1,.665,9.605c-5.621-2.967-9.264-8.037-8.479-14.315"
          transform="translate(28.737 1.109)"
          fill="#dc5955"
        />
        <path
          id="Fill_29"
          data-name="Fill 29"
          d="M5.656,3.065S4.355-.069,3.944,0A26.13,26.13,0,0,0,.038,4.3c-.2.487.481,1.457.481,1.457l1.3-.693L2.369,7H3.655S2.3,7.97,2.369,8.663a1.109,1.109,0,0,0,.754.9L5.149,8.316l1.5,2.773,2.2-2.426Z"
          transform="translate(54.158 83.168)"
          fill="#e48868"
        />
        <path
          id="Fill_31"
          data-name="Fill 31"
          d="M3.269,5.545a.37.37,0,0,1-.11-.016,1.366,1.366,0,0,1-.965-1.145,1.626,1.626,0,0,1,.552-1.17H2.571a.38.38,0,0,1-.365-.276L1.828,1.62l-.792.414A.379.379,0,0,1,.55,1.921C.371,1.673-.19.82.066.227A.374.374,0,0,1,.56.031a.38.38,0,0,1,.2.5A1.252,1.252,0,0,0,.993,1.2L1.886.736a.381.381,0,0,1,.543.23l.429,1.491h.906a.381.381,0,0,1,.361.263.376.376,0,0,1-.143.424c-.506.357-1.056.932-1.033,1.165a.575.575,0,0,0,.292.432L4.948,3.708a.387.387,0,0,1,.523.127.379.379,0,0,1-.128.52L3.466,5.491a.389.389,0,0,1-.2.054"
          transform="translate(54.158 87.604)"
          fill="#ba745d"
        />
        <path
          id="Fill_33"
          data-name="Fill 33"
          d="M3.186,3.065S4.487-.069,4.9,0A26.184,26.184,0,0,1,8.8,4.3c.2.487-.481,1.457-.481,1.457l-1.3-.693L6.474,7H5.187s1.356.971,1.287,1.664a1.109,1.109,0,0,1-.754.9L3.693,8.316,2.2,11.089,0,8.663Z"
          transform="translate(21 83.168)"
          fill="#e48868"
        />
        <path
          id="Fill_35"
          data-name="Fill 35"
          d="M2.258,5.545a.389.389,0,0,1-.2-.054L.184,4.355a.377.377,0,0,1-.128-.52.384.384,0,0,1,.523-.127L2.284,4.74a.583.583,0,0,0,.294-.432C2.6,4.075,2.051,3.5,1.545,3.143A.379.379,0,0,1,1.4,2.718a.383.383,0,0,1,.363-.263h.906L3.1.964A.38.38,0,0,1,3.313.721a.386.386,0,0,1,.328.012l.893.468A1.277,1.277,0,0,0,4.761.525a.38.38,0,0,1,.7-.3c.256.591-.3,1.446-.483,1.695a.388.388,0,0,1-.487.115L3.7,1.619,3.322,2.937a.38.38,0,0,1-.365.276H2.782a1.637,1.637,0,0,1,.552,1.17,1.371,1.371,0,0,1-.966,1.145.382.382,0,0,1-.11.016"
          transform="translate(24.316 87.604)"
          fill="#ba745d"
        />
      </g>
    </svg>
  );
};
