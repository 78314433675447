import { IXFactorAnswer, IXFactorQuestion, QuestionType } from '../../services/generated/ApiClientGenerated';
import React, { FC, useMemo } from 'react';
import { XFactorQuestion } from './questions/xFactorQuestion';

export type XFactorQuestionFormProps = {
  questions: IXFactorQuestion[];
  value: IXFactorAnswer[];
  onChange: (value: IXFactorAnswer[]) => any;
};

export const XFactorQuestionForm: FC<XFactorQuestionFormProps> = ({ questions, value: answers, onChange }) => {
  const getAnswerValue = useMemo(
    () => (questionId: string, questionType?: QuestionType) => {
      const newAnswers = answers?.filter((a) => a.questionId == questionId);
      switch (questionType) {
        case QuestionType.Ranking:
          return newAnswers?.filter((a) => a.optionId)?.map((a) => a.optionId!) ?? [];
        default:
          if (!Array.isArray(newAnswers)) return '';
          return newAnswers[0]?.value?.toString() ?? '';
      }
    },
    [answers],
  );
  const createAnswerChangeHandler = (questionId: string, questionType?: QuestionType) => (value: string | string[]) => {
    if (questionType !== QuestionType.Ranking && typeof value === 'string') {
      onChange(
        [
          ...(answers?.filter((existingXFactor) => existingXFactor.questionId != questionId) || []),
          { questionId, value: parseFloat(value) },
        ].sort((a, b) => (a?.questionId && b?.questionId && a.questionId?.localeCompare(b.questionId)) || 0),
      );
    } else if (questionType === QuestionType.Ranking && Array.isArray(value)) {
      onChange(
        [
          ...(answers?.filter((existingXFactor) => existingXFactor.questionId != questionId) || []),
          ...value.map((v) => ({ questionId, optionId: v, value: 1 })),
        ].sort((a, b) => (a?.questionId && b?.questionId && a.questionId?.localeCompare(b.questionId)) || 0),
      );
    }
  };

  return (
    <>
      {questions
        .filter((x) => x.id)
        .map((q) => (
          <XFactorQuestion
            key={q.id}
            question={q}
            value={getAnswerValue(q.id!, q.type)}
            onChange={createAnswerChangeHandler(q.id!, q.type)}
          />
        ))}
    </>
  );
};
