import { all, put, race, select, take, takeLatest } from 'redux-saga/effects';
import {
  createCompany,
  deleteCompany,
  getCompanies,
  getCompaniesSuccess,
  getCompany,
  getCompanyUsers,
  getCompanyUsersSuccess,
  removeUserRoleAction,
  setCompanyPage,
  setCompanyPageSize,
  setCompanyUserPage,
  setCompanyUserPageSize,
  updateCompany,
  updateUserRoleAction,
} from '../redux/managementSlice';
import { apiCall, apiClient } from '../services/apiClient';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  CreateCompanyRequest,
  ListCompaniesResponse,
  ListCompanyUsersResponse,
  UpdateCompanyRequest,
} from '../services/generated/ApiClientGenerated';
import { cancelConfirmAction, okConfirmAction, showConfirmAction, showToastAction } from '../redux/globalSlice';
import { RootState } from '../store';

function* createCompanySaga(action: PayloadAction<{ body?: CreateCompanyRequest }>) {
  try {
    yield apiCall(apiClient.companies_CreateCompany, action.payload.body);
    yield put(getCompanies());
    yield put(
      showToastAction({
        message: 'Successfully added company!',
        severity: 'success',
      }),
    );
  } catch {
    yield put(
      showToastAction({
        message: 'Could not create company',
        severity: 'warning',
      }),
    );
  }
}

function* deleteCompanySaga(action: PayloadAction<string>) {
  try {
    yield put(
      showConfirmAction({
        title: 'Are you sure?',
        body: 'This action can not be undone.',
        okText: 'Delete company',
        cancelText: 'Cancel',
      }),
    );
    const { yes } = yield race({ yes: take(okConfirmAction.type), no: take(cancelConfirmAction.type) });
    if (yes) {
      yield apiCall(apiClient.companies_DeleteCompany, action.payload);
      yield put(getCompanies());
      yield put(
        showToastAction({
          message: 'Successfully deleted company!',
          severity: 'success',
        }),
      );
    }
  } catch {
    yield put(
      showToastAction({
        message: 'Could not delete company',
        severity: 'warning',
      }),
    );
  }
}

function* getCompaniesSaga() {
  try {
    const { page, pageSize } = yield select((r: RootState) => r.management.companies);
    const response: ListCompaniesResponse = yield apiCall(apiClient.companies_ListCompanies, pageSize, page);
    yield put(getCompaniesSuccess(response.toJSON()));
  } catch {
    yield put(
      showToastAction({
        message: 'Could not load company information',
        severity: 'warning',
      }),
    );
  }
}

function* getCompanySaga(action: PayloadAction<string>) {
  try {
    yield apiCall(apiClient.companies_GetCompany, action.payload);
  } catch {
    yield put(
      showToastAction({
        message: 'Could not load company information',
        severity: 'warning',
      }),
    );
  }
}

function* getCompanyUsersSaga() {
  try {
    const companyId: string = yield select((r: RootState) => r.user.companyId);
    const { currentPage, pageSize, sortBy, sortDirection } = yield select((r: RootState) => r.management.users);
    const response: ListCompanyUsersResponse = yield apiCall(
      apiClient.companies_GetUsers,
      companyId,
      currentPage,
      pageSize,
      sortBy,
      sortDirection,
    );
    yield put(getCompanyUsersSuccess(response));
  } catch {
    yield put(
      showToastAction({
        message: 'Could not load user information',
        severity: 'warning',
      }),
    );
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* setCompanyPageSaga(action: PayloadAction<number>) {
  yield put(getCompanies());
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* setCompanyPageSizeSaga(action: PayloadAction<number>) {
  yield put(getCompanies());
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* setCompanyUserPageSaga(action: PayloadAction<number>) {
  yield put(getCompanyUsers());
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* setCompanyUserPageSizeSaga(action: PayloadAction<number>) {
  yield put(getCompanyUsers());
}

function* updateCompanySaga(action: PayloadAction<{ id: string; name: string; domainName: string }>) {
  try {
    const { id, name, domainName } = action.payload;
    yield put(
      showToastAction({
        message: 'Successfully updated company',
        severity: 'success',
      }),
    );
    yield apiCall(
      apiClient.companies_UpdateCompany,
      id,
      new UpdateCompanyRequest({
        name,
        emailDomain: domainName,
      }),
    );
    yield put(getCompanies());
  } catch {
    yield put(
      showToastAction({
        message: 'Could not update company information',
        severity: 'warning',
      }),
    );
  }
}

function* removeUserRoleSaga(action: PayloadAction<{ userEmail?: string; roleName?: string }>) {
  try {
    yield apiCall(apiClient.security_RemoveUserFromRole, action.payload.userEmail, action.payload.roleName);
    yield put(getCompanyUsers());
    yield put(
      showToastAction({
        message: 'Successfully updated user role!',
        severity: 'success',
      }),
    );
  } catch {
    yield put(
      showToastAction({
        message: 'Could not update user roles',
        severity: 'warning',
      }),
    );
  }
}

function* updateUserRoleSaga(action: PayloadAction<{ userEmail?: string; roleName?: string }>) {
  try {
    yield apiCall(apiClient.security_AddUserToRole, action.payload.userEmail, action.payload.roleName);
    yield put(getCompanyUsers());
    yield put(
      showToastAction({
        message: 'Successfully updated user role!',
        severity: 'success',
      }),
    );
  } catch {
    yield put(
      showToastAction({
        message: 'Could not update user roles',
        severity: 'warning',
      }),
    );
  }
}

export function* managementSaga() {
  yield all([
    takeLatest(createCompany.type, createCompanySaga),
    takeLatest(deleteCompany.type, deleteCompanySaga),
    takeLatest(getCompanies.type, getCompaniesSaga),
    takeLatest(getCompany.type, getCompanySaga),
    takeLatest(getCompanyUsers.type, getCompanyUsersSaga),
    takeLatest(setCompanyPage.type, setCompanyPageSaga),
    takeLatest(setCompanyPageSize.type, setCompanyPageSizeSaga),
    takeLatest(setCompanyUserPage.type, setCompanyUserPageSaga),
    takeLatest(setCompanyUserPageSize.type, setCompanyUserPageSizeSaga),
    takeLatest(updateCompany.type, updateCompanySaga),
    takeLatest(updateUserRoleAction.type, updateUserRoleSaga),
    takeLatest(removeUserRoleAction.type, removeUserRoleSaga),
  ]);
}
