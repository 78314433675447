import { IListCompaniesCompanyItem } from '../../services/generated/ApiClientGenerated';
import React, { FC, FormEvent, useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, Grid, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { updateCompany } from '../../redux/managementSlice';
import { StyledDialogTitleWithX } from '../shared/dialog';
import { ContainedButton } from '../shared/button';

export type EditCompanyModalProps = {
  company?: IListCompaniesCompanyItem;
  onClose: () => any;
};

export const EditCompanyModal: FC<EditCompanyModalProps> = ({ company, onClose }) => {
  const dispatch = useDispatch();
  const [editCompanyDetails, setEditCompanyDetails] = useState(company);

  useEffect(() => {
    setEditCompanyDetails(company);
  }, [company]);

  const handleSave = (e: FormEvent) => {
    if (editCompanyDetails?.id)
      dispatch(
        updateCompany({
          id: editCompanyDetails.id,
          name: editCompanyDetails.name ?? '',
          domainName: editCompanyDetails.domainName ?? '',
        }),
      );
    onClose();
    e.preventDefault();
  };

  return (
    <Dialog open={!!company} onClose={onClose} fullWidth maxWidth="md" aria-labelledby="Edit Company">
      <StyledDialogTitleWithX onClose={onClose}>Edit Company</StyledDialogTitleWithX>
      <DialogContent>
        <form onSubmit={handleSave}>
          <Grid container pt={1}>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                name={'companyName'}
                value={editCompanyDetails?.name}
                label="Company Name"
                placeholder="Company Name"
                type="text"
                fullWidth
                variant="outlined"
                onChange={(e) =>
                  setEditCompanyDetails({
                    ...editCompanyDetails,
                    name: e.currentTarget.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                name={'companyEmailDomain'}
                value={editCompanyDetails?.domainName}
                label="Company Email Domain Name"
                placeholder="example.com"
                type="text"
                fullWidth
                variant="outlined"
                onChange={(e) =>
                  setEditCompanyDetails({
                    ...editCompanyDetails,
                    domainName: e.currentTarget.value,
                  })
                }
              />
            </Grid>
          </Grid>
          <DialogActions>
            <ContainedButton type="submit" text="Save" />
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};
