import React, { FC, useEffect, useState } from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import { StyledPaper } from '../../../shared/paper';
import { ISkillRequirement } from '../../../../services/generated/ApiClientGenerated';
import { StyledModalPaperContainer } from '../../../shared/grid';
import { ContainedButton } from '../../../shared/button';
import { TechSkillRow } from './techSkillRow';

interface JobReqTechnicalSkillsType {
  onChange: (value: any) => void;
}

interface SkillRequirementWithId extends ISkillRequirement {
  id: number;
}

export const JobReqTechnicalSkills: FC<JobReqTechnicalSkillsType> = ({ onChange }) => {
  const [currentCategory, setCurrentCategory] = useState<string>();
  const [skillRequirementArray, setSkillRequirementArray] = useState<SkillRequirementWithId[]>([
    {
      id: 0,
      name: '',
      category: '',
      weight: 0,
    },
  ]);

  useEffect(() => {
    onChange({
      ['requirements']: skillRequirementArray,
    });
  }, [skillRequirementArray]);

  function handleChange<K extends keyof ISkillRequirement>(key: K, newValue: ISkillRequirement[K], index: number) {
    const rows = [...skillRequirementArray];
    if (typeof newValue == 'string') {
      if (key == 'category') {
        setCurrentCategory(newValue);
        rows[index].category = newValue;
      } else if (key == 'name') {
        rows[index].name = newValue;
      }
    }
    setSkillRequirementArray(rows);
  }

  const addRow = () => {
    const skills = [
      ...skillRequirementArray,
      { id: Math.max(...skillRequirementArray.map((s) => s.id)) + 1, category: '', name: '', weight: 0 },
    ];
    setSkillRequirementArray(skills);
  };

  const removeSkill = (
    itemToRemove: string | React.MouseEvent<HTMLButtonElement, MouseEvent>,
    indexToRemove: number,
  ) => {
    const newArr = skillRequirementArray.filter((item, itemIndex) => itemIndex !== indexToRemove);
    setSkillRequirementArray(newArr);
  };

  const handleWeightChange = (value: { target: { name: any; value: any } }, index: number) => {
    const rows = [...skillRequirementArray];
    if (value.target.value == 'Beginner') {
      rows[index].weight = 0;
      setSkillRequirementArray(rows);
    }
    if (value.target.value == 'Intermediate') {
      rows[index].weight = 0.5;
      setSkillRequirementArray(rows);
    }
    if (value.target.value == 'Advanced') {
      rows[index].weight = 1;
      setSkillRequirementArray(rows);
    }
  };

  return (
    <StyledModalPaperContainer width={'100%'} item>
      <StyledPaper sx={{ height: '100%' }}>
        <Grid container item px={2}>
          <Typography variant={'h5'}>Technical Skills</Typography>
        </Grid>
        <Divider />
        <Grid container item p={2}>
          <Typography>
            Please select the technical skills and the MINIMUM level of expertise required for each candidate to succeed
            in this role. You will surface anyone in the company who has an interest in your department and possesses
            the minimum skill level (and above) for all the skills listed, and the X-Factors you identify.
          </Typography>
        </Grid>

        {skillRequirementArray?.map((item, i) => (
          <TechSkillRow
            key={item.id}
            onChangeCategory={(value) => handleChange('category', value, i)}
            onChangeSkill={(value) => handleChange('name', value, i)}
            categoryOption={currentCategory}
            onWeightChange={(value) => handleWeightChange(value, i)}
            onClickDelete={(value) => removeSkill(value, i)}
          />
        ))}

        <Grid container item justifyContent={'flex-end'} px={2}>
          <ContainedButton text="Add skill" onClick={addRow} />
        </Grid>
      </StyledPaper>
    </StyledModalPaperContainer>
  );
};
