import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  FileParameter,
  IAddJobHistoryRequest,
  IGetProfileResponse,
  IJobHistory,
  ISkill,
  IXFactorAnswer,
} from '../services/generated/ApiClientGenerated';

export type ProfileJobHistory = {
  startDate?: string;
  endDate?: string;
} & Omit<IJobHistory, 'startDate' | 'endDate'>;

export type ProfileDetails = {
  jobHistories?: ProfileJobHistory[];
  xFactors?: IXFactorAnswer[];
} & Omit<IGetProfileResponse, 'jobHistories' | 'xFactors'>;

export type SerializableJobHistory = {
  startDate?: string;
  endDate?: string;
} & Omit<IJobHistory, 'startDate' | 'endDate'>;

export interface TechSkillEntryState {
  key: string;
  type: string;
  level: number;
}

export type ProfileState = {
  jobHistoryEditDialog: {
    isVisible: boolean;
  };
  profileDialog: {
    isVisible: boolean;
  };
  jobHistoryToEdit: ProfileJobHistory;
  profileDetails: ProfileDetails;
  loading: boolean;
  loaded: boolean;
};

const initialState = {
  jobHistoryEditDialog: {
    isVisible: false,
  },
  profileDialog: {
    isVisible: false,
  },
  jobHistoryToEdit: {},
  profileDetails: {},
  loading: false,
  loaded: false,
} as ProfileState;

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    addJobHistoryAction: (state, action: PayloadAction<IAddJobHistoryRequest>) => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    deleteJobHistoryAction: (state, action: PayloadAction) => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    deleteTechSkillsAction: (state, action: PayloadAction<string>) => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getProfile: (state) => {
      state.loading = true;
      state.loaded = false;
    },
    hideJobHistoryAction: (state) => {
      state.jobHistoryEditDialog.isVisible = false;
    },
    hideProfileDialogAction: (state) => {
      state.profileDialog.isVisible = false;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateJobHistoryAction: (state, action: PayloadAction<ProfileJobHistory>) => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setProfile: (state, action: PayloadAction<ProfileDetails>) => {
      state.profileDetails = action.payload;
      state.loading = false;
      state.loaded = true;
    },
    showJobHistoryDialog: (state, action: PayloadAction<ProfileJobHistory>) => {
      state.jobHistoryEditDialog.isVisible = true;
      state.jobHistoryToEdit = action.payload;
    },
    showProfileDialogAction: (state) => {
      state.profileDialog.isVisible = true;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateAvatarAction: (state, action: PayloadAction<FileParameter>) => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateProfileDetails: (state, action: PayloadAction<IGetProfileResponse>) => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateTechSkillsAction: (state, action: PayloadAction<ISkill[]>) => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateXFactors: (state, action: PayloadAction<IXFactorAnswer[]>) => {},
    successUpdateXFactors: (state, action: PayloadAction<IXFactorAnswer[]>) => {
      state.profileDetails.xFactors = [
        ...(state.profileDetails.xFactors?.filter(
          (existingXFactor) =>
            !action.payload.map((updatedXFactor) => updatedXFactor.questionId).includes(existingXFactor.questionId),
        ) ?? []),
        ...action.payload,
      ].sort((a, b) => (a?.questionId && b?.questionId && a.questionId?.localeCompare(b.questionId)) || 0);
    },
  },
});

export const {
  addJobHistoryAction,
  deleteJobHistoryAction,
  deleteTechSkillsAction,
  getProfile,
  hideJobHistoryAction,
  hideProfileDialogAction,
  showJobHistoryDialog,
  showProfileDialogAction,
  updateJobHistoryAction,
  updateAvatarAction,
  updateProfileDetails,
  updateTechSkillsAction,
  updateXFactors,
  successUpdateXFactors,
} = profileSlice.actions;
