import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IAddSkillRequest,
  IGetSkillCategoriesResponse,
  IGetSkillsAvailableSkillResponse,
  SkillOrigin,
} from '../services/generated/ApiClientGenerated';

export type SkillInfo = {
  id?: string;
  companyId?: string | undefined;
  origin?: SkillOrigin;
  category?: string | undefined;
  name?: string | undefined;
};

export type SkillsSliceState = {
  categories: string[];
  skills: SkillInfo[];
};

const initialState = {
  categories: [],
  skills: [],
} as SkillsSliceState;

export const skillsSlice = createSlice({
  name: 'skills',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    addSelectedSkillsAction: (state, action: PayloadAction<{ [x: string]: unknown }>) => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    addSkillsAction: (state, action: PayloadAction<IAddSkillRequest>) => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    deleteSelectedSkillAction: (state, action: PayloadAction<string>) => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getAvailableCategoriesAction: (state, action: PayloadAction<{ prefix: string | undefined }>) => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getAvailableSkillsAction: (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{ type: string | undefined; prefix: string | undefined; includeArchived: boolean }>,
    ) => {},
    setCategoriesAction: (state, action: PayloadAction<IGetSkillCategoriesResponse>) => {
      state.categories = action.payload.categories || [];
    },
    setSkillTypesAction: (state, action: PayloadAction<IGetSkillsAvailableSkillResponse[]>) => {
      state.skills = action.payload;
    },
  },
});

export const {
  addSelectedSkillsAction,
  addSkillsAction,
  deleteSelectedSkillAction,
  getAvailableCategoriesAction,
  getAvailableSkillsAction,
  setCategoriesAction,
  setSkillTypesAction,
} = skillsSlice.actions;
