import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from '@redux-saga/core';
import { createRootReducer } from './reducer';
import { rootSagas } from './sagas';

const sagaMiddleware = createSagaMiddleware();
export const store = configureStore({
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
  reducer: createRootReducer(),
  devTools: process.env.NODE_ENV !== 'production',
});
sagaMiddleware.run(rootSagas);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type RootAction = ReturnType<AppDispatch>;
