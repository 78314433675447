import { all, put, takeLatest } from 'redux-saga/effects';
import { hideConfettiAction, showConfettiAction } from '../redux/globalSlice';

function* showConfettiSaga() {
  yield put(hideConfettiAction());
}

export function* globalSaga() {
  yield all([takeLatest(showConfettiAction.type, showConfettiSaga)]);
}
