export const concatenateStringAndLowercaseFirstLetter = (string: string | undefined): string => {
  if (string) {
    const newString = string.replace(/\s+/g, '');
    return newString.charAt(0).toLowerCase() + newString.slice(1);
  } else return '';
};

export const concatenateStringAndUppercaseFirstLetter = (string: string | undefined): string => {
  if (string) {
    const newString = string.replace(/\s+/g, '');
    return newString.charAt(0).toUpperCase() + newString.slice(1);
  } else return '';
};
