import { Divider, Grid, TextField, Typography } from '@mui/material';
import React, { FC } from 'react';
import { StyledPaper } from '../../../shared/paper';
import { StyledModalPaperContainer } from '../../../shared/grid';
import { Contact, IAddJobRoleRequest, IContact } from '../../../../services/generated/ApiClientGenerated';

export type JobReqContactInfoType = {
  hiringManager: IContact;
  onChange: (updatedInfo: Partial<IAddJobRoleRequest>) => any;
};

export const JobReqContactInformation: FC<JobReqContactInfoType> = ({ hiringManager, onChange }) => {
  function handleChange<K extends keyof Contact>(key: K, newValue: Contact[K]) {
    onChange({
      hiringManager: {
        ...hiringManager,
        [key]: newValue,
      } as Contact,
    });
  }

  return (
    <StyledModalPaperContainer item width={'100%'}>
      <StyledPaper>
        <Grid container px={2}>
          <Typography variant={'h5'}>Hiring Manager's Contact Information</Typography>
        </Grid>
        <Divider />
        <Grid container p={2} spacing={1}>
          <Grid item xs={6}>
            <TextField
              name={'firstName'}
              label="First Name"
              type="text"
              fullWidth
              variant="outlined"
              required
              value={hiringManager.firstName}
              onChange={(e) => handleChange('firstName', e.currentTarget.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name={'lastName'}
              label="Last Name"
              type="text"
              fullWidth
              variant="outlined"
              required
              value={hiringManager.lastName}
              onChange={(e) => handleChange('lastName', e.currentTarget.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name={'number'}
              margin="dense"
              label="Number"
              type="tel"
              fullWidth
              variant="outlined"
              required
              value={hiringManager.number}
              onChange={(e) => handleChange('number', e.currentTarget.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name={'email'}
              margin="dense"
              label="Email"
              type="email"
              fullWidth
              variant="outlined"
              required
              value={hiringManager.email}
              onChange={(e) => handleChange('email', e.currentTarget.value)}
            />
          </Grid>
        </Grid>
      </StyledPaper>
    </StyledModalPaperContainer>
  );
};
