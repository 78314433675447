import React, { FC } from 'react';
import '../App.css';
import { Container, Grid, Paper, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { hideCompareCandidatesDialogAction, hidePostNewRoleDialogAction } from '../redux/jobSlice';
import { PostNewRoleModal } from '../components/jobs/jobManagement/createNewRole/postNewRoleModal';
import { JobSearchTab } from '../components/jobs/jobSearching/JobSearchTab';
import { CompareCandidatesModal } from '../components/jobs/jobManagement/compareCandidatesModal';

export const JobSearching: FC = () => {
  const dispatch = useDispatch();
  const jobs = useSelector((s: RootState) => s.job);

  return (
    <>
      <Container maxWidth="lg">
        <Grid container direction={'row'} spacing={3}>
          <Grid item xs={12}>
            <Typography component={'h1'} variant={'h3'}>
              Job Searching
            </Typography>
            <Typography variant="body1">
              Based on your skills and X-factors, we've found a few jobs that could be a great fit! Your profile
              information will be shown to the hiring managers of these roles. Use the drop-down menus to filter jobs by
              department, salary, and job title. For roles that spark your excitement more than others, click "Show
              Interest" to let hiring managers know.
            </Typography>
            <Paper sx={{ padding: 2 }}>
              <JobSearchTab archived={false} />
            </Paper>
          </Grid>
        </Grid>
      </Container>

      <PostNewRoleModal
        onClose={() => dispatch(hidePostNewRoleDialogAction())}
        open={jobs.postNewRollDialog.isVisible}
      />
      <CompareCandidatesModal
        open={jobs.compareCandidatesDialog.isVisible}
        onClose={() => dispatch(hideCompareCandidatesDialogAction())}
        value={jobs.jobRoleMatches}
        roleId={jobs.compareCandidatesDialog.roleId}
      />
    </>
  );
};
