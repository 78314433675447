import { RootState } from '../../store';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import confetti from 'canvas-confetti';

export const Confetti: FC = () => {
  const isVisible = useSelector((r: RootState) => r.global.confetti.isVisible);
  useEffect(() => {
    if (isVisible) {
      console.log('showing confetti');
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 },
      });
    }
  }, [isVisible]);
  return null;
};
