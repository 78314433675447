import React, { FC, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Grid, styled, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { updateAvatarAction } from '../../../redux/profileSlice';

class T {}

export const StyledSection = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2.5),
  borderWidth: 2,
  borderRadius: 2,
  borderColor: theme.palette.secondary.main,
  borderStyle: 'dashed',
  backgroundColor: theme.palette.secondary.light,
  color: theme.palette.secondary.dark,
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer',
  width: 'fit-content',
  height: '100%',
}));

export const StyledImg = styled('img')(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    maxHeight: '215px',
  },
  [theme.breakpoints.up('md')]: {
    maxHeight: '275px',
  },
  [theme.breakpoints.up('lg')]: {
    maxHeight: '355px',
  },
}));

export const ProfilePhotoUpload: FC = () => {
  const defaultProfileImage = useSelector((s: RootState) =>
    s.profile.profileDetails.avatarUrl ? s.profile.profileDetails.avatarUrl : '',
  );
  const [previewImage, setPreviewImage] = useState<(T & { preview: string })[]>([]);
  const dispatch = useDispatch();

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/png',
    maxFiles: 1,
    onDrop: (acceptedFileArray) => {
      acceptedFileArray.forEach((acceptedFile: File) => {
        dispatch(updateAvatarAction({ data: acceptedFile, fileName: acceptedFile.name }));
      });
      setPreviewImage(
        acceptedFileArray.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
  });

  const preview = previewImage.map((file) => <StyledImg key={file.preview} src={file.preview} alt={'image preview'} />);

  return (
    <Grid container flexDirection={'column'} mx={'auto'}>
      <Grid item m={'auto'} pb={1.5} width={'100%'}>
        <Typography variant={'body2'}>Profile Image</Typography>
      </Grid>
      <StyledSection container item flexDirection={'column'} alignItems={'center'}>
        <Grid item m={'auto'} width={'100%'} {...getRootProps()}>
          <input name={'profileImage'} {...getInputProps()} />
          <Typography>Drop an image here or click to select an image</Typography>
          <em>(Only *.jpeg and *.png images will be accepted)</em>
        </Grid>
        <Grid item mt={3}>
          {defaultProfileImage && previewImage.length == 0 ? (
            <StyledImg alt={'image preview'} src={`${process.env.REACT_APP_API_URL}${defaultProfileImage}`} />
          ) : (
            preview
          )}
        </Grid>
      </StyledSection>
    </Grid>
  );
};
