import React, { FC } from 'react';
import { Grid, Typography } from '@mui/material';

import { StyledHeader } from '../shared/typography';
import { StyledLink } from '../shared/link';
import { ImageContainer } from '../shared/image';
import { StyledCard, StyledCardContent, StyledCardActionArea } from '../shared/card';
import { SearchJobsSvg } from '../../assets/searchJobsSvg';

export const JobSearchCard: FC = () => {
  return (
    <Grid item xs={12} sm={6} md={4}>
      <StyledLink to={'/job-searching'}>
        <StyledCard>
          <StyledCardActionArea>
            <StyledCardContent>
              <StyledHeader>Look For Jobs</StyledHeader>
              <ImageContainer container item alignContent={'center'} justifyContent={'center'}>
                <SearchJobsSvg />
              </ImageContainer>
              <Typography variant={'body2'}>Find a new role</Typography>
              <Typography variant={'body1'}>
                Explore opportunities for growth and new experiences within your company.
              </Typography>
            </StyledCardContent>
          </StyledCardActionArea>
        </StyledCard>
      </StyledLink>
    </Grid>
  );
};
