import { Box, Container, Grid, Paper, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import '../App.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { JobHistory } from '../components/profile/jobHistory/jobHistory';
import { TabBody, TabPanel } from '../components/shared/tab';
import { XFactors } from '../components/xFactors/xFactors';
import { ContainedButton } from '../components/shared/button';
import { ProfileDetails } from '../components/profile/profileDetails/profileDetails';
import { AddJobHistoryModal } from '../components/profile/jobHistory/jobHistoryAddModal';

import { hideDialogAction, showDialogAction } from '../redux/globalSlice';
import { Skills } from '../components/profile/skills/skills';
import { JobHistoryEditModal } from '../components/profile/jobHistory/jobHistoryEditModal';
import { hideJobHistoryAction, hideProfileDialogAction, showProfileDialogAction } from '../redux/profileSlice';
import { EditProfileDetailsModal } from '../components/profile/profileDetails/editProfileModal';
import { XFactorsModal } from '../components/xFactors/xFactorsModal';
import { selectScenario } from '../redux/xFactorsSlice';
import { Info } from '@mui/icons-material';

export const Profile: FC = () => {
  const dialog = useSelector((s: RootState) => s.global.dialog);
  const profile = useSelector((s: RootState) => s.profile);
  const currentScenario = useSelector((s: RootState) => s.xFactors.currentScenario);
  const [selectedTabIndex, setSelectedTabIndex] = useState('1');
  const dispatch = useDispatch();

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTabIndex(newValue);
  };

  return (
    <>
      <Container maxWidth="lg">
        <Grid container direction="row" spacing={3}>
          <Grid item xs={9}>
            <Typography component={'h1'} variant={'h3'}>
              My Profile
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 1.5 }}>
              {
                [
                  'Complete each of these modules to share more about how you think and feel in different situations',
                  'Keep this section updated when you first build your profile and whenever you learn new skills',
                  'Complete this section by listing your jobs in reverse order and include at least ten years if you have more than a decade of experience',
                ][parseInt(selectedTabIndex) - 1]
              }
            </Typography>
            <Tabs value={selectedTabIndex} onChange={handleTabChange}>
              {['Job History', 'Technical Skills', 'Scenarios'].map((x, i) => (
                <Tab key={i} value={(i + 1).toString()} label={x} autoCapitalize="none" />
              ))}
              {selectedTabIndex === '3' && (
                <Box my="auto">
                  <Tooltip title="X-Factors are noteworthy talents and qualities that make you unique, beyond what your work experience or technical skills. Our questions are designed to uncover these special qualities based on your honest responses. There are no right or wrong answers!">
                    <Info />
                  </Tooltip>
                </Box>
              )}
            </Tabs>
            <Paper>
              <TabBody spacing={0} container direction="column">
                <TabPanel value={Number(selectedTabIndex)} index={1}>
                  <JobHistory profileDetails={profile.profileDetails} loading={profile.loading} />
                </TabPanel>
                <TabPanel value={Number(selectedTabIndex)} index={2}>
                  <Skills />
                </TabPanel>
                <TabPanel value={Number(selectedTabIndex)} index={3}>
                  <XFactors />
                </TabPanel>
              </TabBody>
            </Paper>
            {Number(selectedTabIndex) === 1 ? (
              <Grid container item justifyContent={'flex-end'}>
                <ContainedButton text={'Add Job History'} onClick={() => dispatch(showDialogAction())} />
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
          <Grid item xs={3}>
            <ProfileDetails onOpen={() => dispatch(showProfileDialogAction())} />
          </Grid>
        </Grid>
      </Container>
      <AddJobHistoryModal open={dialog.isVisible} onClose={() => dispatch(hideDialogAction())} />
      <EditProfileDetailsModal
        open={profile.profileDialog.isVisible}
        onClose={() => dispatch(hideProfileDialogAction())}
        profile={profile.profileDetails}
      />
      <JobHistoryEditModal
        open={profile.jobHistoryEditDialog.isVisible}
        onClose={() => dispatch(hideJobHistoryAction())}
      />
      <XFactorsModal open={!!currentScenario?.scenario?.id} onClose={() => dispatch(selectScenario(undefined))} />
    </>
  );
};
