import { Grid, styled } from '@mui/material';

export const ImageContainer = styled(Grid)(({ theme }) => ({
  overflow: 'hidden',
  height: '250px',
  width: '100%',
  marginBottom: theme.spacing(6),
}));

export const ProfileImage = styled('img')(({ theme }) => ({
  objectFit: 'cover',
  [theme.breakpoints.up('xs')]: {
    height: '215px',
    width: '165px',
  },
  [theme.breakpoints.up('md')]: {
    height: '275px',
    width: '235px',
  },
  [theme.breakpoints.up('lg')]: {
    height: '355px',
    width: '270px',
  },
}));

export const StyledSvgIcon = styled('svg')(({}) => ({
  objectFit: 'cover',
  height: '165px',
  width: '175px',
}));

export const StyledCandidateSvgIcon = styled('svg')(({}) => ({
  height: '100%',
  width: '100%',
}));
