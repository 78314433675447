import React, { FC, useState } from 'react';
import '../App.css';
import { Container, Grid, Paper, Tab, Tabs, Typography } from '@mui/material';
import { TabBody, TabPanel } from '../components/shared/tab';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import {
  hideCompareCandidatesDialogAction,
  hidePostNewRoleDialogAction,
  showPostNewRoleDialogAction,
} from '../redux/jobSlice';
import { ContainedButton } from '../components/shared/button';
import { PostNewRoleModal } from '../components/jobs/jobManagement/createNewRole/postNewRoleModal';
import { CompareCandidatesModal } from '../components/jobs/jobManagement/compareCandidatesModal';
import { JobManagementTab } from '../components/jobs/jobManagement/jobManagementTab';

export const JobManagement: FC = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState('1');
  const dispatch = useDispatch();
  const jobs = useSelector((s: RootState) => s.job);

  const handleTabChange = (_event: any, newValue: string) => {
    setSelectedTabIndex(newValue);
  };

  return (
    <>
      <Container maxWidth="lg">
        <Grid container direction={'row'} spacing={3}>
          <Grid item xs={12}>
            <Typography component={'h1'} variant={'h3'}>
              Job Management
            </Typography>
            <Typography variant="body1">
              Create new opportunities, manage existing postings, and learn more about matching candidates.
            </Typography>
            <Tabs sx={{ marginTop: 2 }} value={selectedTabIndex} onChange={handleTabChange}>
              {['Open Jobs', 'Job Archive'].map((x, i) => (
                <Tab sx={{ py: 3 }} key={i} value={(i + 1).toString()} label={x} autoCapitalize="none" />
              ))}
              {Number(selectedTabIndex) === 1 && (
                <ContainedButton
                  sx={{ marginLeft: 'auto', my: 1 }}
                  text={'Post New Role'}
                  onClick={() => dispatch(showPostNewRoleDialogAction())}
                />
              )}
            </Tabs>
            <Paper>
              <TabBody spacing={0} container direction="column">
                <TabPanel value={Number(selectedTabIndex)} index={1}>
                  <JobManagementTab archived={false} />
                </TabPanel>
                <TabPanel value={Number(selectedTabIndex)} index={2}>
                  <JobManagementTab archived={true} />
                </TabPanel>
              </TabBody>
            </Paper>
            {Number(selectedTabIndex) === 1 && (
              <Grid container item justifyContent={'flex-end'}>
                <ContainedButton text={'Post New Role'} onClick={() => dispatch(showPostNewRoleDialogAction())} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>

      <PostNewRoleModal
        onClose={() => dispatch(hidePostNewRoleDialogAction())}
        open={jobs.postNewRollDialog.isVisible}
      />
      <CompareCandidatesModal
        open={jobs.compareCandidatesDialog.isVisible}
        onClose={() => dispatch(hideCompareCandidatesDialogAction())}
        value={jobs.jobRoleMatches}
        roleId={jobs.compareCandidatesDialog.roleId}
      />
    </>
  );
};
