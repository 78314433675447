import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ProfilePhotoUpload } from './profilePhotoUpload';
import { ContainedButton } from '../../shared/button';
import React, { FC, FormEvent, useState } from 'react';
import { StyledDialogTitleWithX } from '../../shared/dialog';
import { updateProfileDetails } from '../../../redux/profileSlice';
import { ProfileModalProps } from '../../../services/shared/baseTypes';
import { useDispatch } from 'react-redux';
import { IGetProfileResponse } from '../../../services/generated/ApiClientGenerated';

export const EditProfileDetailsModal: FC<ProfileModalProps> = ({ open, onClose, profile }) => {
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState<IGetProfileResponse>({});

  const handleSave = (e: FormEvent) => {
    dispatch(updateProfileDetails(formValues));
    onClose();
    e.preventDefault();
  };

  function handleChange<K extends keyof IGetProfileResponse>(key: K, newValue: IGetProfileResponse[K]) {
    setFormValues({
      ...formValues,
      [key]: newValue,
    });
  }

  return (
    <Dialog fullWidth maxWidth={'md'} open={open} aria-labelledby={'Edit Profile'} onClose={onClose}>
      <form onSubmit={handleSave}>
        <StyledDialogTitleWithX onClose={onClose}>Edit Profile</StyledDialogTitleWithX>
        <DialogContent>
          <DialogContentText>
            Introduce yourself! To help us find the best opportunities for you, please be sure to fill out every item
            completely. Your profile information won't be visible to hiring managers until you're selected for an
            interview.
          </DialogContentText>
          <Grid container direction={'row'} spacing={3} margin={'auto'}>
            <Grid item xs={6}>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  margin="dense"
                  defaultValue={profile.firstName}
                  name={'firstName'}
                  label="First Name"
                  placeholder="Enter your first name here."
                  type="text"
                  fullWidth
                  required
                  variant="outlined"
                  onChange={(e) => handleChange('firstName', e.currentTarget.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  margin="dense"
                  name={'lastName'}
                  defaultValue={profile.lastName}
                  label="Last Name"
                  placeholder="Enter your last name here."
                  type="text"
                  fullWidth
                  required
                  variant="outlined"
                  onChange={(e) => handleChange('lastName', e.currentTarget.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Stack my={2} spacing={2}>
                  <Typography variant="caption">
                    You're so much more than your work history, and this is your chance to share your background,
                    interests, and goals. Please write a brief personal statement to introduce yourself. Your statement
                    will be shown to hiring managers after you've accepted an interview invitation. If you're having
                    trouble getting started, imagine that you're writing an email to a close friend about why you'd make
                    a great hire.
                  </Typography>
                  <Typography variant="caption">
                    <b>For example</b>:{' '}
                    <i>
                      "My name is Ahmed Johnson, and I'm passionate about using technology to build creative solutions.
                      My expertise in computer science is artificial intelligence – specifically, natural language
                      processing. I'll never turn down an opportunity to get my hands dirty in the back-end code, but my
                      most rewarding experiences have always been working alongside others in collaborative teams. I'm
                      looking for leadership opportunities that will allow me to interface more directly with system
                      users, so I can build technical solutions that solve a true pain point."
                    </i>
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  multiline
                  minRows={3}
                  name={'personalStatement'}
                  defaultValue={profile.personalStatement}
                  margin="dense"
                  label="Personal Statement"
                  placeholder="Write a brief description of what you want people to know about you here"
                  type="text"
                  fullWidth
                  variant="outlined"
                  onChange={(e) => handleChange('personalStatement', e.currentTarget.value)}
                />
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <ProfilePhotoUpload />
            </Grid>
          </Grid>

          <DialogActions>
            <ContainedButton type={'submit'} text="Save" />
          </DialogActions>
        </DialogContent>
      </form>
    </Dialog>
  );
};
