import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IXFactorQuestion,
  IGetXFactorScenariosXFactorScenarioWithCompletion,
} from '../services/generated/ApiClientGenerated';
import { Loadable } from '../services/shared/baseTypes';

export type DialogType = {
  isVisible: boolean;
  communicationDialog: {
    isVisible: boolean;
  };
  creativityDialog: {
    isVisible: boolean;
  };
  leadershipDialog: {
    isVisible: boolean;
  };
  learningStyle: {
    isVisible: boolean;
  };
  teamPlayer: {
    isVisible: boolean;
  };
  workStyleDialog: {
    isVisible: boolean;
  };
};

export type ActiveXFactorScenario = {
  scenario: IGetXFactorScenariosXFactorScenarioWithCompletion;
  questions?: Loadable<IXFactorQuestion[]>;
};

export type XFactorScenarioState = {
  loading: boolean;
  loaded: boolean;
  scenarios: IGetXFactorScenariosXFactorScenarioWithCompletion[];
  currentScenario?: ActiveXFactorScenario;
  noScenarioInfo?: IGetXFactorScenariosXFactorScenarioWithCompletion;
};

export const xFactorsSlice = createSlice({
  name: 'xFactors',
  initialState: {
    loading: false,
    loaded: false,
    scenarios: [],
    currentScenario: undefined,
    noScenarioInfo: undefined,
  } as XFactorScenarioState,
  reducers: {
    selectScenario: (state, action: PayloadAction<string | undefined>) => {
      state.currentScenario = {
        scenario: state.scenarios.filter((x) => x.id === action.payload)[0],
        questions: { isLoaded: false, isLoading: true },
      };
    },
    unselectScenario: (state) => {
      state.currentScenario = undefined;
    },
    loadXFactorScenarios: (state) => {
      state.loading = true;
    },
    successLoadedXFactorScenarios: (
      state,
      action: PayloadAction<IGetXFactorScenariosXFactorScenarioWithCompletion[]>,
    ) => {
      state.loading = false;
      state.scenarios = action.payload.filter((x) => x.id);
      state.noScenarioInfo = action.payload.filter((x) => !x.id)[0];
      state.loaded = true;
    },
    failedLoadedXFactorScenarios: (state) => {
      state.loading = false;
      state.loading = false;
      state.scenarios = [];
      state.noScenarioInfo = undefined;
    },
    successLoadedQuestions: (state, action: PayloadAction<IXFactorQuestion[]>) => {
      if (state.currentScenario) {
        state.currentScenario.questions = {
          isLoaded: true,
          isLoading: false,
          value: action.payload,
        };
      }
    },
    failedLoadedQuestions: (state) => {
      if (state.currentScenario) {
        state.currentScenario.questions = { isLoaded: false, isLoading: false };
      }
    },
  },
});

export const {
  failedLoadedXFactorScenarios,
  failedLoadedQuestions,
  loadXFactorScenarios,
  selectScenario,
  successLoadedQuestions,
  successLoadedXFactorScenarios,
  unselectScenario,
} = xFactorsSlice.actions;
