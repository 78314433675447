import { CircularProgress, Grid, Paper, Typography } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { DividerButton } from '../../shared/button';
import { ProfileImage } from '../../shared/image';
import { ProfileDetailsContainer } from '../../shared/grid';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

type OnOpenType = {
  onOpen?: () => void;
};

export const ProfileDetails: FC<OnOpenType> = (props) => {
  const { onOpen } = props;
  const profile = useSelector((s: RootState) => s.profile);
  const currentRole = profile?.profileDetails?.jobHistories?.find((jobHistory) => jobHistory.isCurrentJob === true);

  useEffect(() => {}, [profile]);

  return (
    <>
      <Paper sx={{ borderRadius: '12px', overflow: 'hidden' }}>
        {profile.loading ? (
          <Grid container item justifyContent={'center'} p={2}>
            <CircularProgress />
          </Grid>
        ) : (
          <>
            {profile.profileDetails.avatarUrl && (
              <Grid container item alignContent={'center'}>
                <ProfileImage
                  alt={'Profile image'}
                  src={`${process.env.REACT_APP_API_URL}${profile.profileDetails.avatarUrl}`}
                />
              </Grid>
            )}
            <ProfileDetailsContainer container item direction={'column'} spacing={2}>
              {profile.profileDetails.firstName && profile.profileDetails.lastName && (
                <Grid item>
                  <Typography variant={'h4'}>
                    {profile.profileDetails.firstName.charAt(0).toUpperCase() +
                      profile.profileDetails.firstName.slice(1)}{' '}
                    {profile.profileDetails.lastName.charAt(0).toUpperCase() + profile.profileDetails.lastName.slice(1)}
                  </Typography>
                </Grid>
              )}

              {currentRole && (
                <Grid item>
                  <Typography variant={'h5'}>Role</Typography>
                  <Typography variant="body1">{currentRole.roleName}</Typography>
                </Grid>
              )}

              {profile.profileDetails.personalStatement && (
                <Grid item>
                  <Typography variant={'h5'}>Personal Statement</Typography>
                  <Typography variant="body1">{profile.profileDetails.personalStatement}</Typography>
                </Grid>
              )}

              <Grid item>
                <DividerButton onOpen={onOpen} text={'Edit Profile'} />
              </Grid>
            </ProfileDetailsContainer>
          </>
        )}
      </Paper>
    </>
  );
};
