import { createTheme } from '@mui/material';

export const appTheme = createTheme({
  palette: {
    text: {
      primary: '#4E565F',
      secondary: '#737C85',
    },
    primary: {
      main: '#59BDBF',
      dark: '#449D9F',
      contrastText: '#FFFFFF',
    },
    secondary: {
      light: '#fafafa',
      main: '#CCD3DB',
      dark: '#bdbdbd',
    },
    warning: {
      main: '#ff4133',
    },
    error: {
      main: '#9F0311',
    },
    background: {
      default: '#F8F9FB',
    },
  },
  typography: {
    h6: {
      fontSize: '0.75rem',
      fontWeight: 500,
    },
    h5: {
      fontSize: '0.875rem',
      fontWeight: 500,
    },
    h4: {
      fontSize: '1.125rem',
      fontWeight: 500,
    },
    h3: {
      fontSize: '1.25rem',
      fontWeight: 500,
    },
    // h1: {
    //   fontSize: '3.125rem',
    //   fontWeight: 500,
    // },
    body1: {
      fontSize: '0.875rem',
    },
    body2: {
      fontSize: '1rem',
      fontWeight: 500,
    },
    allVariants: {
      textTransform: 'none',
    },
  },
  components: {
    MuiCard: {
      defaultProps: {},
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            borderRadius: '13px',
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {},
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: '#4E565F',
          paddingBottom: '1rem',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '1.25rem',
          color: '#4E565F',
          paddingBottom: 0,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '& ::placeholder': {
            fontStyle: 'italic',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {},
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          background: '#F8F9FB',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          '&:not(:last-child)': {
            marginRight: '12px',
          },
          '&.Mui-selected': {
            color: '#4E565F',
            borderColor: 'transparent',
            textDecorationColor: 'transparent',
            background: '#ffffff',
          },
          '&:not(.Mui-selected)': {
            background: '#CCD3DB',
          },
        },
      },
    },
  },
});
