import React, { FC } from 'react';

export const JobRecsSvg: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="164.001" height="230" viewBox="0 0 164.001 230">
      <g id="Group_11" data-name="Group 11" transform="translate(0.001 0)">
        <g id="Group_51" data-name="Group 51" transform="translate(7 94)">
          <path
            id="Path"
            d="M139,26.8c0,22.013-31.349,15.2-69.733,15.2S0,48.814,0,26.8,31.345,0,69.729,0,139,4.788,139,26.8Z"
            transform="translate(18 75)"
            opacity="0.1"
          />
          <path
            id="Path-2"
            data-name="Path"
            d="M139,26.8c0,22.013-31.349,15.2-69.733,15.2S0,48.814,0,26.8,31.345,0,69.729,0,139,4.788,139,26.8Z"
            transform="translate(18 69)"
            fill="#3f3d56"
          />
          <path
            id="Path-3"
            data-name="Path"
            d="M7.4.3,8.989,6.661a.387.387,0,0,1-.008.215L1.792,28.724A.409.409,0,0,1,1.363,29,.4.4,0,0,1,1,28.648L0,20.137a.387.387,0,0,1,.017-.168L6.619.274A.412.412,0,0,1,7.4.3Z"
            transform="translate(144 57)"
            fill="#2f2e41"
          />
          <path
            id="Path-4"
            data-name="Path"
            d="M5.687,0,7,5.589,1.312,26,0,19.682,5.687,0Z"
            transform="translate(145 56)"
            fill="#f2f2f2"
          />
          <path
            id="Path-5"
            data-name="Path"
            d="M.046,10.744c.247.7,25.717-.234,25.965-.467a4.993,4.993,0,0,0,.544-.934c.232-.467.445-.934.445-.934L26.011.846.788,0S.158,6.409.029,9.343a6.621,6.621,0,0,0,.017,1.4Z"
            transform="translate(118 76)"
            fill="#2f2e41"
          />
          <path id="Path-6" data-name="Path" d="M10.511,0,11,7H0V0Z" transform="translate(130 77)" opacity="0.1" />
          <path
            id="Path-7"
            data-name="Path"
            d="M6.928.013,7,0,6.662,3H0V.013Z"
            transform="translate(120 80)"
            opacity="0.1"
          />
          <path
            id="Path-8"
            data-name="Path"
            d="M.047.846C.3,1.268,26.2.7,26.447.564A3.172,3.172,0,0,0,27,0H.03A2.384,2.384,0,0,0,.047.846Z"
            transform="translate(118 86)"
            opacity="0.1"
          />
          <path
            id="Path-9"
            data-name="Path"
            d="M6.129,7.759h6.865L29.91,1.265s12.013-4.81,11.033,4.329S38.246,27,38.246,27s-5.394-2.405-8.336-1.684-.981-14.671-.981-14.671S4.9,21.949,2.7,20.506,0,8.961,0,8.961Z"
            transform="translate(73 54)"
            fill="#2f2e41"
          />
          <path
            id="Path-10"
            data-name="Path"
            d="M6.129,7.759h6.865L29.91,1.265s12.013-4.81,11.033,4.329S38.246,27,38.246,27s-5.394-2.405-8.336-1.684-.981-14.671-.981-14.671S4.9,21.949,2.7,20.506,0,8.961,0,8.961Z"
            transform="translate(73 54)"
            opacity="0.1"
          />
          <path
            id="Path-11"
            data-name="Path"
            d="M6.094,0l8.222,11.588L33.485,27.3S51.2,31.344,48.772,34.2s-17.228-2.856-17.228-2.856S9.463,15.635,8.735,14.445,0,2.067,0,2.067Z"
            transform="translate(84 41)"
            fill="#a0616a"
          />
          <path
            id="Path-12"
            data-name="Path"
            d="M6.094,0l8.222,11.588L33.485,27.3S51.2,31.344,48.772,34.2s-17.228-2.856-17.228-2.856S9.463,15.635,8.735,14.445,0,2.067,0,2.067Z"
            transform="translate(84 41)"
            opacity="0.1"
          />
          <circle id="Oval" cx="11" cy="11" r="11" transform="translate(79 5)" fill="#a0616a" />
          <path
            id="Path-13"
            data-name="Path"
            d="M17,9.5s-3.723,6.371-4.467,9.8S1.365,12.439,1.365,12.439L0,8.886S9.8,4.352,9.058.677,17,9.5,17,9.5Z"
            transform="translate(71 17)"
            fill="#a0616a"
          />
          <path
            id="Path-14"
            data-name="Path"
            d="M7.8,0,13,10.408s-1.182,5.083-3.073,5.567S0,9.682,0,9.682Z"
            transform="translate(78 30)"
            fill="#d0cde1"
          />
          <path
            id="Path-15"
            data-name="Path"
            d="M11.786,0,21.854,12.792,45.918,30.086s17.434,3.553,13.505,6.159S44.2,33.876,44.2,33.876,19.89,21.321,14.733,16.109,0,2.132,0,2.132Z"
            transform="translate(78 45)"
            fill="#a0616a"
          />
          <path
            id="Path-16"
            data-name="Path"
            d="M21.818,1.488,24.76,6,42.317.649A14.184,14.184,0,0,1,53.81,1.993c3.064,1.871,5.026,4.842.613,9.238-8.825,8.792-14.709,4.04-14.709,4.04S10.051,28.815,5.148,22.161,0,12.181,0,12.181,18.876.775,21.818,1.488Z"
            transform="translate(55 64)"
            fill="#2f2e41"
          />
          <path
            id="Path-17"
            data-name="Path"
            d="M25.893,2.271s7.891,5.3-1.48,7.821A24.32,24.32,0,0,1,8.138,9.082S0,9.082,0,4.793,2.713,0,2.713,0L11.1,1.261S21.208-1.514,25.893,2.271Z"
            transform="translate(88 79)"
            fill="#d0cde1"
          />
          <path
            id="Path-18"
            data-name="Path"
            d="M23.365,13.394a8.06,8.06,0,0,0,2.284.829,2.074,2.074,0,0,0,2.107-.915A2.9,2.9,0,0,0,28,12.01a5.842,5.842,0,0,0-.91-3.848c-.537-.664-1.32-1.082-1.875-1.73a8.2,8.2,0,0,1-.948-1.569c-1.08-2.055-2.679-4.03-4.9-4.623A10.5,10.5,0,0,0,16.5.019L10.9.1A9.623,9.623,0,0,0,8.211.392C6.409.944,5.1,2.479,3.9,3.947a22.222,22.222,0,0,0-2.474,3.46A12.269,12.269,0,0,0,0,13.355a5.634,5.634,0,0,0,.2,1.574,8.79,8.79,0,0,0,.611,1.359c1.05,2.089,1.673,4.7.491,6.712a12.724,12.724,0,0,0,5.439-3.744,10.292,10.292,0,0,1,1.889-2.312,1.875,1.875,0,0,1,2.641.223,2.424,2.424,0,0,1,.267,1.123,5.21,5.21,0,0,0,.306,1.9,1.4,1.4,0,0,0,1.523.881c1.055-.3.954-1.941,1.766-2.686.617-.566,1.569-.458,2.361-.715a3.527,3.527,0,0,0,2.011-2.007c.3-.682.3-2.467.864-2.836C21.085,12.36,22.7,13.1,23.365,13.394Z"
            transform="translate(74)"
            fill="#2f2e41"
          />
          <path
            id="Path-19"
            data-name="Path"
            d="M18.273.1A2.961,2.961,0,0,1,19.8.1c1.587.422,5.144,1.539,5.906,3.238.989,2.2,4.449,5.631,4.449,5.631s4.7,4.652,3.708,7.834-4.7,6.855-4.7,6.855.989,15.179-3.955,19.1-6.921,1.224-6.921,4.9S2.966,62.832,0,55.732c0,0,1.483-13.22.989-17.872C.5,33.276,1.215,4.672,18.273.1Z"
            transform="translate(52 24)"
            fill="#d0cde1"
          />
          <path
            id="Path-20"
            data-name="Path"
            d="M11.431.628s14.937,10.757,12.244,15.3c0,0-9.06,4.3-11.019,4.064s-9.55-8.606-11.509-9.323S-1.792-2.958,11.431.628Z"
            transform="translate(67 29)"
            fill="#d0cde1"
          />
          <path
            id="Path-21"
            data-name="Path"
            d="M16.532,1.261a7.051,7.051,0,0,0-4.79,4.989c-.215,1.107-.083,2.258-.265,3.371-.42,2.582-2.415,4.554-4.254,6.385s-3.739,3.96-3.873,6.573c-.147,2.862,1.9,5.543,1.542,8.386C4.483,34.268,1.05,36.408.2,39.624a8.441,8.441,0,0,0,1.27,6.356,17.924,17.924,0,0,0,9.86,7.38A40.46,40.46,0,0,0,23.683,55c5.075.054,10.586-.389,14.4-3.793a11.79,11.79,0,0,0,3.829-7.632c.362-3.943-1.257-8.018-.14-11.812.7-2.388,2.466-4.569,2.2-7.047-.257-2.423-2.339-4.14-3.768-6.1A24.957,24.957,0,0,1,37.269,12.5L34.555,5.22a8.476,8.476,0,0,0-2.308-3.876C30.021-.415,26.442-.069,23.84.294,21.425.63,18.854.464,16.532,1.261Z"
            transform="translate(21 38)"
            fill="#2f2e41"
          />
          <path
            id="Path-22"
            data-name="Path"
            d="M11.971.349s1.241,4.007-8.064,8.63.93,9.555.93,9.555L15.693,21S26.859,19.459,28.1,17.61,31.511,9.6,30.891,8.671A14.431,14.431,0,0,0,29.34,6.822S21.9,5.589,22.206,2.2,11.971.349,11.971.349Z"
            transform="translate(26 61)"
            fill="#ffb9b9"
          />
          <path
            id="Path-23"
            data-name="Path"
            d="M11.971.349s1.241,4.007-8.064,8.63.93,9.555.93,9.555L15.693,21S26.859,19.459,28.1,17.61,31.511,9.6,30.891,8.671A14.431,14.431,0,0,0,29.34,6.822S21.9,5.589,22.206,2.2,11.971.349,11.971.349Z"
            transform="translate(26 61)"
            opacity="0.1"
          />
          <circle id="Oval-2" data-name="Oval" cx="9.5" cy="9.5" r="9.5" transform="translate(35 49)" fill="#ffb9b9" />
          <path
            id="Path-24"
            data-name="Path"
            d="M22.395,11.024s-11.663-.858-11.884-8.956c0,0-4.155-.484-5.366.734S0,4.933,0,7.979,6.658,20.16,6.658,20.16s3.632,12.181,2.118,14.313-1.816,7.613-1.816,7.613,15.132-1.218,17.553,0,12.105,1.218,12.711,0-.605-16.445-.605-16.445a11.941,11.941,0,0,0,2.118-7.918L46,9.2S43.276.975,39.039.975c0,0-5.75-1.523-5.9-.761S36.013,9.5,22.395,11.024Z"
            transform="translate(21 68)"
            fill="#ff6584"
          />
          <path
            id="Path-25"
            data-name="Path"
            d="M0,3.641S2.82,7.888,10.026,8.8s8.459-1.517,7.833-2.124S9.712,3.337,9.712,3.337L5.326,0Z"
            transform="translate(15 102)"
            fill="#ffb9b9"
          />
          <path
            id="Path-26"
            data-name="Path"
            d="M15.077,0,7.231,4.532s-10.26-.6-6.337,2.417S12.059,8.761,12.059,8.761L19,5.438Z"
            transform="translate(51 101)"
            fill="#ffb9b9"
          />
          <path
            id="Path-27"
            data-name="Path"
            d="M2.876,0s-7.4,18.439,1.542,21.252,33.613,2.188,39.164-1.563,7.093-8.751,6.168-10.313-7.093-5.938-11.41-6.251S2.876,0,2.876,0Z"
            transform="translate(23 109)"
            fill="#2f2e41"
          />
          <path
            id="Path-28"
            data-name="Path"
            d="M2.876,0s-7.4,18.439,1.542,21.252,33.613,2.188,39.164-1.563,7.093-8.751,6.168-10.313-7.093-5.938-11.41-6.251S2.876,0,2.876,0Z"
            transform="translate(23 109)"
            opacity="0.05"
          />
          <path
            id="Path-29"
            data-name="Path"
            d="M24.161,3.565S50.306-5.6,50,5.459,33.332,19.506,33.332,19.506l-4.938.6L1.235,21S2.16,16.218,0,15.62c0,0,7.1-1.793,7.407-2.092s17.9-6.276,19.135-6.575,4.938-2.69,7.716-2.391c0,0,3.086-2.391,6.481-1.793l-9.567.6Z"
            transform="translate(37 107)"
            fill="#2f2e41"
          />
          <path
            id="Path-30"
            data-name="Path"
            d="M24.161,3.565S50.306-5.6,50,5.459,33.332,19.506,33.332,19.506l-4.938.6L1.235,21S2.16,16.218,0,15.62c0,0,7.1-1.793,7.407-2.092s17.9-6.276,19.135-6.575,4.938-2.69,7.716-2.391c0,0,3.086-2.391,6.481-1.793l-9.567.6Z"
            transform="translate(37 107)"
            opacity="0.1"
          />
          <path
            id="Path-31"
            data-name="Path"
            d="M0,4.5,3.177,12,22.513,4.923,24,.923,18.943,0,8.776,1.846,4.367,3.077,0,4.5Z"
            transform="translate(56 107)"
            fill="#2f2e41"
          />
          <path
            id="Path-32"
            data-name="Path"
            d="M0,4.5,3.177,12,22.513,4.923,24,.923,18.943,0,8.776,1.846,4.367,3.077,0,4.5Z"
            transform="translate(56 107)"
            opacity="0.1"
          />
          <path
            id="Path-33"
            data-name="Path"
            d="M5.419,15.188s5.72,7.306,12.344,9.133S28,23.712,28,23.712l-2.108-8.524-.9-5.48-3.914-2.74L13.247,3.315,3.914.271S0-.338,0,.271s3.613,9.742,3.613,9.742Z"
            transform="translate(9 106)"
            fill="#2f2e41"
          />
          <path
            id="Path-34"
            data-name="Path"
            d="M0,.045s1.186,8.233,2.668,8.8S8.6,8.277,9.783,7.71,15,4.522,15,4.522.889-.523,0,.045Z"
            transform="translate(22 126)"
            fill="#f2f2f2"
          />
          <path
            id="Path-35"
            data-name="Path"
            d="M3,0,9,.923,8.368,8,0,6.02,3,0Z"
            transform="translate(46 126)"
            fill="#ffb9b9"
          />
          <path
            id="Path-36"
            data-name="Path"
            d="M1.2,3.2s-2.726,3.321.029,5.2L3.069,9.972s6.122,1.253,6.734,1.88,3.061-.94,3.061-.94.306-9.4,0-10.026S8.885-.367,8.579.26,4.6,1.826,2.456,1.2Z"
            transform="translate(52 124)"
            fill="#f2f2f2"
          />
          <path
            id="Path-37"
            data-name="Path"
            d="M28.736,8.989S7.024-3.59,3.612,1.012-3.522,12.058,6.4,16.966,47.347,32,47.347,32,52,24.943,52,23.1c0,0-16.44-7.363-19.231-7.977S18.811,7.455,17.26,6.842a18.014,18.014,0,0,1-3.722-2.454Z"
            transform="translate(0 103)"
            fill="#2f2e41"
          />
          <path
            id="Path-38"
            data-name="Path"
            d="M8.306,0,7.031.919S4.163,7.964,3.844,11.946-.3,28.18.019,29.712A6.231,6.231,0,0,0,2.569,34s2.55-5.514,7.012-4.595L15,10.414Z"
            transform="translate(13 73)"
            fill="#ff6584"
          />
          <path
            id="Path-39"
            data-name="Path"
            d="M5.851,0,8.315,2.716a25.715,25.715,0,0,1,1.54,4.526c0,.905,3.08,14.181,3.387,14.784s1.54,8.147,0,9.957A13.863,13.863,0,0,1,8.931,35s1.848-6.336-4-6.638l.616-2.414L0,7.845Z"
            transform="translate(59 74)"
            fill="#ff6584"
          />
          <path
            id="Path-40"
            data-name="Path"
            d="M0,3.817V19.692a3.842,3.842,0,0,0,3.811,3.816L37.456,24a3.846,3.846,0,0,0,3.922-3.67L42,4.453A3.788,3.788,0,0,0,40.939,1.68,3.892,3.892,0,0,0,38.188.49L3.924,0A3.9,3.9,0,0,0,1.153,1.1,3.792,3.792,0,0,0,0,3.817Z"
            transform="translate(22 91)"
            fill="#3f3d56"
          />
          <ellipse
            id="Oval-3"
            data-name="Oval"
            cx="10.5"
            cy="5.5"
            rx="10.5"
            ry="5.5"
            transform="translate(34 46)"
            fill="#2f2e41"
          />
          <circle id="Oval-4" data-name="Oval" cx="2.5" cy="2.5" r="2.5" transform="translate(41 100)" fill="#f2f2f2" />
        </g>
        <g id="Group_10" data-name="Group 10">
          <path
            id="face"
            d="M47.081,80h-.006a50.229,50.229,0,0,1-13-1.748,53.339,53.339,0,0,1-12.105-4.906,49.587,49.587,0,0,1-10.281-7.56,40.925,40.925,0,0,1-7.529-9.71A32.162,32.162,0,0,1,.012,39.53,36.151,36.151,0,0,1,6.006,21.1c-2.4-.479-3.712-1.337-3.886-2.548-.251-1.743,1.907-3.919,3.762-5.437a37.973,37.973,0,0,1,5.573-3.748l.718-.376c3.32-1.739,6.753-3.537,10.3-5.044A44.656,44.656,0,0,1,33.417.681,65.059,65.059,0,0,1,42.854,0,60.322,60.322,0,0,1,67.487,5.008a43.177,43.177,0,0,1,10.444,6.567,35.569,35.569,0,0,1,7.86,9.548,32.828,32.828,0,0,1,4.087,13.45A38.266,38.266,0,0,1,88.42,48.389,45.758,45.758,0,0,1,72.752,71.482,39.865,39.865,0,0,1,60.44,77.962,43.711,43.711,0,0,1,47.081,80ZM41.289,10a37.481,37.481,0,0,0-14.769,3.05,33.154,33.154,0,0,0-11.785,8.465,26.765,26.765,0,0,0-6.463,21.7A30.212,30.212,0,0,0,12.548,54.99a36.509,36.509,0,0,0,8.445,9.447,39.98,39.98,0,0,0,11.19,6.282A36.393,36.393,0,0,0,44.7,73c.75,0,1.5-.026,2.242-.076A35.858,35.858,0,0,0,68.653,63.41a34.846,34.846,0,0,0,11.063-20.72,26.252,26.252,0,0,0-.7-11.055,23.684,23.684,0,0,0-4.77-8.71,30.094,30.094,0,0,0-6.406-5.567,40.716,40.716,0,0,0-7.918-3.983,54.181,54.181,0,0,0-17.851-3.367C41.807,10,41.551,10,41.289,10Z"
            transform="translate(90 80) rotate(-180)"
            fill="#c5e1e4"
          />
          <path
            id="Fill_241"
            data-name="Fill 241"
            d="M32.755.016C30.285.24,28,1.464,25.509,1.479c-2.092.013-4.152-.648-6.255-.55-2.467.114-3.825,1.8-5.986,2.362-1.721.449-2.8-.942-4.318-1.557-1.911-.777-3.323-.376-4.961.8C2.785,3.4-.022,5.334,0,7.08c.064,5.289,5.91-2.017,7.088-2.106,1.566-.118,3.315,1.767,4.981,1.874,2.2.142,3.615-1.357,5.643-1.9,2.051-.552,4.268.124,6.324.334a18.269,18.269,0,0,0,6.669-.9,12.781,12.781,0,0,1,6.309-.061c1.508.325,5.266,2.213,5.926-.215C43.838.794,34.482-.139,32.755.016"
            transform="translate(62 53) rotate(-180)"
            fill="#89c5cc"
          />
          <path
            id="Fill_243"
            data-name="Fill 243"
            d="M25.789.006C20.609.461,15.4.859,10.2.987,7.943,1.042-.72.169.048,2.758.707,4.977,12.5,3.58,14.9,3.411c2.651-.186,5.3-.446,7.935-.748.935-.107,3.192,0,3.852-.705A1.143,1.143,0,0,0,25.789.006"
            transform="translate(53 43) rotate(-180)"
            fill="#89c5cc"
          />
          <path
            id="have_a_nice_day_:_"
            data-name="have a nice day :)"
            d="M5.8,12h0A5.328,5.328,0,0,1,1.622,9.879,7.564,7.564,0,0,1,0,5.359C-.041,2.857,1.319.135,5.262,0h.055a.891.891,0,0,1,.738.349.742.742,0,0,0,.485.29h.015a.955.955,0,0,0,.4-.206L7.011.394A1.115,1.115,0,0,1,7.56.15h.024c1.437.094,2.7.3,3.678,1.839a6.348,6.348,0,0,1,.3,5.337A6.806,6.806,0,0,1,7.876,11.61,6.011,6.011,0,0,1,5.8,12Zm1.154-9.16a1.156,1.156,0,0,0-.622.189,2.061,2.061,0,0,1-1.071.291c-1.955.063-2.144,1.432-2.135,2.018a3.962,3.962,0,0,0,.9,2.32A2.774,2.774,0,0,0,6.106,8.806a2.459,2.459,0,0,0,1.762-.844,5.024,5.024,0,0,0,1.1-2.176,2.862,2.862,0,0,0-.255-2.341c-.316-.4-.447-.407-.685-.426a2.126,2.126,0,0,1-.44-.074A2.389,2.389,0,0,0,6.956,2.841Z"
            transform="translate(68 34) rotate(-180)"
            fill="#89c5cc"
          />
          <path
            id="espero"
            d="M2.431,5H2.378A2.364,2.364,0,0,1,.6,4.178a2.482,2.482,0,0,1-.58-1.943C.191.948,1.051.244,2.728.022A2.545,2.545,0,0,1,3.055,0,1.792,1.792,0,0,1,4.413.569,2.533,2.533,0,0,1,4.989,2.5a2.91,2.91,0,0,1-.716,1.719A2.415,2.415,0,0,1,2.431,5ZM2.205,2h0a.549.549,0,0,0-.175.621A.48.48,0,0,0,2.463,3a.458.458,0,0,0,.168-.034.763.763,0,0,0,.354-.5.357.357,0,0,0-.056-.325c.006.007.008.012.007.016s-.012.008-.029.008a2.212,2.212,0,0,1-.4-.085c-.1-.027-.193-.053-.249-.069L2.205,2Z"
            transform="translate(47 90)"
            fill="#89c5cc"
          />
          <path
            id="espero_copy"
            data-name="espero copy"
            d="M2.431,5H2.378A2.364,2.364,0,0,1,.6,4.178a2.482,2.482,0,0,1-.58-1.943C.191.948,1.051.244,2.728.022A2.545,2.545,0,0,1,3.055,0,1.792,1.792,0,0,1,4.413.569,2.533,2.533,0,0,1,4.989,2.5a2.91,2.91,0,0,1-.716,1.719A2.415,2.415,0,0,1,2.431,5ZM2.205,2h0a.549.549,0,0,0-.175.621A.48.48,0,0,0,2.463,3a.458.458,0,0,0,.168-.034.763.763,0,0,0,.354-.5.357.357,0,0,0-.056-.325c.006.007.008.012.007.016s-.012.008-.029.008a2.212,2.212,0,0,1-.4-.085c-.1-.027-.193-.053-.249-.069L2.205,2Z"
            transform="translate(70 80)"
            fill="#89c5cc"
          />
          <path
            id="hola"
            d="M3.342-9a3.289,3.289,0,0,0-2.355.9,3.339,3.339,0,0,0-.97,2.07,3.536,3.536,0,0,0,3.1,3.845A1.317,1.317,0,0,0,2.5-1.4a.933.933,0,0,0,.13.721,1.2,1.2,0,0,0,1,.674C5.055,0,6.739-3.129,6.886-3.923a5.547,5.547,0,0,0-.217-3.055A3.076,3.076,0,0,0,4.3-8.9,4.819,4.819,0,0,0,3.342-9ZM4.981-4h0c-.606-.048-2.013-.25-1.98-1.09A1.083,1.083,0,0,1,4.048-6a.778.778,0,0,1,.587.259A2.327,2.327,0,0,1,4.981-4Z"
            transform="translate(45 112)"
            fill="#89c5cc"
          />
          <path
            id="hola_copy"
            data-name="hola copy"
            d="M3.342,9A3.289,3.289,0,0,1,.986,8.1a3.339,3.339,0,0,1-.97-2.07,3.536,3.536,0,0,1,3.1-3.845A1.317,1.317,0,0,1,2.5,1.4a.933.933,0,0,1,.13-.721,1.2,1.2,0,0,1,1-.674C5.055,0,6.739,3.129,6.886,3.923a5.547,5.547,0,0,1-.217,3.055A3.076,3.076,0,0,1,4.3,8.9,4.819,4.819,0,0,1,3.342,9ZM4.981,4h0c-.606.048-2.013.25-1.98,1.09A1.083,1.083,0,0,0,4.048,6a.778.778,0,0,0,.587-.259A2.327,2.327,0,0,0,4.981,4Z"
            transform="translate(82 97) rotate(-180)"
            fill="#89c5cc"
          />
        </g>
      </g>
    </svg>
  );
};
