import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import React, { FC, FormEvent, useState } from 'react';
import { ContainedButton } from '../../shared/button';
import { addJobHistoryAction } from '../../../redux/profileSlice';
import { useDispatch } from 'react-redux';
import { IAddJobHistoryRequest, IJobHistory } from '../../../services/generated/ApiClientGenerated';
import { ModalProps } from '../../../services/shared/baseTypes';
import { StyledDialogTitleWithX } from '../../shared/dialog';

export const DateGridContainer = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  alignItems: 'center',
  '& .MuiInputBase-input': {
    textAlign: 'center',
  },
  '& .MuiCheckbox-root': {
    marginLeft: theme.spacing(2.5),
  },
}));

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  '& .MuiFormControlLabel-label': {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export const AddJobHistoryModal: FC<ModalProps> = ({ onClose, open }) => {
  const [newJobHistory, setNewJobHistory] = useState<IAddJobHistoryRequest>();
  const [internalIsCurrentJob, setInternalIsCurrentJob] = useState<boolean>(false);
  const dispatch = useDispatch();

  const handleSave = (e: FormEvent) => {
    if (newJobHistory) {
      dispatch(addJobHistoryAction(newJobHistory));
    }
    onClose();
    e.preventDefault();
  };

  function handleChange<K extends keyof IJobHistory>(key: K, newValue: IJobHistory[K]) {
    setNewJobHistory({
      ...newJobHistory,
      [key]: newValue,
    });
  }

  const handleInternalJobChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInternalIsCurrentJob(!internalIsCurrentJob);
    setNewJobHistory({
      ...newJobHistory,
      endDate: undefined,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <Dialog fullWidth maxWidth={'md'} open={open} aria-labelledby={'Add Job History'} onClose={onClose}>
      <form onSubmit={handleSave}>
        <StyledDialogTitleWithX onClose={onClose}>Add Job History</StyledDialogTitleWithX>
        <DialogContent>
          <DialogContentText>
            Tell us about your experience in this role and what you did to stand out.
          </DialogContentText>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                label="Company Name"
                name="companyName"
                placeholder="This is the company name of this job"
                type="text"
                fullWidth
                required
                variant="outlined"
                onChange={(e) => handleChange('companyName', e.currentTarget.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                label="Role Title"
                name="roleName"
                placeholder="This is the role title of this job"
                type="text"
                fullWidth
                required
                variant="outlined"
                onChange={(e) => handleChange('roleName', e.currentTarget.value)}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Stack my={2} spacing={2}>
              <Typography>
                Think beyond the job description to share 1-2 sentences about your biggest wins and proudest
                accomplishments.
              </Typography>
              <Typography>
                <b>For example:</b>{' '}
                <i>
                  I led weekly meetings and approved all deliverables from a team of internal and contract marketing
                  specialists in order to launch a new brand awareness campaign that resulted in a 40% increase in
                  qualified leads.
                </i>
              </Typography>
            </Stack>
          </Grid>
          <TextField
            margin="dense"
            label="Duties and Description"
            name="description"
            type="text"
            fullWidth
            variant="outlined"
            onChange={(e) => handleChange('description', e.currentTarget.value)}
          />

          <DateGridContainer container direction={'row'} spacing={1} justifyContent={'space-between'}>
            <Grid item>
              <DatePicker
                label="Start Date"
                views={['year', 'month']}
                renderInput={(params) => (
                  <TextField required name="startDate" variant="outlined" margin="dense" {...params} />
                )}
                value={newJobHistory?.startDate}
                onChange={(date) => handleChange('startDate', date ?? undefined)}
              />
            </Grid>

            <Grid item>
              <DatePicker
                label={'End Date'}
                views={['year', 'month']}
                disabled={internalIsCurrentJob}
                renderInput={(params) => <TextField required name="endDate" variant="outlined" {...params} />}
                value={newJobHistory?.endDate ?? null}
                onChange={(date) => handleChange('endDate', date ?? undefined)}
              />
            </Grid>

            <Grid item>
              <FormGroup>
                <StyledFormControlLabel
                  control={<Checkbox checked={internalIsCurrentJob} onChange={handleInternalJobChanged} />}
                  name={'isCurrentJob'}
                  label="I am currently working in this role"
                />
              </FormGroup>
            </Grid>
          </DateGridContainer>

          <DialogActions>
            <ContainedButton type={'submit'} text="Save" />
          </DialogActions>
        </DialogContent>
      </form>
    </Dialog>
  );
};
