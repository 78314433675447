import React, { FC } from 'react';
import { StyledSvgIcon } from '../components/shared/image';

export const ANewTeamSvg: FC = () => {
  return (
    <StyledSvgIcon xmlns="http://www.w3.org/2000/svg" width="146.613" height="152.5" viewBox="0 0 146.613 152.5">
      <g id="Group_142" data-name="Group 142" transform="translate(-547.93 -349.567)">
        <path
          id="Background_Shape"
          data-name="Background Shape"
          d="M0,67.056c.129-31.4,74.01-88.851,89.416-58.443s14.4,92.936-14.729,105.983S-.128,98.457,0,67.056Z"
          transform="matrix(-0.848, -0.53, 0.53, -0.848, 632.126, 502.067)"
          fill="#eff1ff"
          opacity="0.456"
        />
        <path
          id="Fill_173"
          data-name="Fill 173"
          d="M16.525.01Q12.608.054,8.7.3A59.594,59.594,0,0,0,1.091.819C.4.955-.536,1.586.384,2.28c.647.488,3.014-.073,3.787-.121,2.845-.175,5.692-.384,8.542-.485,2.7-.1,5.4-.129,8.094-.156A6.141,6.141,0,0,1,22.313,1.5c.59.172.45.219.494.967.08,1.356.058,2.717.031,4.074-.026,1.313-.075,2.627-.07,3.941,0,.937-.3,2.8.174,3.614.677,1.172,1.379-.1,1.43-.88.1-1.489.053-3,.054-4.488,0-1.4.01-2.8-.039-4.2-.036-1.011.109-2.775-.436-3.694C23.4-.1,22.062.1,21.088.059c-1.52-.07-3.042-.065-4.563-.049"
          transform="translate(589.259 397.9)"
          fill="#89c5cc"
        />
        <path
          id="Fill_171"
          data-name="Fill 171"
          d="M.426.795c0,2.63-.3,5.243-.387,7.87a13.455,13.455,0,0,0,.187,3.72c.308,1.2,1.288,1.457,1.4.12.229-2.706.172-5.513.35-8.241.063-.963.472-2.728.09-3.63C1.731-.162.424-.314.426.795"
          transform="translate(588.743 398.805)"
          fill="#89c5cc"
        />
        <path
          id="Fill_175"
          data-name="Fill 175"
          d="M24.214,0C19.679.6,14.938.385,10.37.48,8.178.525,5.988.528,3.8.551,3.031.559,1.088.268.427.7c-1.053.685.09,1.213.787,1.228,4.325.095,8.7-.1,13.023-.246,1.943-.064,3.892-.095,5.83-.26A20.548,20.548,0,0,0,23.721.9c.247-.068.784-.054.84-.391.034-.208-.028-.548-.347-.5"
          transform="translate(589.443 411.188)"
          fill="#89c5cc"
        />
        <path
          id="Fill_177"
          data-name="Fill 177"
          d="M5.694.133C4.433.14.635-.445.113.784c-.7,1.639,2.019,1.257,2.749,1.238,1.658-.043,3.315-.047,4.974-.046,1.628,0,3.255-.01,4.882-.048.471-.011,1.569.184,1.84-.383a.715.715,0,0,0-.337-1c-.915-.55-3.291-.231-4.362-.28C8.473.2,7.082.133,5.694.133"
          transform="translate(589.104 414.883)"
          fill="#89c5cc"
        />
        <path
          id="Fill_179"
          data-name="Fill 179"
          d="M3.268.082C2.547.125,1.09-.172.445.157-.288.532-.03,1.119.6,1.377a6.226,6.226,0,0,0,2.665-.122c.445-.216.949-1.231,0-1.173"
          transform="translate(606.865 415.236)"
          fill="#89c5cc"
        />
        <path
          id="Fill_181"
          data-name="Fill 181"
          d="M.634,0C-.135.214.1,4.582.079,5.274c-.054,1.9-.268,4.283.363,6.11.4,1.165,1.174,1.007,1.043-.219-.116-1.076-.314-2.14-.4-3.223A50.11,50.11,0,0,1,.81.422c.078.143.808,1.465.98,1.366C1.737,1.819.947-.08.634,0"
          transform="translate(617.396 399.854)"
          fill="#89c5cc"
        />
        <path
          id="Fill_183"
          data-name="Fill 183"
          d="M9.741.024C8.184.047,6.627.074,5.07.118,3.833.152,1.888-.051.753.469c-1.351.62-.71,2,.559,2.03,1.123.026,2.32-.227,3.454-.266,3.061-.1,6.127-.127,9.189-.153,2.769-.024,5.656.272,8.4-.144.407,3.615-.694,7.246-.32,10.9.058.568.032,1.37.755,1.5.912.163.95-.74.984-1.387.1-1.847.045-3.7.181-5.546a26.916,26.916,0,0,0,.164-5.183C23.863.108,22.851.278,21.061.19,17.281,0,13.525-.032,9.741.024"
          transform="translate(616.944 398.225)"
          fill="#89c5cc"
        />
        <path
          id="Fill_185"
          data-name="Fill 185"
          d="M2.815.045C2.022.065.7-.18.2.581c-.529.792.1,1.482.868,1.644a15.927,15.927,0,0,0,3.042-.074c.984,0,1.968.008,2.952.012,4.219.017,8.455.08,12.671-.094.589-.024,2.26.173,2.667-.392.362-.5.109-.95-.368-1.205C21.247.054,19.642.213,18.773.18,17.7.139,16.625.125,15.552.109,11.313.043,7.053-.06,2.815.045"
          transform="translate(618.652 411.042)"
          fill="#89c5cc"
        />
        <path
          id="Fill_187"
          data-name="Fill 187"
          d="M5.93,0A36.548,36.548,0,0,0,1.1.2C-.27.408-.44,1.736,1.04,1.891A27.825,27.825,0,0,0,5.414,1.68c.485-.031,1.049.013,1.282-.523A.862.862,0,0,0,5.93,0"
          transform="translate(619.105 414.746)"
          fill="#89c5cc"
        />
        <path
          id="Fill_189"
          data-name="Fill 189"
          d="M3.816.109C2.973.13.512-.232.095.73c-.695,1.6,2.637.994,3.276.97.806-.03,3.945.441,3.946-.842,0-1.258-2.73-.769-3.5-.749"
          transform="translate(633.154 414.775)"
          fill="#89c5cc"
        />
        <path
          id="Fill_191"
          data-name="Fill 191"
          d="M.059.993c0,2.915-.179,5.857.087,8.764.11,1.2.091,3.506.788,4.525.494.724,1.18.673,1.45-.2a8.941,8.941,0,0,0-.14-2.364c-.031-.6-.067-1.192-.095-1.789C2.077,8.4,2.082,6.863,2.1,5.325A19.79,19.79,0,0,0,2.14.993C1.861-.189.059-.466.059.993"
          transform="translate(588.974 421.854)"
          fill="#69a1ac"
        />
        <path
          id="Fill_193"
          data-name="Fill 193"
          d="M28.808.1C23.751.225,18.683.112,13.63.362c-2.492.123-4.974.394-7.453.665-1.218.133-2.435.27-3.651.42C2.159,1.492-.89,1.61.256,2.77c.5.506,2.094-.023,2.725-.1,1.221-.144,2.444-.27,3.667-.4,2.326-.246,4.656-.479,6.992-.608,5.5-.3,11.027-.213,16.535-.417,1.49-.055,2.975-.094,4.466-.036.391.015,3.267.161,2.019-.9-.59-.5-2.817-.243-3.555-.258-1.432-.03-2.865.009-4.3.045"
          transform="translate(589.518 420.185)"
          fill="#69a1ac"
        />
        <path
          id="Fill_195"
          data-name="Fill 195"
          d="M0,1.222c.147,3.216.086,6.441.313,9.654A21.89,21.89,0,0,0,.7,15c.436,1.549,1.772,1.034,2.031-.273.583-2.942.031-6.271-.055-9.245C2.642,4.195,3.018,1.9,2.44.737S-.063-.2,0,1.222"
          transform="translate(625.107 419.933)"
          fill="#69a1ac"
        />
        <path
          id="Fill_197"
          data-name="Fill 197"
          d="M35.536,0c-2.453.266-5.008.226-7.479.377s-4.944.076-7.419.041A145.044,145.044,0,0,0,5.23.724C4.042.835-.115.832,0,2.489c.1,1.375,1.7.829,2.533.67a29.324,29.324,0,0,1,3.517-.444c5.349-.367,10.728-.243,16.085-.376a64.794,64.794,0,0,0,8.045-.716c1.345-.2,2.677-.357,4.034-.449.446-.03,1.464.083,1.74-.323.2-.3.005-.9-.419-.848"
          transform="translate(590.245 434.813)"
          fill="#69a1ac"
        />
        <path
          id="Fill_199"
          data-name="Fill 199"
          d="M1.032.2A1.043,1.043,0,0,0,.084,1.624c.384.934,1.528.569,2.284.51,2.4-.188,4.911.188,7.284-.1.648-.078,1.589-.431,1.007-1.3C10.222.082,8.628.2,7.951.134A36.143,36.143,0,0,0,1.032.2"
          transform="translate(628.281 421.988)"
          fill="#c1dee2"
        />
        <path
          id="Fill_201"
          data-name="Fill 201"
          d="M3.607.033C2.676.1.507.047.114,1.107c-.7,1.9,2.054,1.232,2.894,1.124.976-.126,3.276.211,3.8-.735C7.733-.2,4.424-.023,3.607.033"
          transform="translate(629.761 426.232)"
          fill="#c1dee2"
        />
        <path
          id="Fill_203"
          data-name="Fill 203"
          d="M.332.411a1.222,1.222,0,0,0,0,1.674c.542.541,1.723.429,2.435.469.993.056,3.846.581,4.339-.589C7.772.386,5.188.42,4.339.33,3.356.227,1.146-.4.332.411"
          transform="translate(630.64 430.029)"
          fill="#c1dee2"
        />
        <path
          id="Fill_205"
          data-name="Fill 205"
          d="M0,1.275a1.326,1.326,0,0,0,2.65,0,1.326,1.326,0,0,0-2.65,0"
          transform="translate(596.152 402.151)"
          fill="#89c5cc"
        />
        <path
          id="Fill_207"
          data-name="Fill 207"
          d="M0,1.457a1.51,1.51,0,0,0,3.018,0A1.51,1.51,0,0,0,0,1.457"
          transform="translate(601.178 401.989)"
          fill="#89c5cc"
        />
        <path
          id="Fill_209"
          data-name="Fill 209"
          d="M.657.026C-.81.431.542,1.675,1.223,1.959a4.137,4.137,0,0,0,3.643.107c.431-.269.781-.627.465-1.15C4.995.359,4.534.607,4.022.658a4.018,4.018,0,0,1-1.8-.153C1.754.35,1.167-.114.657.026"
          transform="translate(597.481 405.999)"
          fill="#89c5cc"
        />
        <path
          id="Fill_211"
          data-name="Fill 211"
          d="M0,.861a.891.891,0,0,0,1.782,0A.891.891,0,0,0,0,.861"
          transform="translate(624.75 402.674)"
          fill="#89c5cc"
        />
        <path
          id="Fill_213"
          data-name="Fill 213"
          d="M0,.956a.99.99,0,0,0,1.98,0A.99.99,0,0,0,0,.956"
          transform="translate(628.691 402.751)"
          fill="#89c5cc"
        />
        <path
          id="Fill_215"
          data-name="Fill 215"
          d="M4.23.056C3.19.19.183.494.015,1.776-.245,3.754,3.05,2.153,3.8,2a10.9,10.9,0,0,1,2.77-.108C7.1,1.911,7.8,2,7.741,1.2,7.65-.117,5.139-.061,4.23.056"
          transform="translate(624.314 406.419)"
          fill="#89c5cc"
        />
        <path
          id="Fill_217"
          data-name="Fill 217"
          d="M1.366.026c-.754.2-1.74,1.5-1.221,2.3.85,1.308,1.225-.7,1.581-.893.647-.352,1.173.933,1.85.259C4.643.629,2.038-.154,1.366.026"
          transform="translate(601.034 424.685)"
          fill="#69a1ac"
        />
        <path
          id="eye"
          d="M2.925,0C2.031.008.144,1.274.011,2.208A.924.924,0,0,0,.9,3.3c.815.01,1.074-1.05,1.78-1.271.762-.239.649.311,1.307.6a.854.854,0,0,0,1.251-.471C5.552,1.042,3.844-.017,2.925,0"
          transform="translate(608.111 423.743)"
          fill="#69a1ac"
        />
        <path
          id="Fill_221"
          data-name="Fill 221"
          d="M.451.138C-.978.976,1.374,2.771,2.189,3c1.231.339,3.709-.294,4-1.8A.8.8,0,0,0,5.271.165c-.544.088-.893.771-1.4,1.015A1.559,1.559,0,0,1,2.013.991C1.542.586,1.29-.351.451.138"
          transform="translate(603.743 428.955)"
          fill="#69a1ac"
        />
        <path
          id="Fill_223"
          data-name="Fill 223"
          d="M12.407.122C11.13.394,9.831.212,8.548.468c-1.309.261-2.091.756-3.307.258L5.07.653A2.9,2.9,0,0,0,2.157.774C1.574,1.156-.2,2.478.018,3.285.48,5.034,2.471,2.291,2.979,2.024c.968-.51,1.632.142,2.629.325,1.194.22,2.155-.411,3.28-.662,1.316-.294,2.8-.4,4.134-.554.529-.062,2.532.356,2.712-.3.311-1.13-2.825-.816-3.327-.71"
          transform="translate(592.823 441.351)"
          fill="#c1dee2"
        />
        <path id="Path" d="M.051.056h0" transform="translate(614.395 444.938)" fill="#c1dee2" />
        <path
          id="Path-2"
          data-name="Path"
          d="M4.011.366C5.1.255,8.6-.526,9.321.6c.468.736-.275,3.452-.983,3.8a7,7,0,0,1-2.506-.052C4.676,4.315,3.518,4.3,2.364,4.225,1.976,4.2.73,4.272.427,3.929.068,3.525.084,1.368,0,.833c1.005.325,2.96-.36,4.011-.467"
          transform="translate(614.013 441.037)"
          fill="#c1dee2"
        />
        <path
          id="Path-3"
          data-name="Path"
          d="M7.741.023c-1.4.061-2.8.166-4.191.354-.775.1-1.56.383-2.275.515C1.044.935.67.817.387.953c-.827.4-.1,3.1.1,3.821C.79,5.882,1.56,6.061,2.64,6.166a39.706,39.706,0,0,0,4.3.077c1.375-.017,3.229.288,4-1.092.5-.892,1.034-2.969.607-3.964C10.987-.121,8.893-.025,7.741.023"
          transform="translate(612.617 440.272)"
          fill="#c1dee2"
        />
        <path id="Path-4" data-name="Path" d="M.1.036h0" transform="translate(635.941 445.701)" fill="#89c5cc" />
        <path
          id="Path-5"
          data-name="Path"
          d="M.1.035C.081.07-.027.183.1.035"
          transform="translate(636.244 445.332)"
          fill="#89c5cc"
        />
        <path
          id="Path-6"
          data-name="Path"
          d="M.348.344Q1.274.17,2.2,0C1.616.819,1,.481,0,.409L.348.344"
          transform="translate(632.66 444.922)"
          fill="#89c5cc"
        />
        <path
          id="Path-7"
          data-name="Path"
          d="M.257.157.726,0A.64.64,0,0,0,.8.334Q.4.291,0,.242L.257.157"
          transform="translate(629.806 444.818)"
          fill="#89c5cc"
        />
        <path
          id="Path-8"
          data-name="Path"
          d="M0,.118C-.007-.1.317.05.5.036Q-.034.508,0,.118"
          transform="translate(628.696 443.85)"
          fill="#89c5cc"
        />
        <path
          id="Path-9"
          data-name="Path"
          d="M.077.05q.07-.09.187.009C.179.087-.146.225.077.05"
          transform="translate(633.502 441.48)"
          fill="#89c5cc"
        />
        <path
          id="Path-10"
          data-name="Path"
          d="M.03.019c.008,0,.184.237,0,0"
          transform="translate(636.715 441.139)"
          fill="#89c5cc"
        />
        <path id="Path-11" data-name="Path" d="M.055.059h0" transform="translate(636.601 441.021)" fill="#89c5cc" />
        <path
          id="Path-12"
          data-name="Path"
          d="M0,0C.647.109.6.869,0,0"
          transform="translate(636.261 441.033)"
          fill="#89c5cc"
        />
        <path
          id="Path-13"
          data-name="Path"
          d="M.113.047c-.132.109-.087,0,0,0"
          transform="translate(628.544 440.935)"
          fill="#89c5cc"
        />
        <path
          id="Path-14"
          data-name="Path"
          d="M4.755.009c-1.1.064-3.116,0-4.033.585-1.2.764-.567,3.395-.46,4.548.158,1.7.96,2.19,2.566,2.369a42.661,42.661,0,0,0,5.81.2c1.734-.046,2.722-.642,3.04-2.468a13.232,13.232,0,0,0,.336-1.958c-.005-.371-.19-.54-.26-.856-.4-1.8-1.064-2.01-2.906-2.089C7.49.285,6.085-.06,4.755.009"
          transform="translate(626.377 439.355)"
          fill="#89c5cc"
        />
        <path
          id="Fill_1"
          data-name="Fill 1"
          d="M.836,7.52a18.833,18.833,0,0,0,.471,12.466A13.3,13.3,0,0,0,9.851,27.9a9.248,9.248,0,0,0,6.583-.661,8.645,8.645,0,0,0,4.3-5.446,7.919,7.919,0,0,0-1.448-6.949c-1.162-1.35-2.818-2.133-3.819-3.633-1.716-2.572-1-6.436-2.71-9.016A5.45,5.45,0,0,0,5.579.745C3.217,2.069,1.675,4.739.836,7.52"
          transform="translate(579.466 411.011)"
          fill="#393762"
        />
        <path
          id="Fill_3"
          data-name="Fill 3"
          d="M3.218,2.923h0S8.745,5.359,8.806,5.915H.568L0,0Z"
          transform="translate(597.682 493.895)"
          fill="#ece0d3"
        />
        <path
          id="Fill_7"
          data-name="Fill 7"
          d="M15.441,13.014s-.886,8.866-1.054,9.206S.988,34.612.988,34.612L0,32.581,9.366,17.675,8.016,0Z"
          transform="translate(574.158 453.876)"
          fill="#e48868"
        />
        <path
          id="Fill_9"
          data-name="Fill 9"
          d="M1.5,0c3.4.381,6.849.686,9.827,2.373l-.81,2.905S15.2,20.234,15.368,21.484,19.06,51.119,19.06,51.119L17.2,50.853,9.364,23.123S-.455,10.282.016,5.221A14.049,14.049,0,0,1,1.5,0"
          transform="translate(580.431 444.558)"
          fill="#e48868"
        />
        <path
          id="Fill_11"
          data-name="Fill 11"
          d="M13.752,15.6C12.34,10.972,10.52,5.222,10.52,5.222l.811-2.873C8.352.679,4.9.377,1.5,0A13.786,13.786,0,0,0,.016,5.166c-.148,1.57.718,3.9,1.973,6.349l.6,7.655a33.6,33.6,0,0,0,3.548-.793l0,.006c.132-.037.263-.082.394-.121.147-.043.3-.082.442-.126l0-.007Q8.7,17.611,10.4,16.986c1.118-.414,2.235-.9,3.356-1.383"
          transform="translate(580.431 444.824)"
          fill="#de6879"
        />
        <path
          id="Fill_13"
          data-name="Fill 13"
          d="M6.1,14.644s-.417-4.1.262-4.762c.443-.43,1.916.242,2.327-.439A15.641,15.641,0,0,0,8.5,6.67c-.023-.259.706.057.671-.227C9.125,6.111,7.9,4.754,7.945,4.4,8.564-1.132.238-1.285.011,3.03A7.755,7.755,0,0,0,3.356,9.971L2,12.848Z"
          transform="translate(583.085 412.342)"
          fill="#e48868"
        />
        <path
          id="Fill_15"
          data-name="Fill 15"
          d="M2.806,0,6.4,6.525s7.7,4.335,8.15,4.508A15.882,15.882,0,0,1,17.569,13.9c.277.511-.869.72-1.084.718a4.058,4.058,0,0,1-.9-.347s-1.366.933-1.431.909-.977-2.239-.977-2.239S4.2,10.367,3.493,9.7,0,1.487,0,1.487Z"
          transform="translate(588.875 436.837)"
          fill="#e48868"
        />
        <path
          id="Fill_17"
          data-name="Fill 17"
          d="M.946,0,4.621,8.56s3.554-.825,4.805.916a8.79,8.79,0,0,0,2.637,2.485L11.7,14.377s-9.036-.885-10.416-2.528S0,1.277,0,1.277Z"
          transform="translate(590.323 435.506)"
          fill="#efc33b"
        />
        <path
          id="Fill_19"
          data-name="Fill 19"
          d="M3.911,4.526,4.825,2.74,1.238,0,0,2.027Z"
          transform="translate(585.256 422.459)"
          fill="#ece0d3"
        />
        <path
          id="Fill_21"
          data-name="Fill 21"
          d="M3.832,2.6S6.152-.986,8.672.264C9.3.576,14.221,7.594,14.316,8.78s-1.955,5.293-1.955,5.293l2.115,13.884L0,23.55l3.205-8.532S2.187,10.362,3.832,2.6"
          transform="translate(578.742 424.057)"
          fill="#efc33b"
        />
        <path
          id="Fill_23"
          data-name="Fill 23"
          d="M2.759,4.526a.132.132,0,0,1-.11-.062C1.681,2.982.816,1.639.02.226a.157.157,0,0,1,.045-.2A.129.129,0,0,1,.252.071c.791,1.4,1.653,2.742,2.618,4.22a.158.158,0,0,1-.031.207.128.128,0,0,1-.079.028"
          transform="translate(582.844 457.338)"
          fill="#c55469"
        />
        <path
          id="Fill_70"
          data-name="Fill 70"
          d="M16.165,3.2,2.546,0,0,10.968l13.9,3.675c.9-3.788,1.19-7.715,2.262-11.446"
          transform="translate(575.847 440.564)"
          fill="#ece0d3"
        />
        <path
          id="Fill_72"
          data-name="Fill 72"
          d="M2.8,4.57c-.318-.383.028-1.537.315-2.191C2.7,1.863,2.472.557,2.1,0,1.045,3.714.123,5.359.067,5.837-.055,6.9-.083,7.487.633,7.383s.508,1.1,1.087.884.519,1.184,1.038.673.174-2.087.174-2.087S4.019,6.042,2.8,4.57"
          transform="translate(580.672 447.753)"
          fill="#e48868"
        />
        <path
          id="Fill_74"
          data-name="Fill 74"
          d="M1.719.283C.444,2.132,0,25.062,0,25.062l3.179.5s.547-5.051,1.323-6.636a4.264,4.264,0,0,0,.31-4.191C4.142,12.75,6.764,7.5,6.755,4.923S2.6-1,1.719.283"
          transform="translate(580.672 426.187)"
          fill="#efc33b"
        />
        <path
          id="Fill_76"
          data-name="Fill 76"
          d="M1.313,9.851a.14.14,0,0,1-.133-.127L0,1.535A.175.175,0,0,1,.01,1.453L.512.093A.129.129,0,0,1,.688.012a.157.157,0,0,1,.072.2L.273,1.529,1.446,9.676a.151.151,0,0,1-.111.173l-.022,0"
          transform="translate(591.047 436.837)"
          fill="#e8a12b"
        />
        <path
          id="Fill_78"
          data-name="Fill 78"
          d="M.72,12.514H.707a.146.146,0,0,1-.126-.163l.746-8.02a7.733,7.733,0,0,0-.016-2.325A2.335,2.335,0,0,0,.087.286.152.152,0,0,1,.01.093.137.137,0,0,1,.192.011,2.623,2.623,0,0,1,1.585,1.94a7.974,7.974,0,0,1,.022,2.419L.86,12.38a.143.143,0,0,1-.14.134"
          transform="translate(585.015 427.518)"
          fill="#e8a12b"
        />
        <path
          id="Fill_80"
          data-name="Fill 80"
          d="M2.511,3.994A.138.138,0,0,1,2.4,3.928L.027.253A.173.173,0,0,1,.059.03a.135.135,0,0,1,.2.036L2.628,3.741a.174.174,0,0,1-.033.223.136.136,0,0,1-.084.03"
          transform="translate(586.945 463.461)"
          fill="#ba745d"
        />
        <path
          id="Fill_82"
          data-name="Fill 82"
          d="M4.7,10.117A16.607,16.607,0,0,0,10.374,3.4L8.527.434S1.971-1.672.293,3.4s4.4,6.721,4.4,6.721"
          transform="translate(581.637 412.342)"
          fill="#393762"
        />
        <path
          id="Fill_5"
          data-name="Fill 5"
          d="M6.126,2.719,4.191,4.535s.277,6.053-.053,6.45L0,3.363,4.144,0Z"
          transform="translate(569.721 485.826)"
          fill="#ece0d3"
        />
        <g id="Head" transform="translate(647.798 411.868) rotate(-8)">
          <path
            id="Head-2"
            data-name="Head"
            d="M0,0,1.616,8.286a6.178,6.178,0,0,0-1.41,4.188c.294,4.6,6.122,3.592,7.256,1.268s1-8.219-.466-8.635a4.85,4.85,0,0,0-3.1,1.08L4.7,0Z"
            transform="translate(8.217 15.708) rotate(180)"
            fill="#b28b67"
          />
        </g>
        <g id="Hijab" transform="translate(648.505 411.278) rotate(-8)">
          <path
            id="Front"
            d="M1.03.433A5.213,5.213,0,0,1,1.477.159C1.5.146,1.693,0,1.7,0c.143.053.557.249.593.418.006.013.011.025.016.035.059.117.113.238.164.359.113.267.21.546.3.823.181.539.338,1.092.483,1.645s.272,1.133.412,1.7c.2.814.459,1.608.659,2.421a5.226,5.226,0,0,1,.165,1.58c0,.006,0,.013,0,.019v.008q-.009.123-.025.247c-.009.071-.019.142-.029.212-.183-.4-.322-.841-.47-1.26-.014-.041-.028-.082-.042-.124q-.072-.127-.145-.253c-.31-.53-.621-1.057-.919-1.6a29.191,29.191,0,0,0-1.7-2.85C.975,3.121.783,2.862.6,2.6q-.158-.226-.307-.46c-.071-.112-.379-.5-.267-.734A5.961,5.961,0,0,1,1.03.433Z"
            transform="translate(0 0.486)"
            fill="#2c2c2c"
          />
          <path
            id="Turban"
            d="M.058.665C-.047.7,0,.464.105.38.575-.01,2.369-.152,3.93.2a7.293,7.293,0,0,1,3.1,1.6,5.9,5.9,0,0,1,1.538,2.86A9.117,9.117,0,0,1,8.733,8.1c-.034.252-.108.493-.149.74a6.234,6.234,0,0,1-.152.817c-.05.166-.136.328-.179.495a1.247,1.247,0,0,0,.017.559,6.6,6.6,0,0,0,.234.931c.154.452.342.875.46,1.344.156.624.811,2.655.333,3.209H.193A19.956,19.956,0,0,0,1.25,13.961c.082-.434.121-1.38.13-1.415A4.923,4.923,0,0,1,2.236,9.98c.251-.269.585-.352.6-.586A8.153,8.153,0,0,0,2.3,6.6C2,5.523,1.774,4.421,1.459,3.349,1.319,2.874.6.464.058.665Z"
            transform="translate(1.385 0)"
            fill="#8991dc"
          />
          <path
            id="Shade"
            d="M1.091,3.286l.138.184C3.108,2.823,2.9,1.486,5.266.132L5.068,0C3,.941,2.511,2.959,1.091,3.286Zm1.521,7.183.15.013A13.747,13.747,0,0,1,4.651,4.026L4.49,3.972A10.943,10.943,0,0,0,2.612,10.469ZM0,8.674l.135.071a7.285,7.285,0,0,1,2.717-4.1l-.138-.106A5.853,5.853,0,0,0,0,8.674Zm4.227-.311a5.377,5.377,0,0,0,.3,3.473h.212A8.888,8.888,0,0,1,4.4,8.363Z"
            transform="translate(9.373 13.613) rotate(180)"
            fill="rgba(0,0,0,0.2)"
          />
        </g>
        <path
          id="Leg"
          d="M13.993,0,6.487,24.7l-3.636,21H0L3.842,0Z"
          transform="translate(644.485 448.004)"
          fill="#997659"
        />
        <path
          id="Leg-2"
          data-name="Leg"
          d="M11.309,0c-.127,3.39-.186,6.37-.2,8.952s.017,4.766.067,6.565.12,3.213.184,4.253.123,1.709.152,2.017.148,1.038.368,2.2.542,2.744.976,4.764.979,4.473,1.648,7.366,1.46,6.225,2.384,10l-3.038-1.05c-1.461-3.464-2.682-6.512-3.7-9.164s-1.837-4.908-2.494-6.788S6.5,25.73,6.133,24.583s-.623-1.939-.79-2.394-.4-1.258-.711-2.41-.682-2.653-1.127-4.5-.96-4.047-1.544-6.593S.723,3.243,0,0Z"
          transform="translate(649.855 448.004)"
          fill="#b28b67"
        />
        <g id="Left_Shoe" data-name="Left Shoe" transform="translate(655.087 487.955)">
          <path
            id="shoe"
            d="M10.639.944a.4.4,0,0,1,.313.025.4.4,0,0,1,.2.246l.381,1.35c0,.136-.011.3-.028.477s-.039.355-.062.519-.045.311-.062.418-.028.171-.028.171H.1S.074,4.058.048,3.92A2.525,2.525,0,0,1,0,3.405a1.1,1.1,0,0,1,.13-.536.61.61,0,0,1,.478-.3l.644-.082.5-.067.318-.045.112-.016L7.407.017a.2.2,0,0,1,.22.04.2.2,0,0,1,.045.067l.336.821s.055.026.145.064.217.09.36.141a4.66,4.66,0,0,0,.463.141,1.994,1.994,0,0,0,.453.064Z"
            transform="translate(0.436 3.881)"
            fill="#e4e4e4"
          />
        </g>
        <g id="Right_Shoe" data-name="Right Shoe" transform="translate(636.337 487.955)">
          <path
            id="shoe-2"
            data-name="shoe"
            d="M10.639.944a.4.4,0,0,1,.313.025.4.4,0,0,1,.2.246l.381,1.35c0,.136-.011.3-.028.477s-.039.355-.062.519-.045.311-.062.418-.028.171-.028.171H.1S.074,4.058.048,3.92A2.525,2.525,0,0,1,0,3.405a1.1,1.1,0,0,1,.13-.536.61.61,0,0,1,.478-.3l.644-.082.5-.067.318-.045.112-.016L7.407.017a.2.2,0,0,1,.22.04.2.2,0,0,1,.045.067l.336.821s.055.026.145.064.217.09.36.141a4.66,4.66,0,0,0,.463.141,1.994,1.994,0,0,0,.453.064Z"
            transform="translate(0.436 3.881)"
            fill="#e4e4e4"
          />
        </g>
        <path id="Bottom" d="M12.939,0,9.748,12.584H0L1.453,0Z" transform="translate(646.536 448.004)" fill="#69a1ac" />
        <path
          id="Bottom-2"
          data-name="Bottom"
          d="M12.084,0a18.237,18.237,0,0,1,.758,3.971,37.593,37.593,0,0,1,.132,4.1c-.024,1.276-.1,2.4-.163,3.212s-.128,1.3-.128,1.3H2.735L0,0Z"
          transform="translate(649.186 448.004)"
          fill="#89c5cc"
        />
        <path
          id="Skin"
          d="M.238.465a.7.7,0,0,0,.12,1.141,19.122,19.122,0,0,1,3.77,3.611C4.7,6.5,7.544,22.96,7.544,22.96l4.257-.018S6.938,5.5,6.638,4.68c-.45-1.234.5-3.113.909-4.123-.642-.318-.94.088-1.266.494a1.1,1.1,0,0,1-1.591.42C3.586.9,2.673,0,1.584,0A2.355,2.355,0,0,0,.238.465Zm38.134,9.873-5.727,3.737,1.829,3.5,5.507-5.946a16.389,16.389,0,0,0,3.939-2.506c.23-.307.481-.9-.6-.632s-2.233.487-2.436.1.4-.987.02-1.5a.138.138,0,0,0-.115-.061Q40.269,7.032,38.372,10.339Z"
          transform="translate(676.471 455.527) rotate(172)"
          fill="#b28b67"
        />
        <path
          id="Clothes_Back"
          data-name="Clothes Back"
          d="M0,23.952l1.647.464C5.188,14.326,19.358,8.476,20.528,4.691c.429-1.388-.429-2.184.084-2.573L18.59.164c-.41.444-1.121-.5-2.817-.03S1.069,11.731,0,23.952Z"
          transform="translate(656.487 445.749) rotate(167)"
          fill="#db2721"
        />
        <path
          id="Clothes_Front"
          data-name="Clothes Front"
          d="M13.1,31.017C9.929,28.314,2.1,20.263.062,5.29-.3,2.639,1.049,2.061.854.874L3.714,0c0,.874,2.171,1.234,2.731,3.5.314,1.274.775,2.864,1.325,4.634A15.081,15.081,0,0,1,9.1,1.851c2.182-4.454,14.8.431,16.114,4.975,1.613,5.564-5.729,9.079-5.365,23.179a2.3,2.3,0,0,1,.034.922c0,.056.005.112.008.167l-.045.009a2.283,2.283,0,0,1-2.056,1.723l-2.266.158c-.054,0-.108.006-.162.006A2.284,2.284,0,0,1,13.1,31.017Z"
          transform="translate(672.247 450.665) rotate(172)"
          fill="#ff4133"
        />
      </g>
    </StyledSvgIcon>
  );
};
