import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputAdornment,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { ButtonStyledText } from '../shared/typography';
import React, { FC } from 'react';
import { RadioGroup } from '../shared/radioGroup';
import { DepartmentMultiAutocomplete } from '../shared/select';
import {
  IGetUserSettingsResponse,
  IUpdateUserSettingsRequest,
  StretchLevel,
} from '../../services/generated/ApiClientGenerated';
import { concatenateStringAndUppercaseFirstLetter } from '../../services/shared/stringHelpers';
import { SerializableIGetUserSettingsResponse } from '../../redux/settingsSlice';
import { AttachMoney, InfoOutlined } from '@mui/icons-material';

export type OnChangeType = {
  onChange: (value: SerializableIGetUserSettingsResponse) => void;
  onSwitchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onAutocompleteChange: (key: keyof IUpdateUserSettingsRequest, value: any) => void;
  onSliderChange: (
    keyOne: keyof IUpdateUserSettingsRequest,
    keyTwo: keyof IUpdateUserSettingsRequest,
    value: (number | undefined)[],
  ) => void;
  value: SerializableIGetUserSettingsResponse;
};

type MoveOptionProps = {
  label: string;
  value: StretchLevel[];
  onChange: React.ChangeEventHandler<HTMLInputElement>;
};

const MoveOption: FC<MoveOptionProps> = ({ label, value, onChange }) => (
  <Grid container item sm={12} key={`stretch-level-${label}`} alignItems="center">
    <FormControlLabel
      control={
        <Checkbox
          checked={value.includes(concatenateStringAndUppercaseFirstLetter(label) as StretchLevel) ?? false}
          value={label}
          onChange={onChange}
          name={concatenateStringAndUppercaseFirstLetter(label)}
        />
      }
      label={label}
    />
    {/*<Tooltip title={tooltip}>*/}
    {/*  <InfoOutlined fontSize="small" />*/}
    {/*</Tooltip>*/}
  </Grid>
);

export const JobSearchPreferences: FC<OnChangeType> = ({ onAutocompleteChange, onChange, onSwitchChange, value }) => {
  function handleChange<K extends keyof IGetUserSettingsResponse>(key: K, newValue: IGetUserSettingsResponse[K]) {
    onChange({
      ...value,
      [key]: newValue,
    });
  }

  const handleStretchLevelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let currentStretchLevels = Array.from(value.stretchLevels ?? []);
    if (event.target.checked) {
      currentStretchLevels.push(event.target.name as StretchLevel);
    } else {
      currentStretchLevels = currentStretchLevels.filter((s) => s !== event.target.name);
    }
    onChange({
      ...value,
      stretchLevels: currentStretchLevels,
    });
  };

  return (
    <Grid container item xs={8} direction={'column'} px={2}>
      <Grid container item>
        <ButtonStyledText>Job Search Preferences</ButtonStyledText>
      </Grid>

      <Grid container item mt={2} spacing={2}>
        <Grid item xs={6}>
          <DepartmentMultiAutocomplete
            onChange={(value) => onAutocompleteChange('myDepartmentInterests', value)}
            value={value?.myDepartmentInterests ?? []}
            label={'My Department Interests'}
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Switch checked={value?.isInterestedInNewRoles} onChange={onSwitchChange} name="isInterestedInNewRoles" />
            }
            label="Interested in new roles"
          />
          <Tooltip
            title={
              "Add yourself to the mix for new opportunities by flipping this switch on to receive notifications. If it's switched off, you won't be alerted about jobs that are a good fit for you. Unless you've been in a position for less than nine months, we recommend leaving this switch on."
            }
          >
            <InfoOutlined fontSize="small" />
          </Tooltip>
        </Grid>
      </Grid>

      <Grid container item mt={3}>
        <Typography variant={'h5'}>What's my work preference?</Typography>
      </Grid>
      <Grid container item spacing={4}>
        <Grid item xs={6}>
          <RadioGroup
            onChange={(value) => handleChange('isWillingToRelocate', value === 'true')}
            value={value?.isWillingToRelocate?.toString() ?? ''}
            options={[
              {
                label: 'Yes',
                value: 'true',
              },
              {
                label: 'No',
                value: 'false',
              },
            ]}
            formLabel={`I'm willing to relocate`}
          />
        </Grid>

        <Grid item xs={6}>
          <RadioGroup
            onChange={(value) => handleChange('isInterestedInRemoteWork', value === 'true')}
            value={value?.isInterestedInRemoteWork?.toString() ?? ''}
            options={[
              {
                label: 'Yes',
                value: 'true',
              },
              {
                label: 'No',
                value: 'false',
              },
            ]}
            formLabel={`I'm interested in working remotely`}
          />
        </Grid>
      </Grid>

      <Grid container item spacing={4} mt={2}>
        <Grid item xs={6}>
          <TextField
            type={'number'}
            fullWidth
            value={value?.minSalaryRequirement ?? 35_000}
            onChange={(e) => handleChange('minSalaryRequirement', parseInt(e.currentTarget.value))}
            InputProps={{
              inputMode: 'numeric',
              startAdornment: (
                <InputAdornment position="start">
                  <AttachMoney />
                </InputAdornment>
              ),
            }}
            label={'Minimum Salary Required'}
          />
        </Grid>

        <Grid item xs={6}>
          <FormLabel>
            What type of moves are you interested in making? Choose all that apply.
            <Tooltip
              title={
                <Stack spacing={1}>
                  <Typography>
                    <b>Lateral moves</b> are jobs that require the same level of experience as your current role.
                  </Typography>

                  <Typography>
                    <b>Next level</b> moves are jobs that are an immediate growth step up from the job you currently
                    hold.
                  </Typography>

                  <Typography>
                    <b>Stretch roles</b> are roles with high visibility and greater impact expectations from senior
                    leadership.
                  </Typography>
                </Stack>
              }
            >
              <InfoOutlined />
            </Tooltip>
          </FormLabel>
          <FormGroup>
            <Grid container direction={'row'}>
              <MoveOption
                label={'Lateral Moves'}
                value={value?.stretchLevels ?? []}
                onChange={handleStretchLevelChange}
              />
              <MoveOption label={'Next Level'} value={value?.stretchLevels ?? []} onChange={handleStretchLevelChange} />
              <MoveOption
                label={'Stretch Levels'}
                value={value?.stretchLevels ?? []}
                onChange={handleStretchLevelChange}
              />
            </Grid>
          </FormGroup>
        </Grid>
      </Grid>
    </Grid>
  );
};
