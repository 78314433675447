import React, { FC } from 'react';
import { StyledModalPaperContainer } from '../../shared/grid';
import { StyledGreyCard } from '../../shared/card';
import { Grid, Tooltip } from '@mui/material';
import { CandidatePaperType } from '../../../services/shared/baseTypes';
import { tooltipInterestedText } from './primaryCandidateCard';
import Avatar from 'boring-avatars';
import LightbulbIcon from '@mui/icons-material/Lightbulb';

export const SecondaryCandidateCard: FC<CandidatePaperType> = ({ candidate }) => {
  return (
    <StyledModalPaperContainer item xs={3}>
      <StyledGreyCard>
        <Grid container justifyContent={'space-between'}>
          <Grid item>
            <Grid container item>
              <Grid item>
                <Avatar size={64} variant="bauhaus" name={candidate.id} />
              </Grid>
              {candidate.isInterested && (
                <Grid item>
                  <Tooltip placement={'right'} title={tooltipInterestedText} arrow>
                    <LightbulbIcon color={'primary'} />
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </StyledGreyCard>
    </StyledModalPaperContainer>
  );
};
