import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Button, Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { cancelConfirmAction, okConfirmAction } from '../../redux/globalSlice';
import { StyledDialogTitleWithX } from '../shared/dialog';

export const ConfirmationDialog: FC = () => {
  const dispatch = useDispatch();
  const confirmationDialog = useSelector((r: RootState) => r.global.confirmation);
  const handleOk = () => dispatch(okConfirmAction());
  const handleCancel = () => dispatch(cancelConfirmAction());
  return (
    <Dialog open={confirmationDialog.isVisible} onClose={handleCancel}>
      <StyledDialogTitleWithX onClose={handleCancel}>{confirmationDialog.title}</StyledDialogTitleWithX>
      <DialogContent>{confirmationDialog.body}</DialogContent>
      <DialogActions>
        <Grid container justifyContent={'space-between'}>
          {confirmationDialog.cancelText ? (
            <Grid item>
              <Button color="secondary" onClick={handleCancel}>
                {confirmationDialog.cancelText}
              </Button>
            </Grid>
          ) : undefined}
          <Grid item>
            <Button variant="contained" onClick={handleOk}>
              {confirmationDialog.okText}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
