import { Divider, Grid, Typography } from '@mui/material';
import React, { FC } from 'react';
import { UnderlineButton } from '../../shared/button';
import { format } from 'date-fns';
import { DescriptionContainer, MainDetailsHeaderContainer } from '../../shared/grid';
import EditIcon from '@mui/icons-material/Edit';
import { JobHistoryProps } from '../../../services/shared/baseTypes';
import { useDispatch } from 'react-redux';
import { showJobHistoryDialog } from '../../../redux/profileSlice';

export const JobHistoryCard: FC<JobHistoryProps> = ({ jobHistoryEntry }) => {
  const dispatch = useDispatch();

  return (
    <>
      <Grid container item spacing={1} direction="column">
        <MainDetailsHeaderContainer container direction="row">
          {jobHistoryEntry.companyName && (
            <>
              <Grid item xs={3}>
                <Typography variant="h6">Company Name</Typography>
                <Typography variant="body1">{jobHistoryEntry.companyName}</Typography>
              </Grid>
              <Grid item>
                <Divider orientation="vertical" />
              </Grid>
            </>
          )}

          <Grid item xs={5}>
            <Typography variant="h6">Job Title</Typography>
            <Typography variant="body1">{jobHistoryEntry.roleName}</Typography>
          </Grid>
          <Grid item>
            <UnderlineButton
              onClick={() => dispatch(showJobHistoryDialog(jobHistoryEntry))}
              text={'EDIT'}
              icon={<EditIcon fontSize={'small'} sx={{ marginLeft: 0.5 }} />}
            />
          </Grid>
        </MainDetailsHeaderContainer>
        <DescriptionContainer container direction={'column'} spacing={2}>
          {jobHistoryEntry.description && (
            <Grid item>
              <Typography variant="h6">Duties and Description</Typography>
              <Typography variant="body1">{jobHistoryEntry.description}</Typography>
            </Grid>
          )}

          {jobHistoryEntry.startDate && (
            <Grid item>
              <Typography variant="h6">Duration</Typography>
              <Typography variant="body1">
                {format(new Date(jobHistoryEntry.startDate), 'MMM dd yyyy')} -{' '}
                {jobHistoryEntry.endDate &&
                  !jobHistoryEntry.isCurrentJob &&
                  format(new Date(jobHistoryEntry.endDate), 'MMM dd yyyy')}
                {jobHistoryEntry.isCurrentJob && 'Current'}
              </Typography>
            </Grid>
          )}
        </DescriptionContainer>
      </Grid>
    </>
  );
};
