import React, { FC } from 'react';
import { Drawer, List, ListItem, ListItemIcon, styled, Tooltip } from '@mui/material';
import {
  AdminPanelSettings,
  AutoAwesomeMosaic,
  Business,
  FormatListBulleted,
  Home,
  ManageSearch,
  Person,
  Settings,
} from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import { Authenticated } from './shared/authenticated';

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  '& .MuiSvgIcon-root:hover': {
    color: theme.palette.primary.main,
  },
}));

export const SideNav: FC = () => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split('/');

  return (
    <Drawer
      variant="permanent"
      anchor={'left'}
      PaperProps={{ sx: { justifyContent: 'center', alignItems: 'center', zIndex: -1 } }}
      sx={{ zIndex: -1 }}
    >
      <List>
        <Tooltip title="Home" placement="right">
          <StyledListItem>
            <Link to={'/'}>
              <ListItemIcon sx={{ minWidth: 0 }}>
                <Home sx={splitLocation[1] === '' ? { color: 'primary.main' } : {}} />
              </ListItemIcon>
            </Link>
          </StyledListItem>
        </Tooltip>
        <Authenticated>
          <Tooltip title="Profile" placement="right">
            <StyledListItem>
              <Link to={'/profile'}>
                <ListItemIcon sx={{ minWidth: '0' }}>
                  <Person sx={splitLocation[1] === 'profile' ? { color: 'primary.main' } : {}} />
                </ListItemIcon>
              </Link>
            </StyledListItem>
          </Tooltip>
        </Authenticated>
        <Authenticated role={['HiringManager', 'Admin']}>
          <Tooltip title="Skills" placement="right">
            <StyledListItem>
              <Link to={'/skills'}>
                <ListItemIcon sx={{ minWidth: '0' }}>
                  <AutoAwesomeMosaic sx={splitLocation[1] === 'skills' ? { color: 'primary.main' } : {}} />
                </ListItemIcon>
              </Link>
            </StyledListItem>
          </Tooltip>
        </Authenticated>

        <Authenticated>
          <Tooltip title="Job Searching" placement="right">
            <StyledListItem>
              <Link to={'/job-searching'}>
                <ListItemIcon sx={{ minWidth: '0' }}>
                  <ManageSearch sx={splitLocation[1] === 'job-searching' ? { color: 'primary.main' } : {}} />
                </ListItemIcon>
              </Link>
            </StyledListItem>
          </Tooltip>
        </Authenticated>

        <Authenticated role={['HiringManager', 'Admin']}>
          <Tooltip title="Job Management" placement="right">
            <StyledListItem>
              <Link to={'/job-management'}>
                <ListItemIcon sx={{ minWidth: '0' }}>
                  <FormatListBulleted sx={splitLocation[1] === 'job-management' ? { color: 'primary.main' } : {}} />
                </ListItemIcon>
              </Link>
            </StyledListItem>
          </Tooltip>
        </Authenticated>

        <Authenticated role="Admin">
          <Tooltip title="User Management" placement="right">
            <StyledListItem>
              <Link to={'/user-management'}>
                <ListItemIcon sx={{ minWidth: '0' }}>
                  <AdminPanelSettings sx={splitLocation[1] === 'user-management' ? { color: 'primary.main' } : {}} />
                </ListItemIcon>
              </Link>
            </StyledListItem>
          </Tooltip>
        </Authenticated>
        <Authenticated role="SuperAdmin">
          <Tooltip title="Company Management" placement="right">
            <StyledListItem>
              <Link to={'/company-management'}>
                <ListItemIcon sx={{ minWidth: '0' }}>
                  <Business sx={splitLocation[1] === 'company-management' ? { color: 'primary.main' } : {}} />
                </ListItemIcon>
              </Link>
            </StyledListItem>
          </Tooltip>
        </Authenticated>

        <Authenticated>
          <Tooltip title="My Settings" placement="right">
            <StyledListItem>
              <Link to={'/settings'}>
                <ListItemIcon sx={{ minWidth: '0' }}>
                  <Settings sx={splitLocation[1] === 'settings' ? { color: 'primary.main' } : {}} />
                </ListItemIcon>
              </Link>
            </StyledListItem>
          </Tooltip>
        </Authenticated>
      </List>
    </Drawer>
  );
};
