import React, { FC, ReactElement } from 'react';
import { Button, ButtonProps, Divider, Grid, styled } from '@mui/material';

export type ButtonType = {
  text: string;
  icon?: ReactElement<any, any>;
  onOpen?: () => void;
};

export const StyledContainedButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.dark,
  borderRadius: '50px',
  width: 'fit-content',
  minWidth: '140px',
  marginTop: '1rem',
  letterSpacing: '0.125rem',
  fontSize: '1rem',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  '&:hover': {
    background: theme.palette.primary.main,
  },
}));

export const StyledDividerButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  textTransform: 'uppercase',
}));

export const StyledUnderlineButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  borderBottom: 'solid 1px',
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
}));

export const StyledIconButton = styled(Button)(({ theme }) => ({
  textTransform: 'uppercase',
  color: theme.palette.text.primary,
  '& .MuiSvgIcon-root': {
    color: theme.palette.primary.main,
    marginLeft: 5,
  },
}));

export const StyledTrashButton = styled(Button)(({ theme }) => ({
  textTransform: 'uppercase',
  color: theme.palette.error.main,
  '& .MuiSvgIcon-root': {
    color: theme.palette.error.main,
    marginRight: 5,
  },
}));

export const ContainedButton: FC<ButtonType & ButtonProps> = ({ text, ...buttonProps }) => {
  return (
    <StyledContainedButton size="large" variant={'contained'} {...buttonProps}>
      {text}
    </StyledContainedButton>
  );
};

export const DividerButton: FC<ButtonType & ButtonProps> = ({ text, onOpen, ...buttonProps }) => {
  return (
    <>
      <Divider sx={{ padding: 1 }} />
      <Grid container item justifyContent={'center'}>
        <StyledDividerButton onClick={onOpen} fullWidth {...buttonProps}>
          {text}
        </StyledDividerButton>
      </Grid>
    </>
  );
};

export const IconButton: FC<ButtonType & ButtonProps> = ({ text, onOpen, icon, ...buttonProps }) => {
  return (
    <StyledIconButton onClick={onOpen} variant="text" {...buttonProps}>
      {text} {icon}
    </StyledIconButton>
  );
};

export const TrashButton: FC<ButtonType & ButtonProps> = ({ text, onOpen, icon, ...buttonProps }) => {
  return (
    <StyledTrashButton onClick={onOpen} variant="text" {...buttonProps}>
      {icon} {text}
    </StyledTrashButton>
  );
};

export const UnderlineButton: FC<ButtonType & ButtonProps> = ({ text, onOpen, icon, ...buttonProps }) => {
  return (
    <StyledUnderlineButton onClick={onOpen} variant="text" {...buttonProps}>
      {text} {icon ?? ''}
    </StyledUnderlineButton>
  );
};
