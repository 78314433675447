import { all, call, put, race, select, take, takeLatest } from 'redux-saga/effects';
import {
  getJobRoleListAction,
  hideCandidateComparisonAction,
  jobSlice,
  ListJobsRequest,
  successGetJobRoleListAction,
  successGetJobRoleMatchesAction,
} from '../redux/jobSlice';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  AddJobRoleRequest,
  ChangeJobRoleStatusRequest,
  ListJobMatchesResponse,
  ListJobRolesResponse,
} from '../services/generated/ApiClientGenerated';
import { apiCall, apiClient } from '../services/apiClient';
import { cancelConfirmAction, okConfirmAction, showConfirmAction, showToastAction } from '../redux/globalSlice';
import { RootState } from '../store';

function* addInterestSaga(action: PayloadAction<string>) {
  try {
    yield call([apiClient, apiClient.jobRole_ShowInterest], action.payload);
    const companyId: string = yield select((r: RootState) => r.user.companyId);
    yield put(getJobRoleListAction({ companyId }));
  } catch {
    yield put(
      showToastAction({
        message: 'Could not update interest',
        severity: 'warning',
      }),
    );
  }
}

function* addJobRoleSaga(action: PayloadAction<AddJobRoleRequest>) {
  try {
    yield apiCall(apiClient.jobRole_Add, action.payload);
    const companyId: string = yield select((r: RootState) => r.user.companyId);
    yield put(getJobRoleListAction({ companyId, isArchived: false }));
    yield put(showToastAction({ message: 'Your role has been posted!', severity: 'success' }));
  } catch {
    yield put(
      showToastAction({
        message: 'Could not post job role',
        severity: 'warning',
      }),
    );
  }
}

function* changeJobRoleStatusSaga(
  action: PayloadAction<{ roleId: string; body: ChangeJobRoleStatusRequest | undefined }>,
) {
  try {
    yield call([apiClient, apiClient.jobRole_ChangeStatus], action.payload.roleId, action.payload.body);
    const companyId: string = yield select((r: RootState) => r.user.companyId);
    yield call([apiClient, apiClient.jobRole_ChangeStatus], action.payload.roleId, action.payload.body);
    yield put(getJobRoleListAction({ companyId, isArchived: false }));
    yield put(
      showToastAction({
        message: 'Successfully updated job role status',
        severity: 'success',
      }),
    );
  } catch {
    yield put(
      showToastAction({
        message: 'Could not update job role status',
        severity: 'warning',
      }),
    );
  }
}

function* deleteInterestSaga(action: PayloadAction<string>) {
  try {
    yield call([apiClient, apiClient.jobRole_RevokeInterest], action.payload);
    const companyId: string = yield select((r: RootState) => r.user.companyId);
    yield put(getJobRoleListAction({ companyId }));
  } catch {
    yield put(
      showToastAction({
        message: 'Could not remove interest',
        severity: 'warning',
      }),
    );
  }
}

function* deleteJobRoleSaga(action: PayloadAction<string>) {
  try {
    yield put(
      showConfirmAction({
        title: 'Are you sure?',
        body: 'This action can not be undone.',
        okText: 'Delete job',
        cancelText: 'Cancel',
      }),
    );
    const { yes } = yield race({ yes: take(okConfirmAction.type), no: take(cancelConfirmAction.type) });
    if (yes) {
      yield call([apiClient, apiClient.jobRole_Delete], action.payload);
      const companyId: string = yield select((r: RootState) => r.user.companyId);
      yield put(getJobRoleListAction({ companyId }));
      yield put(
        showToastAction({
          message: 'Successfully deleted job!',
          severity: 'success',
        }),
      );
    }
  } catch {
    yield put(
      showToastAction({
        message: 'Could not delete job',
        severity: 'warning',
      }),
    );
  }
}

function* getJobMatchesSaga(action: PayloadAction<{ roleId: string; page: number; pageSize: number }>) {
  try {
    const { roleId, page, pageSize } = action.payload;
    const matches: ListJobMatchesResponse = yield apiCall(apiClient.jobRole_GetMatches, roleId, page, pageSize);
    yield put(successGetJobRoleMatchesAction(matches));
  } catch {
    yield put(
      showToastAction({
        message: 'Could not get job matches',
        severity: 'warning',
      }),
    );
  }
}

function* getJobRoleListSaga(action: PayloadAction<ListJobsRequest>) {
  try {
    const jobRolesList: ListJobRolesResponse = yield call(
      [apiClient, apiClient.jobRole_List],
      action.payload.companyId,
      action.payload.departments,
      action.payload.jobTitle,
      action.payload.minSalary,
      action.payload.maxSalary,
      action.payload.sortOrder,
      action.payload.currentPage,
      action.payload.pageSize,
      action.payload.useCurrentUsersSearchSettings,
      action.payload.isArchived,
      action.payload.sortDirection,
    );
    yield put(
      successGetJobRoleListAction({
        ...jobRolesList,
        results: jobRolesList.results?.map((entry) => ({
          ...entry,
          startDate: entry.startDate?.toISOString(),
        })),
      }),
    );
  } catch {
    yield put(
      showToastAction({
        message: 'Could not get job list',
        severity: 'warning',
      }),
    );
  }
}

function* startJobMatchingSaga(action: PayloadAction<string>) {
  try {
    yield apiCall(apiClient.jobRole_MatchCandidates, action.payload);
  } catch {
    yield put(
      showToastAction({
        message: 'Could not get matches',
        severity: 'warning',
      }),
    );
  }
}

function* sendInterviewEmailSaga(action: PayloadAction<{ roleId: string; matchId: string }>) {
  yield put(
    showConfirmAction({
      title: 'Are you sure?',
      body: "Click OK if you'd like to send the candidates an interview invite.",
      okText: 'OK',
      cancelText: 'Cancel',
    }),
  );
  const { yes } = yield race({ yes: take(okConfirmAction.type), no: take(cancelConfirmAction.type) });
  if (yes) {
    try {
      yield apiCall(apiClient.interview, action.payload.roleId, action.payload.matchId);
      yield put(
        showToastAction({
          message: 'Email successfully sent!',
          severity: 'success',
        }),
      );
      yield put(hideCandidateComparisonAction());
    } catch {
      yield put(
        showToastAction({
          message: 'Could not send email',
          severity: 'warning',
        }),
      );
    }
  }
}

export function* jobSaga() {
  yield all([
    takeLatest(jobSlice.actions.addInterestAction.type, addInterestSaga),
    takeLatest(jobSlice.actions.addJobRoleAction.type, addJobRoleSaga),
    takeLatest(jobSlice.actions.changeJobRoleStatusAction.type, changeJobRoleStatusSaga),
    takeLatest(jobSlice.actions.deleteJobRoleAction.type, deleteJobRoleSaga),
    takeLatest(jobSlice.actions.deleteInterestAction.type, deleteInterestSaga),
    takeLatest(jobSlice.actions.getAndShowJobMatchesAction.type, getJobMatchesSaga),
    takeLatest(jobSlice.actions.getJobMatchesAction.type, getJobMatchesSaga),
    takeLatest(jobSlice.actions.startJobMatchingAction.type, startJobMatchingSaga),
    takeLatest(jobSlice.actions.getJobRoleListAction.type, getJobRoleListSaga),
    takeLatest(jobSlice.actions.sendInterviewEmailAction.type, sendInterviewEmailSaga),
  ]);
}
