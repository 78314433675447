import React, { FC, useEffect } from 'react';
import './App.css';
import { Alert, Box, CssBaseline, Snackbar, styled, ThemeProvider } from '@mui/material';
import { appTheme } from './theme';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Welcome } from './pages/Welcome';
import { Profile } from './pages/Profile';
import { JobManagement } from './pages/JobManagement';
import { Header } from './components/header';
import { SideNav } from './components/sideNav';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { TokenSingleton } from './services/TokenSingleton';
import { RootState } from './store';
import { hideToastAction } from './redux/globalSlice';
import { getProfile } from './redux/profileSlice';
import { SkillsDatabase } from './pages/SkillsDatabase';
import { Settings } from './pages/Settings';
import { JobMatches } from './pages/JobMatches';
import { getUserInfo } from './redux/userSlice';
import { PrivateRoute } from './components/shared/authenticated';
import { CompanyManagement } from './pages/CompanyManagement';
import { ConfirmationDialog } from './components/global/confirmationDialog';
import { UserManagement } from './pages/UserManagement';
import { getAvailableCategoriesAction, getAvailableSkillsAction } from './redux/skillsSlice';
import { Login } from './pages/Login';
import { Loading } from './components/shared/loading';
import { JobSearching } from './pages/JobSearching';
import { Confetti } from './components/global/Confetti';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const MainArea = styled('main')(({ theme }) => ({
  position: 'absolute',
  width: `calc(100% - 56px)`,
  minHeight: 'calc(100vh - 64px)',
  backgroundColor: '#eff3f8',
  top: '64px',
  left: '56px',
  padding: theme.spacing(4),
}));

const App: FC = () => {
  const user = useSelector((r: RootState) => r.user);
  const toast = useSelector((s: RootState) => s.global.toast);
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      if (token) {
        TokenSingleton.setAccessToken(token);
        dispatch(getUserInfo());
        dispatch(getProfile());
        dispatch(getAvailableSkillsAction({ type: undefined, prefix: undefined, includeArchived: false }));
        dispatch(getAvailableCategoriesAction({ prefix: undefined }));
      } else {
        TokenSingleton.setAccessToken('');
      }
    });
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={appTheme}>
        <CssBaseline />
        <Snackbar
          autoHideDuration={5000}
          open={toast.isVisible}
          onClose={() => dispatch(hideToastAction())}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        >
          <Alert severity={toast.type}>{toast.message}</Alert>
        </Snackbar>
        <Header />
        <Box>
          <SideNav />
          <MainArea>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route
                path="/"
                element={
                  <PrivateRoute>
                    <Welcome />
                  </PrivateRoute>
                }
              />

              <Route
                path="/profile"
                element={
                  <PrivateRoute>
                    <Profile />
                  </PrivateRoute>
                }
              />
              <Route
                path="/job-searching"
                element={
                  <PrivateRoute>
                    <JobSearching />
                  </PrivateRoute>
                }
              />
              <Route
                path="/job-management"
                element={
                  <PrivateRoute role={['HiringManager', 'Admin']}>
                    <JobManagement />
                  </PrivateRoute>
                }
              />
              <Route
                path="/job/matches/:roleId"
                element={
                  <PrivateRoute role={['HiringManager', 'Admin']}>
                    <JobMatches />
                  </PrivateRoute>
                }
              />
              <Route
                path="/skills"
                element={
                  <PrivateRoute role="Admin">
                    <SkillsDatabase />
                  </PrivateRoute>
                }
              />
              <Route
                path="/user-management"
                element={
                  <PrivateRoute role="Admin">
                    <Loading noDataMessage={'No users found'} loading={!user.companyId} value={user}>
                      <UserManagement />
                    </Loading>
                  </PrivateRoute>
                }
              />
              <Route
                path="/company-management"
                element={
                  <PrivateRoute role="SuperAdmin">
                    <CompanyManagement />
                  </PrivateRoute>
                }
              />
              <Route
                path="/settings"
                element={
                  <PrivateRoute>
                    <Settings />
                  </PrivateRoute>
                }
              />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </MainArea>
        </Box>
        <ConfirmationDialog />
        <Confetti />
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default App;
