import React, { FC } from 'react';
import { AppBar, Avatar, Button, Grid, IconButton, styled, Toolbar, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import logo from '../assets/mailroom.png';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { HeaderGridItem } from './shared/grid';
import { AvatarMenu } from './menu';

export const StyledAvatar = styled(Avatar)(({}) => ({
  width: 32,
  height: 32,
}));

export const Header: FC = () => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const profile = useSelector((s: RootState) => s.profile);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar style={{ backgroundColor: 'white', position: 'relative' }} elevation={2}>
      <Toolbar>
        <Grid container justifyContent="space-between" alignItems="center" width="100%">
          <Grid item sx={{ height: '48px' }}>
            <Link to={'/'}>
              <img src={logo} alt={'Mailroom logo'} height={48} width={190} />
            </Link>
          </Grid>
          <Grid item>
            {profile && (
              <Grid container alignItems={'center'}>
                <HeaderGridItem item>
                  <Typography variant={'body1'}>
                    {profile.profileDetails.firstName} {profile.profileDetails.lastName}
                  </Typography>
                </HeaderGridItem>
                <HeaderGridItem item>
                  {isAuthenticated ? (
                    <IconButton
                      onClick={handleClick}
                      size="small"
                      sx={{ ml: 2 }}
                      aria-controls={open ? 'account-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                    >
                      {profile.profileDetails.avatarUrl && (
                        <StyledAvatar
                          alt={profile.profileDetails.firstName + ' ' + profile.profileDetails.lastName}
                          src={`${process.env.REACT_APP_API_URL}${profile.profileDetails.avatarUrl}`}
                        />
                      )}

                      {!profile.profileDetails.avatarUrl && profile.profileDetails.firstName && (
                        <StyledAvatar>{profile.profileDetails.firstName?.slice(0, 1)}</StyledAvatar>
                      )}

                      {!profile.profileDetails.avatarUrl && !profile.profileDetails.firstName && (
                        <StyledAvatar></StyledAvatar>
                      )}
                    </IconButton>
                  ) : (
                    <Button onClick={loginWithRedirect}>Login</Button>
                  )}

                  <AvatarMenu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    profileImage={profile.profileDetails.avatarUrl}
                    onClick={() =>
                      logout({
                        returnTo: `${window.location.origin}/login`,
                      })
                    }
                  />
                </HeaderGridItem>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
