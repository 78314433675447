import { Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import React, { FC, FormEvent, useEffect, useState } from 'react';
import { ContainedButton } from '../shared/button';
import { ModalProps } from '../../services/shared/baseTypes';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Loading } from '../shared/loading';
import { updateXFactors } from '../../redux/profileSlice';
import { XFactorQuestionForm } from './xFactorQuestionForm';
import { StyledDialogTitleWithX } from '../shared/dialog';

export const XFactorsModal: FC<ModalProps> = (props) => {
  const { onClose, open } = props;
  const currentScenario = useSelector((x: RootState) => x.xFactors.currentScenario);
  const answers = useSelector((x: RootState) => x.profile.profileDetails.xFactors);
  const [localAnswers, setLocalAnswers] = useState(answers ?? []);
  useEffect(() => setLocalAnswers(answers ?? []), [answers]);
  const dispatch = useDispatch();

  const handleSave = (e: FormEvent) => {
    e.preventDefault();
    if (localAnswers) dispatch(updateXFactors(localAnswers));
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth={'md'} open={open} aria-labelledby={'current-scenario'} onClose={onClose}>
      <form onSubmit={handleSave}>
        <StyledDialogTitleWithX id="current-scenario" onClose={onClose}>
          {currentScenario?.scenario?.name}
        </StyledDialogTitleWithX>
        <DialogContent sx={{ minHeight: '100%' }}>
          <Loading
            noDataMessage={'No questions found'}
            loading={currentScenario?.questions?.isLoading ?? false}
            value={currentScenario?.questions?.value}
          >
            <DialogContentText sx={{ paddingTop: 2 }} variant={'h5'}>
              {currentScenario?.scenario?.scenarioPrompt}
            </DialogContentText>
            <XFactorQuestionForm
              questions={currentScenario?.questions?.value ?? []}
              value={localAnswers}
              onChange={setLocalAnswers}
            />
          </Loading>
        </DialogContent>
        <DialogActions>
          {!(currentScenario?.questions?.isLoading ?? false) && <ContainedButton type="submit" text="Save" />}
        </DialogActions>
      </form>
    </Dialog>
  );
};
