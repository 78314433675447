import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISendEmailRequest } from '../services/generated/ApiClientGenerated';

type Severity = 'info' | 'success' | 'warning' | 'error';

export type ConfirmationDialogType = {
  isVisible: boolean;
  title: string;
  body: string;
  okText: string;
  cancelText?: string;
};

export type DialogType = {
  isVisible: boolean;
  recentActivityDialog: {
    isVisible: boolean;
  };
};

export type ToastType = {
  isVisible: boolean;
  type: Severity;
  message: string;
};

export const globalSlice = createSlice({
  name: 'global',
  initialState: {
    confetti: {
      isVisible: false,
    },
    confirmation: <ConfirmationDialogType>{
      isVisible: false,
      title: 'Please confirm',
      body: 'Are you sure?',
      okText: 'OK',
      cancelText: 'Cancel',
    },
    toast: <ToastType>{
      isVisible: false,
      type: 'info',
      message: '',
    },
    dialog: <DialogType>{
      isVisible: false,
      recentActivityDialog: {
        isVisible: false,
      },
    },
  },
  reducers: {
    hideDialogAction: (state) => {
      state.dialog.isVisible = false;
    },
    hideRecentActivityDialogAction: (state) => {
      state.dialog.recentActivityDialog.isVisible = false;
    },
    hideToastAction: (state) => {
      state.toast.isVisible = false;
      state.toast.message = '';
    },
    showDialogAction: (state) => {
      state.dialog.isVisible = true;
    },
    showRecentActivityDialogAction: (state) => {
      state.dialog.recentActivityDialog.isVisible = true;
    },
    showToastAction: (
      state,
      action: PayloadAction<{
        message: string;
        severity?: Severity;
        title?: string;
        body?: string;
      }>,
    ) => {
      state.toast.isVisible = true;
      state.toast.message = action.payload.message;
      state.toast.type = action.payload.severity ?? 'info';
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    sendEmailAction: (state, action: PayloadAction<{ body?: ISendEmailRequest }>) => {},
    showConfirmAction: (
      state,
      action: PayloadAction<Partial<Omit<ConfirmationDialogType, 'isVisible'>> | undefined>,
    ) => {
      state.confirmation = {
        isVisible: true,
        title: 'Are you sure?',
        body: "Click OK if you'd like to perform the action.",
        okText: 'OK',
        cancelText: 'Cancel',
        ...(action.payload ?? {}),
      };
    },
    okConfirmAction: (state) => {
      state.confirmation.isVisible = false;
    },
    cancelConfirmAction: (state) => {
      state.confirmation.isVisible = false;
    },
    showConfettiAction: (state) => {
      state.confetti.isVisible = true;
    },
    hideConfettiAction: (state) => {
      state.confetti.isVisible = false;
    },
  },
});

export const {
  hideDialogAction,
  hideRecentActivityDialogAction,
  hideToastAction,
  sendEmailAction,
  showDialogAction,
  showToastAction,
  showConfirmAction,
  okConfirmAction,
  cancelConfirmAction,
  showConfettiAction,
  hideConfettiAction,
} = globalSlice.actions;
