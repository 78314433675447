import { Box, Button, Grid, List, ListItem, Paper, styled, Typography } from '@mui/material';
import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { showToastAction } from '../../../redux/globalSlice';

export type ListRankerItem = { key: string; text: string };

export type ListRankerProps<T extends ListRankerItem | string> = {
  title?: string;
  leftTitle?: string;
  rightTitle?: string;
  options: T[];
  value: T[];
  onChange: (value: T[]) => any;
  maxSelected?: number;
};

const DraggableInnerListItem = styled(Box)(({ theme }) => ({
  '&': {
    minWidth: '100%',
    borderRadius: theme.shape.borderRadius,
    // boxShadow: theme.shadows[1],
    padding: theme.spacing(1),
    // color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.background.paper,
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
  },
}));

export function ListRanker<T extends ListRankerItem | string>({
  title,
  leftTitle,
  rightTitle,
  options,
  value,
  onChange,
  maxSelected,
}: ListRankerProps<T>): React.ReactElement | null {
  const getKey = (item: T) => {
    if (typeof item === 'string') return item;
    return item.key;
  };
  const [items, setItems] = useState(options.filter((o) => value.every((v) => getKey(v) !== getKey(o))));
  const [selected, setSelected] = useState(value);
  const dispatch = useDispatch();
  const getValue = (item: T) => {
    if (typeof item === 'string') return item;
    return item.text;
  };
  const handleReset = () => {
    onChange([]);
    setItems([...items, ...selected]);
    setSelected([]);
  };
  const handleDrag = (result: DropResult) => {
    if (!result.destination) return;
    if (
      result.destination.droppableId === 'selected' &&
      result.source.droppableId === 'unselected' &&
      typeof maxSelected !== 'undefined' &&
      selected.length >= maxSelected
    ) {
      dispatch(
        showToastAction({
          message: `You can only select up to ${maxSelected} items`,
          severity: 'info',
        }),
      );
      return;
    }
    const [source, setSource] =
      result.source.droppableId === 'unselected' ? [items, setItems] : [selected, setSelected];
    const [target, setTarget] =
      result.destination.droppableId === 'unselected' ? [items, setItems] : [selected, setSelected];
    const newItem = source.splice(result.source.index, 1);
    setSource(source);
    target.splice(result.destination.index, 0, newItem[0]);
    setTarget(target);
    onChange(selected);
  };
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h5">{title}</Typography>
      </Grid>
      {(leftTitle || leftTitle) && (
        <Grid item xs={6}>
          <Typography variant="h5">{leftTitle}</Typography>
        </Grid>
      )}
      {(leftTitle || rightTitle) && (
        <Grid item xs={6}>
          <Typography variant="h5">{rightTitle}</Typography>
        </Grid>
      )}
      <Grid item xs={12} sx={{ position: 'relative' }}>
        <DragDropContext onDragEnd={handleDrag}>
          <Grid container spacing={4}>
            <Droppable droppableId="unselected">
              {(provided) => (
                <Grid item xs={12} sm={6}>
                  <Paper sx={{ minHeight: '100%' }}>
                    <List style={{ minHeight: '80px' }} ref={provided.innerRef}>
                      {items.map((item, i) => (
                        <Draggable key={getKey(item)} draggableId={getKey(item)} index={i}>
                          {(provided) => (
                            <ListItem
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <DraggableInnerListItem>{getValue(item)}</DraggableInnerListItem>
                            </ListItem>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </List>
                  </Paper>
                </Grid>
              )}
            </Droppable>
            <Droppable droppableId="selected">
              {(provided) => (
                <Grid item xs={12} sm={6}>
                  <Paper sx={{ minHeight: '100%' }}>
                    <List style={{ minHeight: '80px' }} ref={provided.innerRef}>
                      {selected.map((item, i) => (
                        <Draggable key={getKey(item)} draggableId={getKey(item)} index={i}>
                          {(provided) => (
                            <ListItem
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <DraggableInnerListItem>{getValue(item)}</DraggableInnerListItem>
                            </ListItem>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </List>
                  </Paper>
                </Grid>
              )}
            </Droppable>
          </Grid>
        </DragDropContext>
      </Grid>
      <Grid container item xs={12} justifyContent={'flex-end'}>
        <Button onClick={handleReset} variant="contained">
          Reset
        </Button>
      </Grid>
    </Grid>
  );
}
