import { Grid, InputLabel, TextField, Tooltip } from '@mui/material';
import { ButtonStyledText } from '../shared/typography';
import React, { FC } from 'react';
import { InfoOutlined } from '@mui/icons-material';
import { MailroomAutocomplete, MultiSelectAutocomplete } from '../shared/select';
import { RadioGroup } from '../shared/radioGroup';
import { IUpdateUserSettingsRequest } from '../../services/generated/ApiClientGenerated';
import { SerializableIGetUserSettingsResponse } from '../../redux/settingsSlice';
import { format } from 'date-fns';

const tooltipText =
  "At Mailroom, we believe that diverse teams are the most innovative. Mailroom's unique algorithm uses your demographic information so that you will be placed in a team that will benefit from your unique identity and experience. In Mailroom, hiring managers will never be able to select based on candidate demographics - you'll be deidentified to them and your special combination will only be used to uplift your profile.";

export type DemographicsPropType = {
  onAutocompleteChange: (key: keyof IUpdateUserSettingsRequest, value: any) => void;
  onChange: (value: SerializableIGetUserSettingsResponse) => void;
  value: SerializableIGetUserSettingsResponse;
};

export const Demographics: FC<DemographicsPropType> = ({ onAutocompleteChange, onChange, value }) => {
  function handleRadioStringToBooleanConversion<K extends keyof SerializableIGetUserSettingsResponse>(
    key: K,
    newValue: string | boolean | undefined,
  ) {
    if (typeof newValue === 'string') {
      const booleanValue = newValue == 'true';
      onChange({
        ...value,
        [key]: booleanValue,
      });
    }
  }

  const getBirthday = (birthday: string | undefined) => {
    if (birthday) {
      return format(new Date(birthday), 'yyyy-MM-dd');
    } else return undefined;
  };

  function handleChange<K extends keyof SerializableIGetUserSettingsResponse>(
    key: K,
    newValue: SerializableIGetUserSettingsResponse[K],
  ) {
    onChange({
      ...value,
      [key]: newValue,
    });
  }

  return (
    <Grid container item xs={3} direction={'column'} px={2}>
      <Grid container item alignItems={'center'}>
        <ButtonStyledText>Demographics</ButtonStyledText>
        <Tooltip placement={'right'} title={tooltipText} arrow>
          <InfoOutlined />
        </Tooltip>
      </Grid>

      <Grid container mt={2} spacing={1} justifyContent={'space-between'}>
        <Grid item xs={12}>
          <MultiSelectAutocomplete
            options={[
              'Arabic',
              'Chinese',
              'English',
              'French',
              'German',
              'Hindi',
              'Japanese',
              'Portuguese',
              'Russian',
              'Spanish',
            ]}
            value={value?.languages ?? []}
            onChange={(value) => onAutocompleteChange('languages', value)}
            label={'Languages'}
          />
        </Grid>

        <Grid item xs={12}>
          <MultiSelectAutocomplete
            options={[
              'Asian',
              'Black or African American',
              'Hispanic',
              'American Indian or Alaska Native',
              'Native Hawaiian or Pacific Islander',
              'White',
              'Other',
            ]}
            value={value?.raceOrEthnicity ?? []}
            onChange={(value) => onAutocompleteChange('raceOrEthnicity', value)}
            label={'Race Or Ethnicity'}
          />
        </Grid>

        <Grid item xs={12}>
          <MailroomAutocomplete
            freeSolo
            label={'Highest Education Level'}
            value={value?.highestEducationLevel ?? ''}
            options={[
              'Grade school',
              'High school graduate or certificate of equivalency',
              'Some college',
              'Technical or occupational certificate',
              'Associate Degree',
              "Bachelor's degree",
              "Master's degree",
              'Doctorate degree',
              'Other',
            ]}
            onChange={(value) => handleChange('highestEducationLevel', value)}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} justifyContent={'space-between'}>
        <Grid item xs={12} mt={3}>
          <InputLabel htmlFor={'birthDay'}>Birth date</InputLabel>
          <TextField
            fullWidth
            value={getBirthday(value?.birthDay)}
            type={'date'}
            name={'birthDay'}
            onChange={(e) => handleChange('birthDay', e.currentTarget.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <RadioGroup
            row
            onChange={(value) => handleRadioStringToBooleanConversion('isVeteran', value)}
            value={value?.isVeteran?.toString() ?? 'false'}
            options={[
              {
                label: 'Yes',
                value: 'true',
              },
              {
                label: 'No',
                value: 'false',
              },
            ]}
            formLabel={`Veteran`}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
