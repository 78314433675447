import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormGroup,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { FC, FormEvent, useEffect, useState } from 'react';
import { ContainedButton, TrashButton } from '../../shared/button';
import { DateGridContainer, StyledFormControlLabel } from './jobHistoryAddModal';
import { useDispatch, useSelector } from 'react-redux';
import { StyledDialogTitleWithX } from '../../shared/dialog';
import DeleteIcon from '@mui/icons-material/Delete';
import { OnOpenModalProps } from '../../../services/shared/baseTypes';
import { RootState } from '../../../store';
import {
  deleteJobHistoryAction,
  ProfileJobHistory,
  SerializableJobHistory,
  updateJobHistoryAction,
} from '../../../redux/profileSlice';
import { format } from 'date-fns';

export const JobHistoryEditModal: FC<OnOpenModalProps> = ({ onClose, open }) => {
  const jobHistoryToEdit = useSelector((r: RootState) => r.profile.jobHistoryToEdit);
  const [jobHistory, setJobHistory] = useState<ProfileJobHistory>(jobHistoryToEdit);
  const dispatch = useDispatch();

  useEffect(() => {
    setJobHistory(jobHistoryToEdit);
  }, [jobHistoryToEdit]);

  function handleChange<K extends keyof SerializableJobHistory>(key: K, newValue: SerializableJobHistory[K]) {
    setJobHistory({
      ...jobHistory,
      [key]: newValue,
    });
  }

  const handleCurrentJobChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setJobHistory({
      ...jobHistory,
      endDate: event.target.checked ? undefined : jobHistory.endDate,
      [event.target.name]: event.target.checked,
    });
  };

  const handleDelete = () => {
    dispatch(deleteJobHistoryAction());
    onClose();
  };

  const handleSave = (e: FormEvent) => {
    dispatch(updateJobHistoryAction(jobHistory));
    onClose();
    e.preventDefault();
  };

  return (
    <Dialog fullWidth maxWidth={'md'} open={open} aria-labelledby={'Edit Job History'} onClose={onClose}>
      <form onSubmit={handleSave}>
        <StyledDialogTitleWithX onClose={onClose}>Edit Job History</StyledDialogTitleWithX>
        <DialogContent>
          <DialogContentText>
            Primum igitur, quid est cur nec me ab illo inventore veritatis et benivole.
          </DialogContentText>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                label="Company Name"
                name="companyName"
                placeholder="This is the company name of this job"
                type="text"
                value={jobHistory.companyName}
                fullWidth
                required
                variant="outlined"
                onChange={(e) => handleChange('companyName', e.currentTarget.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                label="Role Title"
                name="roleName"
                placeholder="This is the role title of this job"
                type="text"
                value={jobHistory.roleName}
                fullWidth
                required
                variant="outlined"
                onChange={(e) => handleChange('roleName', e.currentTarget.value)}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Stack my={2} spacing={2}>
              <Typography>
                Think beyond the job description to share 1-2 sentences about your biggest wins and proudest
                accomplishments.
              </Typography>
              <Typography>
                <b>For example:</b>{' '}
                <i>
                  I led weekly meetings and approved all deliverables from a team of internal and contract marketing
                  specialists in order to launch a new brand awareness campaign that resulted in a 40% increase in
                  qualified leads.
                </i>
              </Typography>
            </Stack>
          </Grid>
          <TextField
            margin="dense"
            label="Duties and Description"
            name="description"
            value={jobHistory.description}
            type="text"
            fullWidth
            variant="outlined"
            onChange={(e) => handleChange('description', e.currentTarget.value)}
          />
          <DateGridContainer container direction={'row'} spacing={1} justifyContent={'space-between'}>
            <Grid container item direction={'row'} alignItems={'center'} sx={{ width: 'fit-content' }}>
              <TextField
                type="date"
                name="startDate"
                required
                value={jobHistory.startDate ? format(new Date(jobHistory.startDate), 'yyyy-MM-dd') : ''}
                variant="outlined"
                onChange={(e) => handleChange('startDate', e.currentTarget.value)}
              />
              <Typography sx={{ paddingLeft: 2 }} variant={'h5'}>
                Start Date
              </Typography>
            </Grid>

            <Grid container item direction={'row'} alignItems={'center'} sx={{ width: 'fit-content' }}>
              <TextField
                disabled={jobHistory.isCurrentJob}
                type="date"
                name="endDate"
                value={jobHistory.endDate ? format(new Date(jobHistory.endDate), 'yyyy-MM-dd') : ''}
                variant="outlined"
                onChange={(e) => handleChange('endDate', e.currentTarget.value)}
              />
              <Typography sx={{ paddingLeft: 2 }} variant={'h5'}>
                End Date
              </Typography>
            </Grid>

            <Grid item>
              <FormGroup>
                <StyledFormControlLabel
                  control={<Checkbox checked={jobHistory.isCurrentJob} onChange={handleCurrentJobChange} />}
                  name={'isCurrentJob'}
                  label="I am currently working this role"
                />
              </FormGroup>
            </Grid>
          </DateGridContainer>
          <DialogActions>
            <Grid container alignItems={'center'} justifyContent={'space-between'}>
              <Grid item>
                <TrashButton onClick={handleDelete} text={'Delete job history'} icon={<DeleteIcon />} />
              </Grid>
              <Grid item>
                <ContainedButton type="submit" text="Save" />
              </Grid>
            </Grid>
          </DialogActions>
        </DialogContent>
      </form>
    </Dialog>
  );
};
