import React, { FC } from 'react';
import { StyledSvgIcon } from '../components/shared/image';

export const FindingYourRhythmSvg: FC = () => {
  return (
    <StyledSvgIcon xmlns="http://www.w3.org/2000/svg" width="110.536" height="124.54" viewBox="0 0 110.536 124.54">
      <g id="Group_6" data-name="Group 6" transform="translate(-0.09 -0.002)" opacity="0.98">
        <path
          id="Combined_Shape"
          data-name="Combined Shape"
          d="M-53.8,7.578C-10.182,21.795-12.117-5.238-2.751.932s-7.966,22.491-10.675,38.783C-15.836,54.2-5.2,76.434-16.26,83.643-29.547,92.3-43.793,49.731-78.638,60.132-130.136,75.505-98.618-7.03-53.8,7.578Z"
          transform="translate(81.618 124.542) rotate(106)"
          fill="#ffebef"
        />
        <g id="Group_2" data-name="Group 2" transform="translate(99.462 19)">
          <g id="Group">
            <g id="Head" transform="translate(-58.384)">
              <g id="Head-2" data-name="Head" transform="translate(22.798 8.561)">
                <path
                  id="Head-3"
                  data-name="Head"
                  d="M0,20.159-2.3,9.525a7.453,7.453,0,0,0,2-5.375C-.711-1.76-8.994-.46-10.6,2.522s-1.42,10.549.662,11.082c.83.213,2.6-.308,4.4-1.386l-1.13,7.941Z"
                  transform="translate(0 0)"
                  fill="#b28b67"
                />
              </g>
              <path
                id="hair"
                d="M-12.14,6.73a8.764,8.764,0,0,1,2.792-.306,4.741,4.741,0,0,1-.34-2.411A7.316,7.316,0,0,1-8.9,1.832a2.98,2.98,0,0,1,.582-.9,1.993,1.993,0,0,1,.942-.5,2.538,2.538,0,0,1,1.086,0c.158.033.3.1.454.144s.235-.058.35-.157A1.993,1.993,0,0,1-4.45.016a2.685,2.685,0,0,1,1.177.14,2.306,2.306,0,0,1,.98.624A1.632,1.632,0,0,1-2,1.228c.028.068.033.108.1.133s.137.024.2.046A2.537,2.537,0,0,1-.234,2.723,4.147,4.147,0,0,1,0,4.437,7.425,7.425,0,0,1-1.283,7.7,4.089,4.089,0,0,1-3.392,8.88a5.98,5.98,0,0,1,1.1,2.041,9.93,9.93,0,0,1,.5,2.479,8.04,8.04,0,0,1-.125,2.444A2.649,2.649,0,0,1-3.1,17.57,5.713,5.713,0,0,1-4.021,18a6.072,6.072,0,0,1-.946.3,1.907,1.907,0,0,1-1.8-.381,3.353,3.353,0,0,1-.483-.609c-.045-.077-.072-.127-.017-.2a1.455,1.455,0,0,1,.254-.23A2.375,2.375,0,0,0-6.162,15.3a1.679,1.679,0,0,0-.733-1.764,2.728,2.728,0,0,0-2.337.424,3.8,3.8,0,0,0-1.852-2.442c-1.051-.525-2.258-.587-3.338-1.037a1.993,1.993,0,0,1-.776-.49,1,1,0,0,1-.223-.362.584.584,0,0,1,.154-.455A8.512,8.512,0,0,1-12.14,6.73Z"
                transform="translate(26.968 1.105)"
                fill="#191847"
              />
            </g>
            <g id="Bottom" transform="translate(-90.078 51.917)">
              <g id="Seat" transform="translate(22.798 11.599)">
                <ellipse
                  id="Ballsy_Ball"
                  data-name="Ballsy Ball"
                  cx="21.408"
                  cy="20.297"
                  rx="21.408"
                  ry="20.297"
                  transform="translate(10.843 -4.142)"
                  fill="#c5cfd6"
                />
              </g>
              <path
                id="Skin"
                d="M-35.19,17.788c1.771-6.16,3.117-11.581,3.458-13.606.812-4.827-6.245-5.108-7.177-2.709-1.412,3.631-3.876,15.562-6.838,33.582l3.385.981A154.005,154.005,0,0,0-35.19,17.788ZM-1.043,11.46c-3.268-1.283-14.714-5.3-20.234-6.435-1.586-.327-3.124-.63-4.569-.9-4.3-.815-6.522,6.884-2.384,7.476,10.305,1.473,24.915,2.995,26.165,3.165a1.6,1.6,0,0,0,.216.015C-.208,14.777.926,12.233-1.043,11.46Z"
                transform="translate(56.956 8.883)"
                fill="#b28b67"
              />
              <path
                id="Leg_Back"
                data-name="Leg Back"
                d="M-17.726,11.8a3.565,3.565,0,0,0,.048-.915L0,1.946V0H-2.1S-27.757,6.493-28.321,8.323c0,.013-.007.026-.011.039l-.026.035c-3.389,4.63-5.419,27.236-6.086,28.928l6.5.963C-25.464,29.135-17.515,22.76-17.726,11.8Z"
                transform="translate(46.151)"
                fill="#1f28cf"
              />
              <path
                id="Leg_Front"
                data-name="Leg Front"
                d="M-8.64,18.013l-5.34-1.819c1.617-.322,3.107-.609,4.381-.831C-1.365,13.929,1.036,9.551-.384,0H-15.553C-18.031.707-30.909,6.071-37.59,8.924c-4.422,1.888-3.028,8.7-.524,10.467a.243.243,0,0,0,.139.216c9.265,3.814,17.2,3.629,22.407,3.444a22.414,22.414,0,0,1,5.281.122Z"
                transform="translate(58.662)"
                fill="#2b44ff"
              />
              <g id="Right_Shoe" data-name="Right Shoe" transform="matrix(0.438, -0.899, 0.899, 0.438, 43.261, 31.188)">
                <path
                  id="shoe"
                  d="M-42.825,1.653a.562.562,0,0,1,.721.379l.536,1.889a18.051,18.051,0,0,1-.253,2.218H-57.669s-.618-2.042.713-2.205,2.211-.294,2.211-.294L-47.379.356A.281.281,0,0,1-47,.506l.473,1.149a6.369,6.369,0,0,0,2,.575Z"
                  transform="translate(58.427 5.1)"
                  fill="#e4e4e4"
                />
              </g>
              <g id="Right_Shoe-2" data-name="Right Shoe" transform="translate(0 36.453)">
                <path
                  id="shoe-2"
                  data-name="shoe"
                  d="M-1.244,1.63A.557.557,0,0,1-.531,2L0,3.859A17.627,17.627,0,0,1-.251,6.04H-15.931s-.611-2.007.706-2.167,2.188-.289,2.188-.289L-5.749.356A.279.279,0,0,1-5.379.5l.468,1.129A6.333,6.333,0,0,0-2.929,2.2Z"
                  transform="translate(16.681 5.007)"
                  fill="#e4e4e4"
                />
              </g>
            </g>
            <g id="Body_Long_Sleeve_1" data-name="Body/Long Sleeve 1" transform="translate(-76.733 22.645)">
              <path
                id="Skin-2"
                data-name="Skin"
                d="M-.341,23.966c.31-.19.65-.906-.171-1.465-2.046-1.393-4.994-3.831-5.392-4.634C-6.719,16.225-9.493.223-9.493.223L-15.3.246s5.381,17.254,5.81,18.31c.643,1.584-.718,4-1.3,5.292.918.408,1.344-.113,1.811-.634a1.656,1.656,0,0,1,2.275-.54c1.579.73,2.885,1.888,4.441,1.888A3.629,3.629,0,0,0-.341,23.966ZM-50.365,8.431l8.782-3.617L-43.543,0l-8.868,6.469A23.957,23.957,0,0,0-58.44,8.875c-.381.345-.842,1.044.739.923s3.249-.177,3.468.358-.739,1.176-.3,1.9a.21.21,0,0,0,.192.1Q-53.559,12.158-50.365,8.431Z"
                transform="translate(61.675 25.465)"
                fill="#b28b67"
              />
              <path
                id="Coat_Back"
                data-name="Coat Back"
                d="M0,.616-2.359,0c-9.553,6.847-14.484,21.176-26.055,27.982l2.339,3.457C-6.976,30.767-.729,13.31,0,.616Z"
                transform="matrix(0.996, -0.087, 0.087, 0.996, 35.617, 0.859)"
                fill="#e87613"
              />
              <path
                id="Shirt"
                d="M0,31.482H-21.272c-.972,0-.789-1.394-.648-2.1C-20.3,21.265-13.968,12.513-13.968.956L-7.832,0C-2.757,8.108-1,18.089,0,31.482Z"
                transform="translate(46.151)"
                fill="#dde3e9"
              />
              <path
                id="Coat_Front"
                data-name="Coat Front"
                d="M-.028,44.05A58.853,58.853,0,0,0-10.574,8.844,3.229,3.229,0,0,0-7.813,7.159C-5.57,3.14-6.38.927-8.777.2S-13.311.97-17.129.97A3,3,0,0,0-17.585,1l-2.036.115s-8.266,27.828-4.56,37.133H-7.367a52.837,52.837,0,0,0,2.777,5.8Z"
                transform="translate(55.91 -0.97)"
                fill="#ff9b21"
              />
              <path
                id="Shade"
                d="M-6.284,10.092H0C-1.152,7.343-2.424,3.948-3.778,0c-.564,4.865-1.407,8.26-2.505,10.092Z"
                transform="translate(48.543 27.189)"
                fill="rgba(0,0,0,0.1)"
              />
            </g>
          </g>
        </g>
      </g>
    </StyledSvgIcon>
  );
};
