import React, { FC } from 'react';
import { Avatar, Badge, Divider, Grid, ListItemIcon, Menu, MenuItem, styled, Typography } from '@mui/material';
import { StyledLink } from './shared/link';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { useDispatch } from 'react-redux';
import { showProfileDialogAction } from '../redux/profileSlice';

export const StyledMenuItem = styled(MenuItem)(({}) => ({
  '& .MuiAvatar-root': {
    width: 32,
    height: 32,
    ml: -0.5,
    mr: 1,
  },
}));

export type AvatarMenuType = {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  profileImage?: string;
  onClick: () => void;
};

export const AvatarMenu: FC<AvatarMenuType> = ({ anchorEl, open, onClose, profileImage, onClick }) => {
  const dispatch = useDispatch();

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      onClick={onClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,

          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <MenuItem onClick={() => dispatch(showProfileDialogAction())}>
        <Grid container item justifyContent={'center'}>
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={<CameraAltIcon fontSize="small" />}
          >
            <Avatar sx={{ width: 50, height: 50 }} src={`${process.env.REACT_APP_API_URL}${profileImage}`} />
          </Badge>
        </Grid>
      </MenuItem>
      <Divider />
      <StyledMenuItem>
        <StyledLink to={'/profile'}>
          <Grid container alignItems={'center'}>
            <Grid item>
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
            </Grid>
            <Grid item>
              <Typography>Manage your Profile</Typography>
            </Grid>
          </Grid>
        </StyledLink>
      </StyledMenuItem>
      <StyledMenuItem onClick={onClick}>
        <Grid container alignItems={'center'}>
          <Grid item>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
          </Grid>
          <Grid item>
            <Typography>Logout</Typography>
          </Grid>
        </Grid>
      </StyledMenuItem>
    </Menu>
  );
};
