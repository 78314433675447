import { Dialog, DialogActions, DialogContent, DialogContentText, Divider, Grid, Typography } from '@mui/material';
import React, { FC, FormEvent, useState } from 'react';
import { ModalProps } from '../../../../services/shared/baseTypes';
import { JobReqContactInformation } from './jobReqContactInformation';
import { JobReqTechnicalSkills } from './jobReqTechnicalSkills';
import {
  Contact,
  IAddJobRoleRequest,
  IContact,
  XFactorCategory,
} from '../../../../services/generated/ApiClientGenerated';
import { RoleDescription } from './jobReqRoleDescription';
import { useDispatch } from 'react-redux';
import { addJobRoleAction } from '../../../../redux/jobSlice';
import { ContainedButton } from '../../../shared/button';
import { StyledDialogTitleWithX } from '../../../shared/dialog';
import { useAuth0 } from '@auth0/auth0-react';
import { ListRanker } from '../../../xFactors/questions/listRanker';
import { StyledModalPaperContainer } from '../../../shared/grid';
import { StyledPaper } from '../../../shared/paper';

export const PostNewRoleModal: FC<ModalProps> = ({ onClose, open }) => {
  const { user } = useAuth0();
  const hiringManager: IContact = {
    firstName: user?.given_name,
    lastName: user?.family_name,
    number: user?.phone_number,
    email: user?.email,
  };
  const [jobRole, setJobRole] = useState<IAddJobRoleRequest>({
    hiringManager: hiringManager as Contact,
  });
  const options = [
    XFactorCategory.Communication,
    XFactorCategory.Creativity,
    XFactorCategory.Adaptation,
    XFactorCategory.Empathy,
    XFactorCategory.Leadership,
    XFactorCategory.Resilience,
  ];
  const dispatch = useDispatch();

  const handleSave = (e: FormEvent) => {
    dispatch(addJobRoleAction(jobRole));
    onClose();
    e.preventDefault();
  };

  const handleJobRoleChange = (value: Partial<IAddJobRoleRequest>) => {
    setJobRole({
      ...jobRole,
      ...value,
    });
  };

  return (
    <Dialog fullWidth maxWidth={'md'} open={open} aria-labelledby={'Create New Role'} onClose={onClose}>
      <form onSubmit={handleSave}>
        <StyledDialogTitleWithX onClose={onClose}>Create New Role</StyledDialogTitleWithX>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <Grid container item spacing={2}>
            <RoleDescription value={jobRole} onChange={handleJobRoleChange} />
            <JobReqContactInformation hiringManager={hiringManager} onChange={handleJobRoleChange} />
            <JobReqTechnicalSkills onChange={handleJobRoleChange} />
            <StyledModalPaperContainer width={'100%'} item>
              <StyledPaper sx={{ height: '100%' }}>
                <Grid container item px={2}>
                  <Typography variant={'h5'}>X-Factors</Typography>
                </Grid>
                <Divider />

                <Grid container item p={2}>
                  <Typography>Select up to 3 x-factors</Typography>
                </Grid>
                <Grid container item sx={{ paddingLeft: 2, paddingRight: 2, position: 'relative' }}>
                  <ListRanker
                    maxSelected={3}
                    options={options}
                    value={jobRole.xFactorRequirements ?? []}
                    onChange={(v) => handleJobRoleChange({ xFactorRequirements: v })}
                  />
                </Grid>
              </StyledPaper>
            </StyledModalPaperContainer>
          </Grid>
          <DialogActions>
            <ContainedButton type="submit" text="Save" />
          </DialogActions>
        </DialogContent>
      </form>
    </Dialog>
  );
};
