import { all, put, takeLatest } from 'redux-saga/effects';
import { failureGetUserInfo, getUserInfo, setAuthenticated, successGetUserInfo } from '../redux/userSlice';
import { apiCall, apiClient } from '../services/apiClient';
import { GetUserInfoResponse } from '../services/generated/ApiClientGenerated';
import { showToastAction } from '../redux/globalSlice';

function* getUserInfoSaga() {
  try {
    const response: GetUserInfoResponse = yield apiCall(apiClient.security_GetUserInfo);
    yield put(successGetUserInfo({ ...response }));
    yield put(setAuthenticated(true));
  } catch (e) {
    yield put(failureGetUserInfo());
    yield put(
      showToastAction({
        message: 'Could not retrieve user info',
        severity: 'warning',
      }),
    );
  }
}

export function* userSaga() {
  yield all([takeLatest(getUserInfo.type, getUserInfoSaga)]);
}
