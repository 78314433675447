import React, { FC } from 'react';
import '../App.css';
import { Container, Grid, Skeleton, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { JobSearchCard } from '../components/welcome/jobSearchCard';
import { JobManagementCard } from '../components/welcome/jobManagementCard';
import { Authenticated } from '../components/shared/authenticated';
import { ProfileCard } from '../components/welcome/profileCard';

export const Welcome: FC = () => {
  const profile = useSelector((s: RootState) => s.profile);

  return (
    <Container maxWidth="lg">
      <Grid container direction={'row'}>
        <Grid item xs={12}>
          <Typography component={'h1'} variant={'h3'}>
            {profile?.profileDetails?.firstName ? (
              `Hello ${profile?.profileDetails.firstName}!`
            ) : (
              <Skeleton variant="text" width={'18.75rem'} />
            )}
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 1.5 }}>
            {profile?.profileDetails?.firstName ? (
              `Welcome to Mailroom. What would you like to do today?`
            ) : (
              <Skeleton variant="text" width={'22rem'} />
            )}
          </Typography>
          <Grid item xs={12}>
            <Grid container direction="row" spacing={4}>
              {profile?.profileDetails?.firstName ? (
                <>
                  <ProfileCard />
                  <JobSearchCard />
                  <Authenticated role={'HiringManager'}>
                    <JobManagementCard />
                  </Authenticated>
                </>
              ) : (
                <>
                  <Grid item xs={12} sm={6} md={4}>
                    <Skeleton variant="rectangular" width={'100%'} height={500} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Skeleton variant="rectangular" width={'100%'} height={500} />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
