import React, { FC, useEffect } from 'react';
import '../App.css';
import { Container, Grid, Typography } from '@mui/material';
import { PrimaryCandidateCard } from '../components/jobs/jobManagement/primaryCandidateCard';
import { IListJobMatchesJobMatchItem } from '../services/generated/ApiClientGenerated';
import { CandidateComparison } from '../components/jobs/jobManagement/jobMatches/compareCandidates';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import {
  addCandidateToInterview,
  getJobMatchesAction,
  hideCandidateComparisonAction,
  showCandidateComparisonAction,
} from '../redux/jobSlice';
import { showToastAction } from '../redux/globalSlice';
import { useParams } from 'react-router';
import { Loading } from '../components/shared/loading';

export const JobMatches: FC = () => {
  const { roleId } = useParams();
  const candidatesToInterview = useSelector((r: RootState) => r.job.candidatesToInterview);
  const matches = useSelector((r: RootState) => r.job.jobRoleMatches);
  const isCandidateComparisonComponentVisible = useSelector(
    (r: RootState) => r.job.isCandidateComparisonComponentVisible,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (roleId) dispatch(getJobMatchesAction({ roleId, page: 0, pageSize: 50 }));
  }, [roleId]);

  const handleComparisonClick = (candidate: IListJobMatchesJobMatchItem) => {
    const candidateToAdd = checkIfCandidateArrayAlreadyContainsCandidate(candidate, candidatesToInterview);
    if (candidateToAdd) {
      dispatch(
        showToastAction({
          message: 'This candidate is already selected. Please choose another candidate.',
          severity: 'warning',
        }),
      );
    } else if (candidatesToInterview.length >= 3) {
      dispatch(
        showToastAction({
          message: 'Please limit your comparison to three candidates.',
          severity: 'warning',
        }),
      );
    } else {
      dispatch(addCandidateToInterview([...candidatesToInterview, candidate]));
      dispatch(showCandidateComparisonAction());
    }
  };

  const handleRemoveCandidate = (newArr: IListJobMatchesJobMatchItem[]) => {
    if (newArr.length == 0) {
      dispatch(hideCandidateComparisonAction());
    }
    dispatch(addCandidateToInterview(newArr));
  };

  function checkIfCandidateArrayAlreadyContainsCandidate(
    candidate: IListJobMatchesJobMatchItem,
    candidateArray: IListJobMatchesJobMatchItem[],
  ) {
    let i;
    for (i = 0; i < candidateArray.length; i++) {
      if (candidateArray[i] === candidate) {
        return true;
      }
    }
    return false;
  }

  return (
    <Container maxWidth="lg">
      <Grid container>
        <Grid item xs={9}>
          <Typography component={'h1'} variant={'h3'}>
            Job Matches
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 1.5 }}>
            {(matches.value?.resultCount ?? 0) > 10 && (
              <Typography>
                {' '}
                Wow! Your candidate pool is stacked with talent! We've already identified {
                  matches.value?.resultCount
                }{' '}
                matches to explore within your company. Explore each candidate's skills and X-Factors, and select up to
                three candidates to invite for an initial interview.
              </Typography>
            )}
            {(matches.value?.resultCount ?? 0) > 3 && (matches.value?.resultCount ?? 0) <= 10 && (
              <Typography>
                Congratulations! You have some solid talent in your candidate pool! We've already identified{' '}
                {matches.value?.resultCount} matches to explore within your company. Explore each candidate's skills and
                X-Factors, and select up to three candidates to invite for an initial interview.
              </Typography>
            )}
            {(matches.value?.resultCount ?? 0) < 3 && (
              <Typography>
                It looks like you have a few internal candidates. Before you move forward make sure you aren't missing
                some key skills that might ensure as many exciting candidates as possible are surfaced across the
                company!
              </Typography>
            )}
            "
          </Typography>
        </Grid>
      </Grid>

      {isCandidateComparisonComponentVisible && (
        <CandidateComparison
          roleId={roleId}
          onRemoveCandidate={handleRemoveCandidate}
          candidates={candidatesToInterview ? candidatesToInterview : []}
        />
      )}

      <Loading noDataMessage={'No candidates found'} loading={matches.isLoading} value={matches?.value?.results}>
        <Grid container spacing={1}>
          {matches?.value?.results?.map((candidate, index) => (
            <PrimaryCandidateCard
              onComparisonClick={() => handleComparisonClick(candidate)}
              key={index}
              mouseHover={true}
              xs={4}
              candidate={candidate}
            />
          ))}
        </Grid>
      </Loading>
    </Container>
  );
};
